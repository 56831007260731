import { Box, Card, CardMedia } from '@mui/material';
import { styled } from '@fctg-ds/theme';

export const StyledCardMedia = styled(CardMedia)<{ isRoomDetails?: boolean; component: string }>(
  ({ theme, isRoomDetails }) => ({
    height: '275px',
    borderRadius: isRoomDetails
      ? theme.spacing(3)
      : `calc(${theme.spacing(4)} - 1px) calc(${theme.spacing(4)} - 1px) 0 0`,
  }),
);

export const StyledCard = styled(Card)(() => ({
  borderRadius: 0,
}));

export const StyledGalleryBox = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
}));
