import React, { useEffect, useState } from 'react';
import { DatePickerMobileProps } from './types';
import { SelectionDate } from '@src/components/calendar-date-picker/types';
import { DatePickerContent, ClearButton, DoneButton } from './styles';
import { FooterStyle, FooterButtons } from '../edit-search-date-picker/styles';
import { Drawer, IconButton } from '@fctg-ds/core';
import CloseIcon from '@mui/icons-material/Close';
import CalendarDatePicker from '../calendar-date-picker/main';
import CalendarFooter from '../calendar-footer/main';
import ErrorDialog from '../error-dialog/main';
import { hasDatePassed } from '@src/helpers/hasDatePassed/hasDatePassed';
import { toSelectionDate } from '@src/helpers/toSelectionDate/toSelectionDate';
import { toStringDate } from '@src/helpers/toStringDate/toStringDate';
import { isWithinMaxStayDuration } from '@src/helpers/isWithinMaxStayDuration/isWithinMaxStayDuration';
import { isSameDatesSelected } from '@src/helpers/isSameDatesSelected/isSameDatesSelected';

const DatePickerMobile = ({ open, dates, handleClose, setNewCheckInOutDates }: DatePickerMobileProps) => {
  const [startDate, setStartDate] = useState<SelectionDate | null>(toSelectionDate(dates?.checkInDate));
  const [endDate, setEndDate] = useState<SelectionDate | null>(toSelectionDate(dates?.checkOutDate));
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  useEffect(() => {
    setStartDate(toSelectionDate(dates?.checkInDate));
    setEndDate(toSelectionDate(dates?.checkOutDate));
  }, [open]);

  const handleSelectedDates = (newSelectionDate: SelectionDate) => {
    if (startDate && !endDate && !hasDatePassed(newSelectionDate, startDate)) {
      setEndDate(newSelectionDate);
    } else {
      setStartDate(newSelectionDate);
      setEndDate(null);
    }
  };

  const handleDone = () => {
    if (startDate && endDate && !isSameDatesSelected(startDate, endDate)) {
      if (isWithinMaxStayDuration(endDate, startDate)) {
        setNewCheckInOutDates({ checkInDate: toStringDate(startDate), checkOutDate: toStringDate(endDate) });
        handleClose();
      } else {
        setShowErrorModal(true);
      }
    }
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <>
      <Drawer
        open={open}
        onOpen={() => true}
        onClose={handleClose}
        drawerHeaderTitle="Travel dates"
        hideDragHandle
        noContentPadding
        leftActionBtn={
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        }
        rightActionBtn={
          <ClearButton placeholder={undefined} onClick={handleClear} disabled={!startDate && !endDate}>
            Clear
          </ClearButton>
        }
        footer={
          <FooterStyle data-testid="date-picker-mobile-footer">
            <CalendarFooter checkInDate={startDate} checkOutDate={endDate} />
            <FooterButtons>
              <DoneButton
                placeholder={undefined}
                active={startDate && endDate && !isSameDatesSelected(startDate, endDate) ? true : false}
                onClick={handleDone}
              >
                Done
              </DoneButton>
            </FooterButtons>
          </FooterStyle>
        }
        data-testid="date-picker-mobile"
      >
        <DatePickerContent data-testid="date-picker-mobile-content">
          <CalendarDatePicker
            initialCalendarDates={{ checkInDate: startDate, checkOutDate: endDate }}
            setNewCalendarDates={{ checkInDate: startDate, checkOutDate: endDate }}
            handleSelectedDate={handleSelectedDates}
          />
        </DatePickerContent>
      </Drawer>
      <ErrorDialog
        errorMessage="Length of stay cannot exceed 28 days. Please modify your date selection."
        open={showErrorModal}
        handleClose={() => {
          setShowErrorModal(false);
        }}
      />
    </>
  );
};

export default DatePickerMobile;
