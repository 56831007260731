import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';
import { PrimaryButton } from '@fctg-ds/core';

export const HotelDetailContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(8),
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  gap: theme.spacing(4),
}));

export const ContentsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: theme.spacing(16),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

export const SubContentsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(8),
}));

export const SelectButtonContainerDesktop = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'block',
  },
}));

export const SelectButtonContainerMobile = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

export const SelectRoomButton = styled(PrimaryButton)(() => ({}));
