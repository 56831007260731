import { Box, styled } from '@mui/material';

export const StyledReviewerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
  backgroundColor: theme.token.color.neutral.background.weak[' '].value,
  borderRadius: theme.spacing(1),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  flexWrap: 'wrap',
  width: 'fit-content',
}));

export const StyledReviewerAvatar = styled('img')(({ theme }) => ({
  height: theme.spacing(5),
}));
