import React from 'react';
import { Checkbox } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';
import { EmitEventProps } from '@src/common/types/types';

export interface AvailabilityProps {
  checked: boolean;
  count: number;
  handleChange: () => void;
  onEmitEvent?: (event: EmitEventProps) => void;
}
export const StyledCount = styled(Typography)(({ theme }) => ({
  color: theme.token.color.neutral.text.weak[' '].value,
}));

export const StyledAvailabilityContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const StyledCheckboxContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  marginRight: theme.spacing(-4),
}));

const Availability = ({ checked, count, handleChange, onEmitEvent }: AvailabilityProps) => {
  const onChange = () => {
    handleChange();
    onEmitEvent &&
      checked &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: 'filterBy',
          interactionLabel: 'includeSoldOut',
        },
      });
  };

  return (
    <StyledAvailabilityContainer data-testid="availability-filter">
      <StyledCheckboxContainer>
        <StyledCheckbox
          onChange={onChange}
          checked={!checked}
          name={'available'}
          data-testid="availability-filter-checkbox"
        />
        <Typography variant="bodyMd" data-testid="availability-filter-label">
          Include sold out stays
        </Typography>
      </StyledCheckboxContainer>
      <StyledCount variant="bodyMd" data-testid="availability-filter-count">
        {count || 0}
      </StyledCount>
    </StyledAvailabilityContainer>
  );
};

export default Availability;
