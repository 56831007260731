import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

export const StyledTravellersBox = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '33%',
  },
}));

export const StyledPersonIcon = styled(PersonIcon)(({ theme }) => ({
  marginTop: theme.spacing(4),
  color: theme.token.color.neutral.icon.weak[' '].value,
}));
