import { Carousel, MediaGrid, SliderModal } from '@fctg-ds/core';
import { Card } from '@mui/material';
import React, { useState } from 'react';
import { ImagesCounter } from './components';
import {
  StyledMediaGridBox,
  StyledCardMedia,
  StyledImageGalleryBox,
  StyledMobileCard,
  StyledModalCardMedia,
} from './styles';

export interface MediaElement {
  url: string;
  caption: string;
}

export interface ImageGalleryProps {
  isMobile?: boolean;
  images: MediaElement[];
}

export const ImageGallery = ({ isMobile, images }: ImageGalleryProps) => {
  const [selectedImage, setSelectedImage] = useState<MediaElement | null>(null);

  const onToggleSliderModal = (image: MediaElement | null) => {
    setSelectedImage(image);
  };

  const showImagesCounter = (isMobile && images.length > 1) || (!isMobile && images.length > 5);

  return (
    <>
      <StyledImageGalleryBox>
        {isMobile ? (
          <Carousel
            loop
            data-testid="image-gallery-mobile-carousel"
            lazyLoad="nearby"
            splideOptionsOverride={{ useScroll: true }}
          >
            {images?.map((image) => (
              <StyledMobileCard key={`mobile-image-${image.url}`}>
                <StyledCardMedia data-splide-lazy={image.url} component="img" />
              </StyledMobileCard>
            ))}
          </Carousel>
        ) : (
          <StyledMediaGridBox data-testid="image-gallery-desktop-media-grid">
            <MediaGrid
              onImageSelect={({ url, description }) => onToggleSliderModal({ url, caption: description })}
              medias={images.slice(0, 5).map(({ url, caption }) => ({ url, description: caption }))}
            />
          </StyledMediaGridBox>
        )}
        {showImagesCounter && <ImagesCounter imagesCount={images.length} />}
      </StyledImageGalleryBox>
      <SliderModal
        startIndex={images.findIndex((image) => image.url === selectedImage?.url)}
        open={!!selectedImage}
        onClose={() => onToggleSliderModal(null)}
      >
        {images.map((image) => {
          return (
            <Card key={`desktop-image-${image.url}`}>
              <StyledModalCardMedia component="img" image={image.url} loading="lazy" />
            </Card>
          );
        })}
      </SliderModal>
    </>
  );
};
