import { styled } from '@fctg-ds/theme';
import { Check } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { RoomOptionProps } from '../../types';

export const StyledRoomOptionsInclusions = styled(Box)<{ roomOptionVariant: RoomOptionProps['roomOptionVariant'] }>(
  ({ theme, roomOptionVariant }) => ({
    display: 'flex',
    flexDirection: roomOptionVariant === 'roomTile' ? 'row' : 'column',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
  }),
);

export const StyledRoomOptionsInclusionItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

export const StyledCheckIcon = styled(Check)(({ theme }) => ({
  color: theme.token.color.success[' '].value,
  width: theme.spacing(5),
  height: 'auto',
}));

export const StyledPromotion = styled(Typography)<{ isBreakfast: boolean }>(({ theme, isBreakfast }) => ({
  color: isBreakfast && theme.token.color.success[' '].value,
}));
