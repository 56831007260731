import { GuestReviewSortOption, GuestReviewsSortOptionLabel } from '@src/common/sort-chip/constants';

export const fetchGuestReviewsLabelByOption = (sortOption: GuestReviewSortOption): GuestReviewsSortOptionLabel => {
  switch (sortOption) {
    case 'most-recent':
      return 'Most recent';
    case 'highest-rating':
      return 'Highest score';
    case 'lowest-rating':
      return 'Lowest score';
    default:
      return 'Sort by';
  }
};
