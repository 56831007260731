import { isEqual, isValid } from 'date-fns';
import { SelectionDate } from '@src/components/calendar-date-picker/types';

export const isSameDatesSelected = (startDate: SelectionDate, endDate: SelectionDate): boolean => {
  if (!startDate || !startDate.day || !endDate || !endDate.day) return false;

  const startDateFormat = new Date(startDate.year, startDate.month - 1, startDate.day);
  const endDateFormat = new Date(endDate.year, endDate.month - 1, endDate.day);

  if (!isValid(startDateFormat) || !isValid(endDateFormat)) return false;

  return isEqual(startDateFormat, endDateFormat);
};
