import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@fctg-ds/theme';
import { Popover } from '@fctg-ds/core';

export const StyledUserRatingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: `${theme.spacing(6)} ${theme.spacing(1.5)}`,
  alignItems: 'end',
  flexWrap: 'wrap',
}));

export const StyledUserRatingInner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(4),
  alignItems: 'end',
}));

export const StyledUserRatingBox = styled(Box)<{ isGreen: boolean; size: 'lg' | 'sm' }>(({ isGreen, size, theme }) => ({
  display: 'flex',
  width: size === 'lg' ? theme.spacing(14) : theme.spacing(13),
  height: size === 'lg' ? theme.spacing(14) : theme.spacing(13),
  padding: theme.spacing(1),
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: isGreen ? theme.token.color.green[500].value : theme.token.color.neutral.icon.weak[' '].value,
  borderRadius: theme.spacing(2),
}));

export const StyledUserRating = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.token.color.success.background[' '].value,
}));

export const StyledReviewsCountText = styled(Typography)(({ theme }) => ({
  color: theme.token.color.neutral.text.weak[' '].value,
}));

export const StyledInfoIcon = styled(InfoOutlinedIcon)<{ size: 'lg' | 'sm' }>(({ size, theme }) => ({
  color: theme.token.color.neutral.text.weak[' '].value,
  fontSize: theme.spacing(4),
  ':hover': {
    cursor: 'pointer',
  },
  marginTop: size === 'lg' ? theme.spacing(1) : theme.spacing(0.75),
}));

export const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: theme.spacing(3),
  },
}));

export const StyledPopoverContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  width: theme.spacing(126),
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const StyledSeeReviewsLink = styled(Typography)(() => ({
  textDecoration: 'underline',
  cursor: 'pointer',
}));
