import React, { useState } from 'react';
import TravellersSelectorDesktop from './components/travellers-selector-desktop/main';
import { PaxCountUpdater, PaxType, Travellers } from './types';
import TravellersSelectorMobile from './components/travellers-selector-mobile/main';
import { useTravellersError } from './hooks/useTravellersError';
import { isSameTravellersSelected } from '@src/helpers/isSameTravellersSelected/isSameTravellersSelected';
import { CabinClassType } from '@src/common/types/types';

export interface TravellersSelectorProps {
  open: boolean;
  isMobileOrTablet: boolean;
  travellers: Travellers;
  cabinClass?: CabinClassType;
  anchorEl?: HTMLInputElement;
  inputComponent?: React.ReactNode;
  onSubmit: (travellers: Travellers, cabinClass?: CabinClassType) => void;
  onClose: () => void;
}

const DEFAULT_TRAVELLERS: Travellers = { adult: 2, childAges: [], infant: 0 };

const TravellersSelector = ({
  open,
  isMobileOrTablet,
  travellers = DEFAULT_TRAVELLERS,
  cabinClass: cabinClassProp,
  anchorEl,
  inputComponent,
  onSubmit,
  onClose,
}: TravellersSelectorProps) => {
  const [adult, setAdult] = useState<number>(travellers?.adult);
  const [childAges, setChildAges] = useState<number[]>(travellers?.childAges ? [...travellers.childAges] : []);
  const [infant, setInfant] = useState<number>(travellers?.infant);
  const [isChildAgesValid, setIsChildAgesValid] = useState<boolean>(true);
  const [paxType, setPaxType] = useState<PaxType | null>(null);
  const [cabinClass, setCabinClass] = useState<CabinClassType | null>(cabinClassProp);

  const paxCountUpdater: PaxCountUpdater = {
    adult: {
      increase: () => setAdult((adultCount) => adultCount + 1),
      decrease: () => setAdult((adultCount) => adultCount - 1),
    },
    child: {
      increase: () => {
        setChildAges([...childAges, null]);
      },
      decrease: () => {
        const newChildAges = [...childAges];
        newChildAges.pop();
        setChildAges([...newChildAges]);
        if (!newChildAges.includes(null)) {
          setIsChildAgesValid(true);
        }
      },
    },
    infant: {
      increase: () => setInfant((infantCount) => infantCount + 1),
      decrease: () => setInfant((infantCount) => infantCount - 1),
    },
  };
  const onUpdateChildrenCount = (childrenCount: number) => {
    if (childrenCount < childAges.length) {
      paxCountUpdater['child'].decrease();
    } else if (childrenCount > childAges.length) {
      paxCountUpdater['child'].increase();
    }
  };

  const onSelectChildAge = (index: number, value: number) => {
    childAges[index] = value;
    const newChildAges = [...childAges];
    setChildAges(newChildAges);
    setIsChildAgesValid(true);
  };

  const { error } = useTravellersError({
    travellers: { adult, childAges, infant },
    paxCountUpdater,
    paxType,
  });

  const onDone = () => {
    if (childAges.includes(null)) {
      setIsChildAgesValid(false);
    } else {
      onClose();
      if (!isSameTravellersSelected(travellers, { adult, childAges, infant }) || cabinClass !== cabinClassProp) {
        onSubmit?.({ adult, childAges, infant }, cabinClass);
        setChildAges([...childAges]); // Need this to update the local state
      }
    }
  };

  const handleAdultChange = (newAdultCount: number) => {
    setPaxType('adult');
    setAdult(newAdultCount);
  };

  const handleChildAgesChange = (newChildrenCount: number) => {
    setPaxType('child');
    onUpdateChildrenCount(newChildrenCount);
  };

  const handleInfantChange = (newInfantCount: number) => {
    setPaxType('infant');
    setInfant(newInfantCount);
  };

  const resetTravellers = () => {
    setAdult(travellers.adult);
    setChildAges(travellers.childAges);
    setInfant(travellers.infant);
    setIsChildAgesValid(true);
  };

  const handleClose = () => {
    resetTravellers();
    onClose();
  };

  const handleCabinClass = (value: CabinClassType) => {
    setCabinClass(value);
  };

  return isMobileOrTablet ? (
    <TravellersSelectorMobile
      open={open}
      onClose={handleClose}
      onDone={onDone}
      adult={adult}
      childAges={childAges}
      infant={infant}
      handleAdultChange={handleAdultChange}
      handleChildAgesChange={handleChildAgesChange}
      handleInfantChange={handleInfantChange}
      handleCabinClass={handleCabinClass}
      isChildAgesValid={isChildAgesValid}
      onSelectChildAge={onSelectChildAge}
      cabinClass={cabinClass}
      error={error}
    />
  ) : (
    <TravellersSelectorDesktop
      open={open}
      anchorElement={anchorEl}
      onClose={handleClose}
      onDone={onDone}
      adult={adult}
      childAges={childAges}
      infant={infant}
      handleAdultChange={handleAdultChange}
      handleChildAgesChange={handleChildAgesChange}
      handleInfantChange={handleInfantChange}
      handleCabinClass={handleCabinClass}
      isChildAgesValid={isChildAgesValid}
      onSelectChildAge={onSelectChildAge}
      cabinClass={cabinClass}
      error={error}
      inputComponent={inputComponent}
    />
  );
};

export default TravellersSelector;
