import React from 'react';
import SortOptionsList from '@src/components/sort-chip/components/sort-option-list/main';
import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';
import { EmitEventProps } from '@src/common/types/types';

interface SortByProps {
  onChange: (value: string) => void;
  selected: string | null;
  sortOptions: string[];
  onEmitEvent?: (event: EmitEventProps) => void;
  fetchLabelByOption: (sortOption: string) => string;
}

export const StyledSortByContainer = styled(Box)(() => ({
  display: 'contents',
  width: '100%',
}));

export const SortByMobile = ({ selected, sortOptions, onChange, onEmitEvent, fetchLabelByOption }: SortByProps) => (
  <StyledSortByContainer data-testid="sort-by-mobile">
    <SortOptionsList
      sortValue={selected}
      sortOptions={sortOptions}
      handleOnSelect={onChange}
      onEmitEvent={onEmitEvent}
      fetchLabelByOption={fetchLabelByOption}
    />
  </StyledSortByContainer>
);
