import React from 'react';
import { Typography } from '@mui/material';
import { StyledScoreBox } from './styles';

interface ScoreProps {
  rating: number;
  superlative: string;
}

const Source = ({ rating, superlative }: ScoreProps) => {
  const TOTAL_SCORE = 10;

  return (
    <StyledScoreBox data-testid="guest-review-tile-rating">
      <Typography variant="priceLg" data-testid="guest-review-tile-score">
        {rating}/{TOTAL_SCORE}
      </Typography>
      <Typography variant="priceLg" data-testid="guest-review-tile-superlative">
        {superlative}
      </Typography>
    </StyledScoreBox>
  );
};

export default Source;
