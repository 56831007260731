import React from 'react';
import {
  StyledMobileDatesTextField,
  StyledAutocompleteTextField,
  StyledFieldGroup,
  StyledFlightForward,
  StyledLocationPin,
  StyledPackagesResultsSearchForm,
  StyledTravellersTextField,
} from './styles';
import { PackagesResultsSearchFormProps } from '../../types';
import SearchFormDatePicker from '@src/components/search-form-date-picker/main';
import { useSearchFormDatePicker } from '../../hooks/useSearchFormDatePicker';
import { useAutocomplete } from '../../hooks/useAutocomplete';
import Autocomplete from '@src/components/autocomplete/main';
import { useTravellersSelector } from '../../hooks/useTravellersSelector';
import TravellersSelector from '@src/components/travellers-selector/main';
import { getCabinClassLabel } from '@src/components/travellers-selector/components/cabin-class/constants';
import { getTravellersText } from '@src/helpers/getTravellersText/getTravellersText';
import { OptionType } from '@src/components/autocomplete/types';
import { useErrorDialog } from '../../hooks/useErrorDialog';
import ErrorDialog from '../error-dialog/main';

const PackagesResultsSearchFormMobile = ({
  origin,
  destination,
  departureDate,
  returnDate,
  travellers,
  cabinClass,
  brandRegion,
  onChangeEvents,
  onFetchOptions,
}: PackagesResultsSearchFormProps) => {
  const { open, mode, options, onOpen, onChange, onFetch, onClose, onClear } = useAutocomplete({
    onChangeEvents,
    onFetchOptions,
  });
  const datePickerControls = useSearchFormDatePicker({
    onChangeEvents,
    departureDate,
    returnDate,
  });
  const travellersSelectorControls = useTravellersSelector({
    onChangeEvents,
  });
  const errorDialogControls = useErrorDialog({
    onChangeEvents,
    origin,
    destination,
    returnDate,
    departureDate,
    autocompleteOnChange: onChange,
    autocompleteOnOpen: onOpen,
  });

  const travellersSelectorLabel = `${getTravellersText(travellers)} • ${getCabinClassLabel(cabinClass)}`;

  return (
    <StyledPackagesResultsSearchForm>
      <StyledFieldGroup layout={[2]}>
        <StyledAutocompleteTextField
          value={origin?.displayValue}
          placeholder="Where from?"
          hiddenLabel
          startAdornment={<StyledFlightForward />}
          autoComplete="off"
          onClick={() => onOpen('origin')}
        />
        <StyledAutocompleteTextField
          id="destination-selection"
          value={destination?.displayValue}
          placeholder="Where to?"
          hiddenLabel
          startAdornment={<StyledLocationPin />}
          autoComplete="off"
          onClick={() => onOpen('destination')}
        />
      </StyledFieldGroup>
      <StyledMobileDatesTextField
        value={datePickerControls.displayValue}
        placeholder="Enter dates"
        hiddenLabel
        fullWidth
        onClick={datePickerControls.onOpen}
      />
      <StyledTravellersTextField
        value={travellersSelectorLabel}
        hiddenLabel
        fullWidth
        ref={travellersSelectorControls.ref}
        onClick={travellersSelectorControls.onOpen}
        data-testid="travellers-selection-mobile"
      />
      <Autocomplete
        mode={mode}
        options={options}
        onChange={(value: OptionType) => errorDialogControls.onValidatedChange(value, mode)}
        onClose={onClose}
        onClear={onClear}
        fetchOptions={onFetch}
        isOpen={open}
        brandRegion={brandRegion}
        desktopBreakpoint="lg"
      />
      <TravellersSelector
        isMobileOrTablet={true}
        travellers={travellers}
        cabinClass={cabinClass}
        open={travellersSelectorControls.open}
        onSubmit={travellersSelectorControls.onSubmit}
        onClose={travellersSelectorControls.onClose}
        anchorEl={travellersSelectorControls.ref?.current}
      />
      <SearchFormDatePicker
        isOpen={datePickerControls.open}
        onClose={datePickerControls.onClose}
        onChange={datePickerControls.onChange}
        dates={datePickerControls.dates}
        isMobile={true}
      />
      <ErrorDialog errorStatus={errorDialogControls.errorStatus} onClose={errorDialogControls.onClose} />
    </StyledPackagesResultsSearchForm>
  );
};

export default PackagesResultsSearchFormMobile;
