import React from 'react';
import { StyledPropertyClassSelection, StyledPropertyClassStarIcon } from '../styles';
import { Typography } from '@mui/material';
import { PropertyClassSelectionProps } from '../types';

export const PropertyClassSelection = ({ rating, ratingDescription }: PropertyClassSelectionProps) => (
  <>
    <StyledPropertyClassSelection>
      <StyledPropertyClassStarIcon />
      <Typography variant="subheadingSm">{rating}</Typography>
    </StyledPropertyClassSelection>
    <Typography variant="captionMd">{ratingDescription}</Typography>
  </>
);
