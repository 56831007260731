import React from 'react';
import { Box } from '@mui/material';
import { PoliciesProps, Policy } from '../../types';
import CheckInOutTime from '../check-in-out-time/main';
import CheckInOutInstructions from '../check-in-out-instructions/main';
import SectionHeader from '../section-header/main';
import { styled } from '@fctg-ds/theme';

interface CheckInOutProps {
  checkInTime: PoliciesProps['checkInTime'];
  checkOutTime: PoliciesProps['checkOutTime'];
  checkInInstructions?: Policy['checkInInstructions'];
}

const StyledCheckInOutContentInner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  width: '100%',
}));

const CheckInOut = ({ checkInTime, checkOutTime, checkInInstructions }: CheckInOutProps) => {
  return (
    <SectionHeader type={'check'} title={'Check-in instructions'}>
      <StyledCheckInOutContentInner data-testid="policiesCheckInOutInner">
        <CheckInOutTime checkInTime={checkInTime} checkOutTime={checkOutTime} />
        <CheckInOutInstructions checkInInstructions={checkInInstructions} />
      </StyledCheckInOutContentInner>
    </SectionHeader>
  );
};

export default CheckInOut;
