import React from 'react';
import { CabinClassType } from '@src/common/types/types';
import { Divider, MenuItem, MenuList } from '@fctg-ds/core';
import { StyledCabinClassBox, StyledDividerBox } from './style';
import { getCabinClassLabel } from './constants';

interface CabinClassProps {
  value: CabinClassType;
  onChange: (value: CabinClassType) => void;
}

const OPTIONS: CabinClassType[] = ['ECONOMY', 'PREMIUM_ECONOMY', 'BUSINESS', 'FIRST'];

export const CabinClass = ({ value, onChange }: CabinClassProps) => {
  return (
    <StyledCabinClassBox>
      <StyledDividerBox>
        <Divider direction="horizontal" />
      </StyledDividerBox>
      <MenuList>
        {OPTIONS.map((option, index) => (
          <MenuItem key={index} onClick={() => onChange(option)} selected={value === option}>
            {getCabinClassLabel(option)}
          </MenuItem>
        ))}
      </MenuList>
    </StyledCabinClassBox>
  );
};
