import React from 'react';
import { Typography } from '@mui/material';
import { formattedCurrency } from '@src/helpers/formattedCurrency';
import { DisplayPriceProps } from '../../types';

const TotalPrice = ({ displayPrice, currency }: DisplayPriceProps) => {
  return (
    <Typography variant="priceXl" data-testid="currentPrice">
      {formattedCurrency(displayPrice, currency, true)}
    </Typography>
  );
};

export default TotalPrice;
