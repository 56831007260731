import React from 'react';
import { DrawerMobileSortOptionsListProps, MobileSortOptionsListProps } from '../types';
import { Drawer, IconButton } from '@fctg-ds/core';
import CloseIcon from '@mui/icons-material/Close';
import { MobileMenuListStyled } from '../styles';
import SortOptionsList from '../sort-option-list/main';

const DrawerMobileSortOptionsList = ({
  open,
  footer,
  sortValue,
  handleOnSelect,
  handleOpenOrClose,
  sortOptions,
  fetchLabelByOption,
  onEmitEvent,
  eventType,
}: DrawerMobileSortOptionsListProps) => {
  const footerWithInternalProp =
    footer &&
    React.cloneElement(footer as JSX.Element, {
      onClose: handleOpenOrClose,
    });

  return (
    <Drawer
      open={open}
      height="100%"
      onClose={handleOpenOrClose}
      onOpen={handleOpenOrClose}
      leftActionBtn={
        <IconButton onClick={handleOpenOrClose}>
          <CloseIcon />
        </IconButton>
      }
      drawerHeaderTitle="Sort By"
    >
      <MobileSortOptionsList
        handleOnClose={handleOpenOrClose}
        sortValue={sortValue}
        sortOptions={sortOptions}
        handleOnSelect={handleOnSelect}
        fetchLabelByOption={fetchLabelByOption}
        onEmitEvent={onEmitEvent}
        eventType={eventType}
      />
      {footerWithInternalProp}
    </Drawer>
  );
};

export const MobileSortOptionsList = ({
  sortValue,
  sortOptions,
  handleOnSelect,
  handleOnClose,
  fetchLabelByOption,
  onEmitEvent,
  eventType,
}: MobileSortOptionsListProps) => (
  <MobileMenuListStyled data-testid="sort-options-list-mobile">
    <SortOptionsList
      isMobile
      sortValue={sortValue}
      sortOptions={sortOptions}
      handleOnClose={handleOnClose}
      handleOnSelect={handleOnSelect}
      fetchLabelByOption={fetchLabelByOption}
      onEmitEvent={onEmitEvent}
      eventType={eventType}
    />
  </MobileMenuListStyled>
);

export default DrawerMobileSortOptionsList;
