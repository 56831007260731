import React from 'react';
import pluralize from 'pluralize';
import { NightsPaxProps } from './types';
import { StyledNightsPax, StyledText } from './styles';

const NightsPax = ({ nights, travellersCount, showFlights = false }: NightsPaxProps) => {
  const nightsText = pluralize('night', nights, true);
  const travellersText = pluralize('travellers', travellersCount, true);

  return (
    <StyledNightsPax data-testid="nightsPax">
      {!showFlights ? (
        <StyledText variant="bodySm" data-testid="nightsPaxTotal">
          Total price
        </StyledText>
      ) : (
        <StyledText variant="bodySm" data-testid="nightsPaxFlightsText">
          Flights +
        </StyledText>
      )}
      <StyledText variant="bodySm" data-testid="nightsPaxNights">
        {nightsText},
      </StyledText>
      <StyledText variant="bodySm" data-testid="nightsPaxTravellers">
        {travellersText}
      </StyledText>
    </StyledNightsPax>
  );
};

export default NightsPax;
