import { Box, Card, CardMedia } from '@mui/material';
import { styled } from '@fctg-ds/theme';

export const StyledMediaGridBox = styled(Box)(() => ({
  cursor: 'pointer',
}));

export const StyledMobileCard = styled(Card)(() => ({
  borderRadius: 0,
  height: '250px',
}));

export const StyledImageGalleryBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    height: '250px',
  },
}));

export const StyledCardMedia = styled(CardMedia)<{ component: string }>(() => ({
  borderRadius: 0,
  height: '250px',
  width: '100%',
}));

export const StyledModalCardMedia = styled(CardMedia)<{ loading: string; component: string }>(() => ({
  borderRadius: 0,
  height: '100%',
  width: '100%',
}));
