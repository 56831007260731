import React from 'react';
import { GetMobileDrawerComponentResultProps, MobileDrawerTypeProps } from './types';
import { AllFilters } from '../all-filters-mobile';
import { PriceSliderMobile } from '../price-slider-mobile';
import { ByGuestReviewsMobile } from '../by-guest-reviews-mobile';
import { PropertyClassMobile } from '../property-class-mobile';
import { AreaMobile } from '../area-mobile';
import { SortByMobile } from '../sort-by-mobile';

export const getMobileDrawerComponent = ({
  activeProps,
  sortOptions,
  onChangeEvents,
  metaData,
  onEmitEvent,
  fetchLabelByOption,
}: MobileDrawerTypeProps): GetMobileDrawerComponentResultProps => {
  return {
    'all-filters': {
      title: 'All filters',
      component: (
        <AllFilters
          activeProps={activeProps}
          onChangeEvents={onChangeEvents}
          metaData={metaData}
          onEmitEvent={onEmitEvent}
          sortOptions={sortOptions}
        />
      ),
    },
    price: {
      title: 'Price',
      component: (
        <PriceSliderMobile
          min={metaData.price?.min}
          max={metaData.price?.max}
          chartData={metaData.price?.chartData}
          onChange={onChangeEvents.onChangePrice}
          value={activeProps.price}
          onEmitEvent={onEmitEvent}
        />
      ),
    },
    sort: {
      title: 'Sort by',
      component: (
        <SortByMobile
          selected={activeProps.sort}
          sortOptions={sortOptions}
          onChange={onChangeEvents.onChangeSort}
          onEmitEvent={onEmitEvent}
          fetchLabelByOption={fetchLabelByOption}
        />
      ),
    },
    'guest-review': {
      title: 'Guest review score',
      component: (
        <ByGuestReviewsMobile
          selected={activeProps.reviews}
          onChange={onChangeEvents.onChangeReview}
          onEmitEvent={onEmitEvent}
        />
      ),
    },
    'property-class': {
      title: 'Property class',
      component: (
        <PropertyClassMobile
          selected={activeProps.rating}
          onChange={onChangeEvents.onChangeRating}
          onEmitEvent={onEmitEvent}
        />
      ),
    },
    area: {
      title: 'Area',
      component: (
        <AreaMobile
          selected={activeProps.area}
          onChange={onChangeEvents.onChangeArea}
          areas={metaData.area}
          onEmitEvent={onEmitEvent}
        />
      ),
    },
  };
};
