import { styled } from '@fctg-ds/theme';
import { BoxProps } from '@mui/material/Box';
import { Box } from '@mui/material';

interface BarProps extends BoxProps {
  active: boolean;
  itemCount: number;
  calculatedHeight: number;
}

export default styled(Box)<BarProps>(({ theme, active, itemCount, calculatedHeight }) => ({
  height: `${calculatedHeight}%`,
  width: `${100 / itemCount}%`,
  borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0px 0px`,
  backgroundColor: active
    ? theme.token.color.neutral.background.strong.hovered.value
    : theme.token.color.neutral.background.medium[' '].value,
}));
