import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';

export const StyledModalBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  paddingLeft: theme.spacing(20),
  paddingRight: theme.spacing(20),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
}));

export const StyledEmptyBox = styled(Box)(() => ({
  height: '300px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledEmptyTypography = styled(Typography)(() => ({
  textAlign: 'center',
}));
