export const parseVerificationSource = (verification_source: string): string => {
  if (!verification_source) return null;

  const verificationSourceLowerCase = verification_source.toLowerCase();

  if (verificationSourceLowerCase.includes('expedia')) {
    return 'https://secure.fclmedia.com/assets/stays/assets/icons/expedia-verified-reviewer.png';
  } else if (verificationSourceLowerCase.includes('hotels')) {
    return 'https://secure.fclmedia.com/assets/stays/assets/icons/hotels-dot-com-verified-reviewer.png';
  } else if (verificationSourceLowerCase.includes('vrbo')) {
    return 'https://secure.fclmedia.com/assets/stays/assets/icons/vrbo-verified-reviewer.png';
  } else {
    return null;
  }
};
