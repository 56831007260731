import { styled } from '@fctg-ds/theme';
import { Box, Icon, Typography } from '@mui/material';
import { TopAmenity } from '@src/components/top-hotel-amenities/types';
import React from 'react';
import { getAmenityIcon } from '@src/helpers/getAmenityIcon/getAmenityIcon';

interface AmenityItemProps {
  topAmenity: TopAmenity;
}

const StyledTopAmenityBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'flex-start',
}));

const AmenityItem = ({ topAmenity }: AmenityItemProps) => {
  return (
    <StyledTopAmenityBox>
      <Icon component={getAmenityIcon(topAmenity)} sx={(theme) => ({ fontSize: theme.spacing(5) })} />
      <Typography variant="bodySmStrong">{topAmenity.name}</Typography>
    </StyledTopAmenityBox>
  );
};

export default AmenityItem;
