export type ResultsSortOption =
  | 'recommended'
  | 'lowest_price'
  | 'highest_price'
  | 'property_class'
  | 'guest_review'
  | null;

export const RESULTS_SORT_OPTIONS: Array<ResultsSortOption> = [
  'recommended',
  'lowest_price',
  'highest_price',
  'property_class',
  'guest_review',
];

export type ResultsSortOptionLabel =
  | 'Recommended'
  | 'Lowest price'
  | 'Highest price'
  | 'Highest property class'
  | 'Highest review score'
  | null;

export const RESULTS_SORT_OPTIONS_LABELS: Array<ResultsSortOptionLabel> = [
  'Recommended',
  'Lowest price',
  'Highest price',
  'Highest property class',
  'Highest review score',
];

export const GTM_SORT_EVENTS = {
  results: {
    interactionValue: 'sortBy',
    interactionLabel: {
      recommended: 'recommended',
      lowest_price: 'priceAsc',
      highest_price: 'priceDesc',
      property_class: 'starRating',
      guest_review: 'userRating',
    },
  },
  guestReviews: {
    interactionValue: 'Guest Reviews',
    interactionLabel: {
      'most-recent': 'sortByMostRecent',
      'highest-rating': 'sortByDesc',
      'lowest-rating': 'sortByAsc',
    },
  },
};

export type GuestReviewSortOption = 'most-recent' | 'highest-rating' | 'lowest-rating';

export const GUEST_REVIEWS_SORT_OPTIONS: Array<GuestReviewSortOption> = [
  'most-recent',
  'highest-rating',
  'lowest-rating',
];

export type GuestReviewsSortOptionLabel = 'Most recent' | 'Highest score' | 'Lowest score' | 'Sort by';

export const GUEST_REVIEWS_SORT_OPTIONS_LABELS: Array<GuestReviewsSortOptionLabel> = [
  'Most recent',
  'Highest score',
  'Lowest score',
];
