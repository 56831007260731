import Attractions from '@src/components/attractions/main';
import { StyledContainer } from './styles';
import React from 'react';
import { AttractionsModalProps } from '../../main';
import { Flight, LocationPin } from '@fctg-ds/icons';

export interface ListProps {
  attractions: AttractionsModalProps['attractions'];
  showDottedLines?: boolean;
}

const List = ({ attractions, showDottedLines }: ListProps) => {
  const { poi, airports } = attractions;

  return (
    <StyledContainer>
      <Attractions
        showDottedLines={showDottedLines}
        label="Points of Interest"
        attractions={poi}
        icon={<LocationPin />}
      />
      {airports.length > 0 && (
        <Attractions showDottedLines={showDottedLines} label="Airports" attractions={airports} icon={<Flight />} />
      )}
    </StyledContainer>
  );
};

export default List;
