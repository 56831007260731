import React from 'react';
import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';

interface PriceLabelProps {
  mode: 'min' | 'max';
  price: string;
}

const StyledPriceLabel = styled(Box)<{ mode: PriceLabelProps['mode'] }>(({ mode }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: mode === 'min' ? 'flex-start' : 'flex-end',
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.token.color.text.weak[' '].value,
}));

const PriceLabel = ({ mode, price }: PriceLabelProps) => {
  const label = mode === 'min' ? 'Min price' : 'Max price';

  return (
    <StyledPriceLabel mode={mode} data-testid="price-label">
      <StyledLabel variant="captionMd">{label}</StyledLabel>
      <Typography variant="bodyMd">{price}</Typography>
    </StyledPriceLabel>
  );
};

export default PriceLabel;
