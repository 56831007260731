import React, { PropsWithChildren } from 'react';
import { StyledBoxChip } from './styles';
import { BoxChipProps } from './types';

export const BoxChip = ({ isActive, children, fullWidth, onChange }: PropsWithChildren<BoxChipProps>) => {
  return (
    <StyledBoxChip isActive={isActive} fullWidth={fullWidth} onClick={onChange} role="menuitem">
      {children}
    </StyledBoxChip>
  );
};
