import { Popover, TextField } from '@fctg-ds/core';
import PersonIcon from '@mui/icons-material/Person';
import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  width: '100%',
  ['& > *']: {
    borderWidth: '0 !important',
  },
  ['& input']: {
    padding: theme.token.spacing['4'].value,
  },
}));

export const StyledPopover = styled(Popover)<{ width: number }>(({ width, theme }) => ({
  top: theme.spacing(-4),
  '& .MuiPaper-root': {
    minWidth: width,
  },
}));

export const StyledPersonIcon = styled(PersonIcon)(({ theme }) => ({
  color: theme.token.color.icon[' '].value,
}));

export const StyledPopoverContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledFooter = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  boxShadow: `0 -1px 0 ${theme.token.color.border.disabled.value}`,
}));
