import React from 'react';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { StyledFab, StyledFabBox } from './styles';
import { EmitEventProps } from '@src/common/types/types';

export interface MapFabProps {
  onMapClick: () => void;
  showFab: boolean;
  onEmitEvent?: (event: EmitEventProps) => void;
}

export const MapFab = ({ showFab, onMapClick, onEmitEvent }: MapFabProps) => {
  return (
    <>
      {showFab && (
        <StyledFabBox>
          <StyledFab
            variant="extended"
            size="small"
            onClick={() => {
              onEmitEvent &&
                onEmitEvent({
                  event: 'trackInteraction',
                  data: {
                    interactionValue: 'mapFilter',
                    interactionLabel: 'openModal',
                  },
                });
              onMapClick();
            }}
          >
            <FmdGoodIcon />
            Show on map
          </StyledFab>
        </StyledFabBox>
      )}
    </>
  );
};
