import React from 'react';
import { Typography } from '@mui/material';
import {
  StyledRoomOptionsLabel,
  StyledTopRoomOptionContainer,
  StyledBottomRoomOptionContainer,
  StyledBottomRoomOptionInner,
  StyledRoomOptionContainer,
  StyledHeaderRoomOptionContainer,
} from './styles';
import { RoomOptionProps } from './types';
import { Badges, Inclusions, RefundType, SelectButton, PackagesRoomPrice, StaysRoomPrice } from './components';
import UrgencyIndicator from '../urgency-indicator/main';

const RoomOption = (props: RoomOptionProps) => {
  const {
    roomId,
    footer,
    roomOption,
    cheapest,
    roomOptionVariant,
    nights,
    travellers,
    variant,
    handleSelect,
    onEmitEvent,
  } = props;

  return (
    <StyledRoomOptionContainer data-testid="room-option-container">
      <StyledTopRoomOptionContainer data-testid="room-options-top-container">
        <StyledHeaderRoomOptionContainer>
          <StyledRoomOptionsLabel data-testid="room-options-label">
            <Typography variant="subheadingMd">{roomOption.shortName}</Typography>
          </StyledRoomOptionsLabel>
          {variant === 'stays' ? (
            <Badges cheapest={cheapest} discount={roomOption.price.percentageSaved} />
          ) : (
            roomOptionVariant === 'roomTile' && roomOption.urgencyIndicator && <UrgencyIndicator variant={variant} />
          )}
        </StyledHeaderRoomOptionContainer>
        <Inclusions roomOptionVariant={roomOptionVariant} promotions={roomOption.promotions} />
      </StyledTopRoomOptionContainer>
      <StyledBottomRoomOptionContainer data-testid="room-options-bottom-container">
        <StyledBottomRoomOptionInner>
          <RefundType
            cancellationPolicies={roomOption.cancellationPolicies}
            refundable={roomOption.refundable}
            freeCancellationDate={roomOption.freeCancellationDate}
            roomOptionVariant={roomOptionVariant}
          />
          {variant === 'stays' ? (
            <StaysRoomPrice
              displayPrice={roomOption.price.total}
              currency={roomOption.price.currency}
              originalPrice={roomOption.price.originalPrice}
              amountSaved={roomOption.price.amountSaved}
              urgent={roomOption.urgencyIndicator}
              nights={nights}
              travellers={travellers}
            />
          ) : (
            <PackagesRoomPrice
              displayPrice={roomOption.price.priceDifference || 0}
              currency={roomOption.price.currency}
              originalPrice={roomOption.price.originalPrice}
              amountSaved={roomOption.price.amountSaved}
              urgent={roomOption.urgencyIndicator}
              nights={nights}
              travellers={travellers}
              roomOptionVariant={roomOptionVariant}
            />
          )}
        </StyledBottomRoomOptionInner>
        {footer}
        <SelectButton
          handleSelect={handleSelect}
          onEmitEvent={onEmitEvent}
          roomId={roomId}
          roomRateId={roomOption.roomRateId}
          roomOptionVariant={roomOptionVariant}
        />
      </StyledBottomRoomOptionContainer>
    </StyledRoomOptionContainer>
  );
};

export default RoomOption;
