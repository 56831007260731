/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { ScrollList } from '@fctg-ds/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterIcon from '@src/assets/icons/FilterIcon';
import { MobileDrawerType } from '../mobile/types';
import { FilterBarActive, FilterBarChangeEvents } from '../../types';
import {
  getAreaChipLabel,
  getGuestReviewChipLabel,
  getPriceChipLabel,
  getPropertyClassChipLabel,
} from './helpers/getFilterChipLabel';
import {
  isAreaChipActive,
  isAvailableChipActive,
  isFullyRefundableChipActive,
  isGuestReviewChipActive,
  isPriceChipActive,
  isPropertyClassChipActive,
  isSortChipActive,
} from './helpers/isFilterChipActive';
import { StyledChip, StyledDotBox, StyledDot } from './styles';
import { EmitEventProps } from '@src/common/types/types';

interface ChipsProps {
  handleSelect: (value: MobileDrawerType) => void;
  activeProps: FilterBarActive;
  onChangeEvents: FilterBarChangeEvents;
  fetchLabelByOption: (sortOption: string) => string;
  onEmitEvent?: (event: EmitEventProps) => void;
}

export const FilterChips = ({
  activeProps,
  handleSelect,
  onChangeEvents,
  fetchLabelByOption,
  onEmitEvent,
}: ChipsProps) => {
  const { rating, price, reviews, area, refundable, available, sort } = activeProps;

  return (
    <ScrollList
      data-testid="stays-filter-mobile-chips"
      autoWidth
      overflowContainer
      spacing={2}
      ScrollListItemSx={(theme) => ({ width: 'auto', marginTop: theme.spacing(2) })}
    >
      <StyledChip label="All Filters" onClick={() => handleSelect('all-filters')} startIcon={<FilterIcon />} />
      <StyledChip
        label={sort ? fetchLabelByOption(sort) : 'Sort'}
        isActive={isSortChipActive(sort)}
        onClick={() => handleSelect('sort')}
        endIcon={<ExpandMoreIcon />}
      />
      <StyledChip
        label={getPriceChipLabel(price)}
        isActive={isPriceChipActive(price)}
        onClick={() => handleSelect('price')}
        endIcon={<ExpandMoreIcon />}
        sx={{
          height: '32px',
        }}
      />
      <StyledChip
        label={getGuestReviewChipLabel(reviews)}
        isActive={isGuestReviewChipActive(reviews)}
        onClick={() => handleSelect('guest-review')}
        endIcon={<ExpandMoreIcon />}
      />
      <StyledChip
        label={getPropertyClassChipLabel(rating)}
        isActive={isPropertyClassChipActive(rating)}
        onClick={() => handleSelect('property-class')}
        endIcon={<ExpandMoreIcon />}
      />
      <StyledChip
        label={getAreaChipLabel(area)}
        isActive={isAreaChipActive(area)}
        onClick={() => handleSelect('area')}
        endIcon={<ExpandMoreIcon />}
      />
      <StyledDotBox>
        <StyledDot />
      </StyledDotBox>
      <StyledChip
        label="Fully refundable"
        isActive={isFullyRefundableChipActive(refundable)}
        onClick={() => {
          onEmitEvent &&
            !isFullyRefundableChipActive(refundable) &&
            onEmitEvent({
              event: 'trackInteraction',
              data: {
                interactionValue: 'filterBy',
                interactionLabel: 'fullyRefundable',
              },
            });
          onChangeEvents.onChangeRefundable();
        }}
      />
      <StyledChip
        label="Include sold out"
        isActive={!isAvailableChipActive(available)}
        onClick={() => {
          onEmitEvent &&
            isAvailableChipActive(available) &&
            onEmitEvent({
              event: 'trackInteraction',
              data: {
                interactionValue: 'filterBy',
                interactionLabel: 'includeSoldOut',
              },
            });
          onChangeEvents.onChangeAvailable();
        }}
      />
    </ScrollList>
  );
};
