import React from 'react';
import Header from '../header/main';
import { HotelDetailProps } from './types';
import UserRating from '../user-rating';
import { Typography } from '@mui/material';
import MapSection from './components/map-section/main';
import {
  ContentsContainer,
  HeaderContainer,
  HotelDetailContainer,
  SubContentsContainer,
  SelectButtonContainerDesktop,
  SelectButtonContainerMobile,
  SelectRoomButton,
} from './styles';
import TopHotelAmenities from '../top-hotel-amenities/main';

export const HotelDetail = ({
  isMobile,
  hotelName,
  starRating,
  location,
  guestRating,
  description,
  mapKey,
  hotelAddress,
  hotelCoordinates,
  amenities,
  showSeeMoreReviewsLink,
  attractions,
  onEmitEvent,
  scrollToRooms,
  handleShowGuestReviews,
}: HotelDetailProps) => {
  const SelectButton = () => (
    <SelectRoomButton data-testid="hotel-detail-button" onClick={scrollToRooms} variant="contained" size="large">
      Select your room
    </SelectRoomButton>
  );

  return (
    <HotelDetailContainer>
      <HeaderContainer>
        <Header hotelName={hotelName} city={location} starRating={starRating} />
        <SelectButtonContainerDesktop data-testid="desktop-select-button-container">
          <SelectButton />
        </SelectButtonContainerDesktop>
      </HeaderContainer>
      <ContentsContainer>
        <SubContentsContainer>
          <UserRating
            {...guestRating}
            size={'lg'}
            showSeeMoreReviewsLink={showSeeMoreReviewsLink}
            handleShowGuestReviews={handleShowGuestReviews}
            onEmitEvent={onEmitEvent}
          />
          <SelectButtonContainerMobile data-testid="mobile-select-button-container">
            <SelectButton />
          </SelectButtonContainerMobile>
          <Typography data-testid="hotel-detail-description">{description}</Typography>
          <TopHotelAmenities amenities={amenities} isMobile={isMobile} />
        </SubContentsContainer>
        <SubContentsContainer>
          <MapSection
            mapKey={mapKey}
            hotelName={hotelName}
            hotelCoordinates={hotelCoordinates}
            hotelAddress={hotelAddress}
            isMobile={isMobile}
            attractions={attractions}
            onEmitEvent={onEmitEvent}
          />
        </SubContentsContainer>
      </ContentsContainer>
    </HotelDetailContainer>
  );
};
