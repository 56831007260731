import { StaysDates } from '@src/common/types/types';
import React, { useState } from 'react';
import DatePickerDesktop from '../date-picker-desktop/main';
import DatePickerMobile from '../date-picker-mobile';

import ErrorDialog from '../error-dialog';
import { SearchFormDatePickerProps } from './types';

const SearchFormDatePicker = ({ isOpen, onClose, onChange, dates, anchorEl, isMobile }: SearchFormDatePickerProps) => {
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const setNewDates = (dates: StaysDates) => {
    onChange({ departureDate: dates.checkInDate, returnDate: dates.checkOutDate });
  };

  return (
    <>
      {isMobile ? (
        <DatePickerMobile
          open={isOpen}
          dates={{ checkInDate: dates.departureDate, checkOutDate: dates.returnDate }}
          handleClose={onClose}
          setNewCheckInOutDates={setNewDates}
        />
      ) : (
        <DatePickerDesktop
          open={isOpen}
          dates={dates}
          handleClose={onClose}
          onChange={onChange}
          anchorEl={anchorEl}
          setShowErrorModal={setShowErrorModal}
        />
      )}
      <ErrorDialog
        errorMessage="Length of stay cannot exceed 28 days. Please modify your date selection."
        open={showErrorModal}
        handleClose={() => {
          setShowErrorModal(false);
        }}
      />
    </>
  );
};

export default SearchFormDatePicker;
