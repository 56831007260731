import { Amenities, TopAmenity } from '@src/components/top-hotel-amenities/types';

export const getTopAmenities = (amenities: Amenities, desiredLength: number): TopAmenity[] => {
  if (!amenities) return [];

  const allAmenities = amenities.all;
  const topAmenities = amenities.top;

  const slicedTopAmenities: TopAmenity[] = topAmenities.slice(0, desiredLength);
  if (slicedTopAmenities.length === desiredLength) return slicedTopAmenities;

  const backFilledAmenities = allAmenities.reduce((accumulated, currentAmenity) => {
    if (accumulated.length === desiredLength) return accumulated;

    if (accumulated.find((amenity) => amenity.name === currentAmenity)) return accumulated;

    return [...accumulated, { name: currentAmenity, type: '' }];
  }, slicedTopAmenities);

  return backFilledAmenities;
};
