import { TextField } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

export const StyledFormBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4),
    flexDirection: 'row',
    borderRadius: theme.spacing(4),
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 2px rgba(0, 0, 0, 0.04), 0px 8px 12px -4px rgba(0, 0, 0, 0.02)',
  },
}));

export const StyledTextfield = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    marginLeft: theme.spacing(1.5),
    caretColor: 'transparent',
    cursor: 'pointer',
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.token.color.neutral.background.medium[' '].value,
    borderColor: theme.token.color.neutral.background.medium[' '].value,
  },
}));

export const StyledTextfieldShineThrough = styled(StyledTextfield)<{ width: number }>(({ theme, width }) => ({
  backgroundColor: theme.token.color.background.weak[' '].value,
  width,
}));
