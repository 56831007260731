import React from 'react';
import { Typography } from '@mui/material';
import { StyledSourceBox, StyledVerificationSource } from './styles';
import { parseVerificationSource } from '@src/helpers/parseVerificationSource/parseVerificationSource';

interface SourceProps {
  verification_source: string;
}

const Source = (props: SourceProps) => {
  const verificationSourceIconLink = parseVerificationSource(props.verification_source);

  if (!verificationSourceIconLink) return null;

  return (
    <StyledSourceBox data-testid="guest-review-tile-verification-source">
      <StyledVerificationSource src={verificationSourceIconLink} />
      <Typography variant="captionSm">verified reviewer</Typography>
    </StyledSourceBox>
  );
};

export default Source;
