import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { TextField } from '@fctg-ds/core';

export const StyledDatesBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    width: '66%',
  },
  [theme.breakpoints.between('lg', 1090)]: {
    minWidth: '682px',
  },
  justifyContent: 'space-between',
}));

export const StyledDatePickerIcon = styled(DateRangeIcon)(({ theme }) => ({
  marginTop: theme.spacing(4),
  color: theme.token.color.neutral.icon.weak[' '].value,
}));

export const StyledTextfield = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    marginLeft: theme.spacing(1.5),
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.token.color.neutral.background.medium[' '].value,
    borderColor: theme.token.color.neutral.background.medium[' '].value,
  },
}));
