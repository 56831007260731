import React, { useState } from 'react';
import { Drawer, IconButton } from '@fctg-ds/core';
import CloseIcon from '@mui/icons-material/Close';
import { MobileDrawerType, MobileFilterBarProps } from './types';
import { getMobileDrawerComponent } from './constants';
import { FilterChips } from '../filter-chips';
import { Box } from '@mui/material';
import { StyledPropertyClassDialog } from './styles';
import { Footer } from '../footer';
import { fetchResultsLabelByOption } from '@src/helpers/fetchSortByLabel/fetchResultsLabelByOption';

export const MobileFilterbar = ({
  activeProps,
  onChangeEvents,
  metaData,
  onEmitEvent,
  sortOptions,
}: MobileFilterBarProps) => {
  const [componentType, setComponentType] = useState<MobileDrawerType | undefined>(undefined);
  const currentDrawerComponent = getMobileDrawerComponent({
    activeProps,
    onChangeEvents,
    metaData,
    onEmitEvent,
    sortOptions,
    fetchLabelByOption: fetchResultsLabelByOption,
  })[componentType];
  const currentDrawerComponentTitle = currentDrawerComponent?.title;
  const fullHeightComponents = ['All filters', 'Area'];

  const handleSelect = (type: MobileDrawerType) => {
    setComponentType(type);
  };

  const handleClose = () => {
    setComponentType(undefined);
  };

  return (
    <>
      <FilterChips
        activeProps={activeProps}
        handleSelect={handleSelect}
        onChangeEvents={onChangeEvents}
        fetchLabelByOption={fetchResultsLabelByOption}
        onEmitEvent={onEmitEvent}
      />
      <Drawer
        open={!!currentDrawerComponent}
        drawerHeaderTitle={currentDrawerComponent?.title}
        onClose={handleClose}
        onOpen={() => true}
        height={!fullHeightComponents.includes(currentDrawerComponentTitle) ? '55%' : '100%'}
        leftActionBtn={
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        }
      >
        {currentDrawerComponentTitle === 'Property class' ? (
          <StyledPropertyClassDialog>{currentDrawerComponent?.component}</StyledPropertyClassDialog>
        ) : (
          <Box mb={16}>{currentDrawerComponent?.component}</Box>
        )}
        <Footer
          metaData={metaData}
          onChangeEvents={onChangeEvents}
          isSortBy={componentType === 'sort'}
          onClose={handleClose}
        />
      </Drawer>
    </>
  );
};
