import React from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';
import MapFilterMap from '@components/map-filter/components/map';
import { MapFilterProps } from './types';
import { MapWrapper } from './styles';
import { MapProductTileWrapper } from './components/product-tile/main';

const MapFilter = ({ destination, productTileData, mapKey, onEmitEvent, variant = 'stays' }: MapFilterProps) => {
  return (
    <MapProductTileWrapper productTileData={productTileData} onEmitEvent={onEmitEvent} variant={variant}>
      <MapWrapper data-testid="stays-map-filter">
        <APIProvider apiKey={mapKey}>
          <MapFilterMap destination={destination} productTileData={productTileData} onEmitEvent={onEmitEvent} />
        </APIProvider>
      </MapWrapper>
    </MapProductTileWrapper>
  );
};

export default MapFilter;
