import React from 'react';
import CardMedia from '@mui/material/CardMedia';

export interface TileImageProps {
  imageHeight?: number;
  imageUrl: string;
}

export const DestinationTileImage = ({ imageHeight, imageUrl }: TileImageProps) => {
  return (
    <CardMedia
      data-testid="tile-image"
      component="img"
      image={imageUrl}
      sx={(theme) => ({
        height: imageHeight,
        borderRadius: theme.token.radius.base.value,
      })}
    />
  );
};

export default DestinationTileImage;
