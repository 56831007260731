import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { MapSectionProps } from './types';
import HotelMapModal from '../map-modal/main';
import AttractionsModal from '@src/components/attractions-modal/main';
import Attraction from '@src/components/attraction/main';
import { LocationPin } from '@fctg-ds/icons';
import { formatHotelAddress } from '@src/helpers/formatHotelAddress';
import { generateStaticMapUrl } from '@src/helpers/generateStaticMapUrl/generateStaticMapUrl';
import {
  StyledHotelMapContainer,
  StyledHotelMapAddress,
  StyledHotelMapLink,
  StyledHotelAddressContainer,
  StyledNearbyAttractionsBox,
  StyledInitialNearbyAttractionsBox,
  StyledNearbyAttractionsLink,
  StyledMapImageBox,
} from './styles';

const MapSection = ({
  hotelCoordinates,
  hotelAddress,
  hotelName,
  mapKey,
  isMobile,
  attractions,
  onEmitEvent,
}: MapSectionProps) => {
  const { poi, airports } = attractions;

  const [openAttractions, setAttractionsOpen] = useState<boolean>(false);
  const [openMap, setMapOpen] = useState<boolean>(false);
  const modalTitle = `${hotelName}, ${hotelAddress.city}`;

  const onMapOpen = () => {
    setMapOpen(true);
    onEmitEvent &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: 'Property Map',
          interactionLabel: 'openModal',
        },
      });
  };

  const onMapClose = () => {
    setMapOpen(false);
    onEmitEvent &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: 'Property Map',
          interactionLabel: 'closeModal',
        },
      });
  };

  const onAttractionsOpen = () => {
    setAttractionsOpen(true);
    onEmitEvent &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: 'Points of Interest',
          interactionLabel: 'openModal',
        },
      });
  };

  const onAttractionsClose = () => {
    setAttractionsOpen(false);
    onEmitEvent &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: 'Points of Interest',
          interactionLabel: 'closeModal',
        },
      });
  };

  const filteredPoi = poi.filter((attraction) => attraction && attraction.name);
  const filteredAirports = airports.filter((attraction) => attraction && attraction.name);

  const filteredAttractions = {
    poi: filteredPoi,
    airports: filteredAirports,
  };

  const initialPointsOfInterest = filteredPoi.slice(0, 4);
  const showNearbyAttractions = filteredPoi.length > 0;
  const showSeeMore = !(filteredPoi.length < 5 && airports.length === 0);
  const staticMapUrl = generateStaticMapUrl({ hotelCoordinates, mapKey });

  return (
    <StyledHotelMapContainer data-testid="hotelMapSectionContainer">
      <Typography variant="headingMd" data-testid="hotelMapSectionTitle">
        Explore the area
      </Typography>
      {hotelCoordinates.lat && hotelCoordinates.lng && (
        <>
          {staticMapUrl && (
            <StyledMapImageBox staticMapUrl={staticMapUrl} onClick={onMapOpen} data-testid="hotelMapSectionBox">
              <img src={staticMapUrl} alt="hotel map image" hidden />
            </StyledMapImageBox>
          )}
        </>
      )}
      <StyledHotelAddressContainer>
        <StyledHotelMapAddress variant="bodyMd" data-testid="hotelMapSectionAddress">
          {formatHotelAddress(hotelAddress)}
        </StyledHotelMapAddress>
        {staticMapUrl && (
          <StyledHotelMapLink onClick={onMapOpen} data-testid="hotelMapSectionLink">
            <Typography variant="bodyMd">View map</Typography>
          </StyledHotelMapLink>
        )}
      </StyledHotelAddressContainer>
      {showNearbyAttractions && (
        <>
          <StyledNearbyAttractionsBox data-testid="nearby-attractions">
            <StyledInitialNearbyAttractionsBox data-testid="nearby-attractions-initial">
              {initialPointsOfInterest.map((attraction) => (
                <Attraction attraction={attraction} icon={<LocationPin />} />
              ))}
            </StyledInitialNearbyAttractionsBox>
            {showSeeMore && (
              <StyledNearbyAttractionsLink data-testid="nearby-attractions-see-more-link" onClick={onAttractionsOpen}>
                See more
              </StyledNearbyAttractionsLink>
            )}
          </StyledNearbyAttractionsBox>
          <AttractionsModal
            open={openAttractions}
            isMobile={isMobile}
            attractions={filteredAttractions}
            onClose={onAttractionsClose}
          />
        </>
      )}
      <HotelMapModal
        modalTitle={modalTitle}
        mapKey={mapKey}
        hotelCoordinates={hotelCoordinates}
        isMobile={isMobile}
        open={openMap}
        onClose={onMapClose}
      />
    </StyledHotelMapContainer>
  );
};

export default MapSection;
