import { isBefore, isValid } from 'date-fns';
import { SelectionDate } from '@src/components/calendar-date-picker/types';

export const hasDatePassed = (date1: SelectionDate, date2: SelectionDate): boolean => {
  if (!date1 || !date1.day || !date2 || !date2.day) return false;

  const date1Format = new Date(date1.year, date1.month - 1, date1.day);
  const date2Format = new Date(date2.year, date2.month - 1, date2.day);

  if (!isValid(date1Format) || !isValid(date2Format)) return false;

  return isBefore(date1Format, date2Format);
};
