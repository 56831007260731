import { configureStore, combineReducers } from '@reduxjs/toolkit';
import configReducer from '@reducers/configReducer';
import exampleReducer, { ExampleReducerState } from '@reducers/exampleReducer';
import { ConfigType } from './config';

export type StoreState = { config: ConfigType; example: ExampleReducerState };

export default configureStore<StoreState>({
  reducer: combineReducers({ config: configReducer, example: exampleReducer }),
});
