import { Typography } from '@mui/material';
import React from 'react';
import Attraction, { AttractionProps } from '../attraction/main';
import { StyledMappedAttractionsBox, StyledMainBox } from './style';
export interface AttractionsProps {
  label: string;
  attractions: AttractionProps['attraction'][];
  icon: React.ReactNode;
  showDottedLines?: boolean;
}

const Attractions = ({ label, attractions, icon, showDottedLines }: AttractionsProps) => {
  return (
    <>
      {attractions.length > 0 && (
        <StyledMainBox data-testid="attractions-main-container">
          <Typography variant="subheadingMd">{label}</Typography>
          <StyledMappedAttractionsBox data-testid="attractions-mapped-children">
            {attractions.map((attraction) => (
              <Attraction
                icon={icon}
                attraction={attraction}
                showDottedLines={showDottedLines}
                key={`attraction-${attraction.name}`}
              />
            ))}
          </StyledMappedAttractionsBox>
        </StyledMainBox>
      )}
    </>
  );
};

export default Attractions;
