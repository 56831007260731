import MuiStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { styled } from '@fctg-ds/theme';
import { StepConnector, stepConnectorClasses, StepLabel } from '@mui/material';

export const StyledConnector = styled(StepConnector)<{ alternativeLabel?: boolean }>(({ theme, alternativeLabel }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.token.color.black[' '].value,
    },
  },
  [`&.${stepConnectorClasses.disabled}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      border: `1px dashed ${theme.token.color.neutral.text.weak.disabled.value}`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.token.color.black[' '].value,
    marginLeft: alternativeLabel ? `-${theme.spacing(2)}` : 0,
    marginRight: alternativeLabel ? `-${theme.spacing(2)}` : 0,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

export const StyledStepper = styled(MuiStepper)(({ theme }) => ({
  '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
    marginTop: theme.spacing(1),
  },
  whiteSpace: 'nowrap',
}));

export const StyledStep = styled(Step)(({ theme }) => ({
  '& .MuiStepIcon-root.Mui-active': {
    color: theme.token.color.black[' '].value,
  },
  '& .MuiStepIcon-root.Mui-completed, ': {
    color: theme.token.color.black[' '].value,
  },
  '& .MuiStepIcon-root.Mui-disabled, ': {
    color: theme.token.color.neutral.text.weak.disabled.value,
  },
}));

export const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepIcon-text': {
    fill: 'none',
  },
  ...theme.typography.bodySmStrong,
}));
