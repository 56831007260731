import { Box, Typography } from '@mui/material';
import { styled } from '@fctg-ds/theme';

export const StyledOptionBox = styled(Box, {
  shouldForwardProp: (props) => !['isChild'].includes(props as string),
})<{ isChild: boolean }>(({ theme, isChild }) => ({
  display: 'flex',
  flexDirection: 'row',
  whiteSpace: 'wrap',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  gap: theme.spacing(3),
  marginLeft: isChild ? theme.spacing(6) : 0,
}));

export const StyledInnerOption = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  gap: theme.spacing(4),
}));

export const StyledTypographyBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const StyledTypographySubHeading = styled(Typography)(({ theme }) => ({
  color: theme.token.color.neutral.text.weak[' '].value,
}));
