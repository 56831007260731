import React from 'react';
import { ControlPosition, Map, MapProps, useApiIsLoaded, useMapsLibrary } from '@vis.gl/react-google-maps';
import MapFilterMarkers from '@components/map-filter/components/markers';
import { ProductTileProps } from '@fctg/ui-stays-components';
import { MAP_ID } from '../../constants';
import { EmitEventProps } from '@src/common/types/types';

const DEFAULT_ZOOM = 17;

interface MapFilterMapProps {
  destination: google.maps.LatLngLiteral;
  productTileData: ProductTileProps[];
  onEmitEvent?: (event: EmitEventProps) => void;
}

const mapOptions: MapProps = {
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  clickableIcons: false,
  zoomControlOptions: {
    position: ControlPosition.TOP_RIGHT,
  },
  gestureHandling: 'greedy',
};

// this is a temporary fix https://github.com/visgl/react-google-maps/issues/171
const Loaded = ({ children }) => {
  const markers = useMapsLibrary('marker');
  const isLoaded = useApiIsLoaded();
  return isLoaded && markers && children;
};

const MapFilterMap = ({ destination, productTileData, onEmitEvent }: MapFilterMapProps) => (
  <Loaded>
    <Map defaultCenter={destination} defaultZoom={DEFAULT_ZOOM} mapId={MAP_ID} {...mapOptions}>
      <MapFilterMarkers productTileData={productTileData} onEmitEvent={onEmitEvent} />
    </Map>
  </Loaded>
);

export default MapFilterMap;
