import React, { useEffect, useState } from 'react';
import { styled } from '@fctg-ds/theme';
import { DatePicker } from '@fctg/ui-date-picker';
import { addMonths, parse } from 'date-fns';
import { Box } from '@mui/material';
import { CalendarDatePickerProps, SelectionDate } from './types';
import { toStringDate } from '@src/helpers/toStringDate/toStringDate';

const DatePickerContainer = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  [theme.breakpoints.up('lg')]: {
    margin: 'initial',
  },
}));

const CalendarDatePicker = ({
  initialCalendarDates,
  handleSelectedDate,
  setNewCalendarDates,
}: CalendarDatePickerProps) => {
  const today = new Date();
  const MAX_DATE = addMonths(today, 11);
  const [calendarStartDate, setCalendarStartDate] = useState<SelectionDate | null>(
    initialCalendarDates?.checkInDate || null,
  );
  const [calendarEndDate, setCalendarEndDate] = useState<SelectionDate | null>(
    initialCalendarDates?.checkOutDate || null,
  );

  const [selectedMonth, setSelectedMonth] = useState(
    calendarStartDate?.month >= 0 ? calendarStartDate.month - 1 : today.getMonth(),
  );
  const [yearVisible, setYearVisible] = useState(calendarStartDate?.year ?? today.getFullYear());

  useEffect(() => {
    const { checkInDate, checkOutDate } = setNewCalendarDates;

    const parsedCheckInDate = checkInDate ? parse(toStringDate(checkInDate), 'yyyy-LL-dd', new Date()) : null;
    const parsedCheckOutDate = checkOutDate ? parse(toStringDate(checkOutDate), 'yyyy-LL-dd', new Date()) : null;

    if (!parsedCheckInDate) setCalendarStartDate(null);
    if (!parsedCheckOutDate) setCalendarEndDate(null);

    setCalendarStartDate(checkInDate);
    if (parsedCheckOutDate?.valueOf() > parsedCheckInDate?.valueOf()) setCalendarEndDate(checkOutDate);
  }, [setNewCalendarDates]);

  const onClickPreviousMonth = () => {
    setSelectedMonth((next) => {
      if (next - 1 < 0) {
        setYearVisible((prev) => Math.min(prev - 1, calendarStartDate?.year || today.getFullYear()));

        return 11;
      }

      if (yearVisible === today.getFullYear()) return Math.max(next - 1, today.getMonth());

      return next - 1;
    });
  };

  const onClickNextMonth = () => {
    setSelectedMonth((next) => {
      if (next + 1 > 11) {
        setYearVisible((prev) => Math.min(prev + 1, MAX_DATE.getFullYear()));

        return 0;
      }

      if (yearVisible === MAX_DATE.getFullYear()) return Math.min(next + 1, MAX_DATE.getMonth() - 2);

      return next + 1;
    });
  };

  return (
    <DatePickerContainer data-testid="calendar-date-picker">
      <DatePicker
        startDate={calendarStartDate}
        endDate={calendarEndDate}
        mode={'range'}
        monthVisible={selectedMonth + 1}
        yearVisible={yearVisible}
        maxDate={{ day: MAX_DATE.getDate(), month: MAX_DATE.getMonth(), year: MAX_DATE.getFullYear() }}
        selectedDates={handleSelectedDate}
        navPrevFn={onClickPreviousMonth}
        navNextFn={onClickNextMonth}
      />
    </DatePickerContainer>
  );
};

export default CalendarDatePicker;
