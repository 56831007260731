import React from 'react';
import { TabType } from '../../type';
import { StyledChip, StyledChipBox } from './styles';

export interface MediaElement {
  url: string;
  caption: string;
}

export interface ChipTabsProps {
  tab: TabType;
  handleTabChange: (tab: TabType) => void;
}

const ChipTabs = ({ tab, handleTabChange }: ChipTabsProps) => {
  return (
    <StyledChipBox>
      <StyledChip
        data-testid="room-details-modal-amenities-chip-tab"
        active={tab === 'amenities'}
        onClick={() => handleTabChange('amenities')}
        heading="Room amenities"
        placeholder={undefined}
      />
      <StyledChip
        data-testid="room-details-modal-details-chip-tab"
        active={tab === 'details'}
        onClick={() => handleTabChange('details')}
        heading="Room details"
        placeholder={undefined}
      />
    </StyledChipBox>
  );
};

export default ChipTabs;
