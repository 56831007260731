import { Fab } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

export const StyledFab = styled(Fab)(({ theme }) => ({
  color: theme.token.color.neutral.background[' '].value,
  background: theme.token.color.primary[' '].value,
  borderRadius: theme.spacing(3),
  gap: theme.spacing(1),
  padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
}));

export const StyledFabBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 50,
  zIndex: 1100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  marginLeft: `-${theme.spacing(5)}`,
}));
