import React from 'react';
import PaxCount from '../pax-count/main';
import { Drawer, IconButton, PrimaryButton } from '@fctg-ds/core';
import CloseIcon from '@mui/icons-material/Close';
import { StyledFooter } from './styles';
import { ErrorState } from '../pax-count/types';
import { CabinClass } from '../cabin-class/main';
import { CabinClassType } from '@src/common/types/types';
import { Box } from '@mui/material';

interface TravellersSelectorMobileProps {
  open: boolean;
  onClose: () => void;
  onDone: () => void;
  adult: number;
  childAges: number[];
  infant: number;
  handleAdultChange: (newAdultCount: number) => void;
  handleChildAgesChange: (newChildrenCount: number) => void;
  handleInfantChange: (newInfantCount: number) => void;
  handleCabinClass: (value: CabinClassType) => void;
  isChildAgesValid: boolean;
  onSelectChildAge: (index: number, value: number) => void;
  error: ErrorState;
  cabinClass: CabinClassType;
}

const TravellersSelectorMobile = ({
  open,
  onClose,
  onDone,
  adult,
  childAges,
  infant,
  handleAdultChange,
  handleChildAgesChange,
  handleInfantChange,
  handleCabinClass,
  isChildAgesValid,
  onSelectChildAge,
  error,
  cabinClass,
}: TravellersSelectorMobileProps) => {
  return (
    <Drawer
      open={open}
      drawerHeaderTitle="Travellers"
      onClose={onClose}
      onOpen={() => true}
      leftActionBtn={
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
      noContentPadding
      data-testid="travellers-selector-mobile-drawer"
      footer={
        <StyledFooter>
          <PrimaryButton placeholder={undefined} fullWidth onClick={onDone} data-testid="travellers-done-button">
            Done
          </PrimaryButton>
        </StyledFooter>
      }
    >
      <Box>
        <PaxCount
          adult={adult}
          childAges={childAges}
          infant={infant}
          handleAdultChange={handleAdultChange}
          handleChildAgesChange={handleChildAgesChange}
          handleInfantChange={handleInfantChange}
          isChildAgesValid={isChildAgesValid}
          onSelectChildAge={onSelectChildAge}
          error={error}
        />
        {cabinClass && <CabinClass value={cabinClass} onChange={handleCabinClass} />}
      </Box>
    </Drawer>
  );
};

export default TravellersSelectorMobile;
