import { Box, Typography } from '@mui/material';
import { styled } from '@fctg-ds/theme';
import { Rating } from '@fctg-ds/core';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LocationPin from '@fctg-ds/icons/LocationPin';

export const StyledHeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const StyledSubContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const StyledRating = styled(Rating)(({ theme }) => ({
  mt: theme.spacing(0.25),
  color: theme.token.color.neutral.icon.weak[' '].value,
}));

export const StyledFiberManualRecordIcon = styled(FiberManualRecordIcon)(({ theme }) => ({
  color: theme.token.color.neutral.icon.weak[' '].value,
  fontSize: theme.spacing(0.5),
  marginTop: theme.spacing(2.5),
}));

export const StyledLocationPin = styled(LocationPin)(({ theme }) => ({
  color: theme.token.color.neutral.icon.weak[' '].value,
  fontSize: theme.spacing(6),
}));

export const StyledCityName = styled(Typography)(({ theme }) => ({
  color: theme.token.color.text.weak[' '].value,
}));
