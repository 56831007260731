import React from 'react';
import { Typography } from '@mui/material';
import { StyledReviewerAvatar, StyledReviewerBox } from './styles';

interface ReviewerProps {
  name: string;
  date: string;
}

const Reviewer = ({ name, date }: ReviewerProps) => {
  return (
    <StyledReviewerBox data-testid="guest-review-tile-reviewer">
      <StyledReviewerAvatar src="https://secure.fclmedia.com/assets/stays/assets/icons/reviewer-avatar.png" />
      <Typography variant="bodySmStrong">{name}</Typography>
      <Typography variant="captionSm" data-testid="guest-review-tile-date">
        {date}
      </Typography>
    </StyledReviewerBox>
  );
};

export default Reviewer;
