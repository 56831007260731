import { AutocompleteConfig } from './types';

export const MIN_WIDTH = 400;

export const FETCH_AUTOCOMPLETE_URL = (searchValue: string, filter: 'airports' | 'all') =>
  `https://www.flightcentre.com.au/omni-search-ui/api/v1/destinations?filter=${filter}&brand=AU_FlightCentre_Air&search=${searchValue}&cacheKey=3`;

export const AUTOCOMPLETE_CONFIG: AutocompleteConfig = {
  fcau: {
    placeholder: {
      origin: 'Where from?',
      destination: 'Where to?',
    },
    defaultFooterText: {
      origin: 'For more airports, use the search box.',
      destination: 'For more destinations, use the search box.',
    },
    popularLocations: {
      origin: [
        {
          displayValue: 'Popular',
          value: '',
          iataCode: '',
          isChild: false,
          type: 'airport',
          slug: '',
          keyword: '',
          isHeading: true,
          countryCode: '',
        },
        {
          displayValue: 'Sydney, Australia',
          value: 'Sydney',
          iataCode: 'SYD',
          isChild: false,
          type: 'airport',
          slug: 'au-nsw-sydney',
          keyword: '',
          countryCode: 'AU',
        },
        {
          displayValue: 'Melbourne, Australia',
          value: 'Melbourne',
          iataCode: 'MEL',
          isChild: false,
          type: 'airport',
          slug: 'au-vic-melbourne',
          keyword: '',
          countryCode: 'AU',
        },
        {
          displayValue: 'Brisbane, Australia',
          value: 'Brisbane',
          iataCode: 'BNE',
          isChild: false,
          type: 'airport',
          slug: 'au-qld-brisbane',
          keyword: '',
          countryCode: 'AU',
        },
        {
          displayValue: 'Perth, Australia',
          value: 'Perth',
          iataCode: 'PER',
          isChild: false,
          type: 'airport',
          slug: 'au-wa-perth',
          keyword: '',
          countryCode: 'AU',
        },
        {
          displayValue: 'Adelaide, Australia',
          value: 'Adelaide',
          iataCode: 'ADL',
          isChild: false,
          type: 'airport',
          slug: 'au-sa-adelaide',
          keyword: '',
          countryCode: 'AU',
        },
        {
          displayValue: 'Gold Coast, Australia',
          value: 'Gold Coast',
          iataCode: 'OOL',
          isChild: false,
          type: 'airport',
          slug: 'au-qld-gold-coast',
          keyword: '',
          countryCode: 'AU',
        },
        {
          displayValue: 'Cairns, Australia',
          value: 'Cairns',
          iataCode: 'CNS',
          isChild: false,
          type: 'airport',
          slug: 'au-qld-cairns',
          keyword: '',
          countryCode: 'AU',
        },
        {
          displayValue: 'Hobart, Australia',
          value: 'Hobart',
          iataCode: 'HBA',
          isChild: false,
          type: 'airport',
          slug: 'au-tas-hobart',
          keyword: '',
          countryCode: 'AU',
        },
        {
          displayValue: 'Canberra, Australia',
          value: 'Canberra',
          iataCode: 'CBR',
          isChild: false,
          type: 'airport',
          slug: 'au-act-canberra',
          keyword: '',
          countryCode: 'AU',
        },
      ],
      destination: [
        {
          displayValue: 'Popular',
          value: '',
          iataCode: '',
          isChild: false,
          type: 'city',
          slug: '',
          keyword: '',
          isHeading: true,
          countryCode: '',
        },
        {
          displayValue: 'Gold Coast',
          value: 'Queensland, Australia',
          iataCode: 'OOL',
          slug: 'au-qld-gold-coast',
          type: 'city',
          isChild: false,
          keyword: '',
          countryCode: 'AU',
        },
        {
          displayValue: 'Sydney',
          value: 'New South Wales, Australia',
          iataCode: 'SYD',
          slug: 'au-nsw-sydney',
          type: 'city',
          isChild: false,
          keyword: '',
          countryCode: 'AU',
        },
        {
          displayValue: 'Melbourne',
          value: 'Victoria, Australia',
          iataCode: 'MEL',
          slug: 'au-vic-melbourne',
          type: 'city',
          isChild: false,
          keyword: '',
          countryCode: 'AU',
        },
        {
          displayValue: 'Brisbane',
          value: 'Queensland, Australia',
          iataCode: 'BNE',
          slug: 'au-qld-brisbane',
          type: 'city',
          isChild: false,
          keyword: '',
          countryCode: 'AU',
        },
        {
          displayValue: 'Bali',
          value: 'Bali, Indonesia',
          iataCode: 'DPS',
          slug: 'id-ba-bali',
          type: 'city',
          isChild: false,
          keyword: '',
          countryCode: 'ID',
        },
        {
          displayValue: 'Phuket',
          value: 'Phuket Province, Thailand',
          iataCode: 'HKT',
          slug: 'th-phu-phuket',
          type: 'city',
          isChild: false,
          keyword: '',
          countryCode: 'TH',
        },
        {
          displayValue: 'Tokyo',
          value: 'Tokyo, Japan',
          iataCode: 'TYO',
          slug: 'jp-tok-tokyo',
          type: 'city',
          isChild: false,
          keyword: '',
          countryCode: 'JP',
        },
        {
          displayValue: 'Singapore',
          value: 'Singapore',
          iataCode: 'SIN',
          slug: 'sg-singapore',
          type: 'city',
          isChild: false,
          keyword: '',
          countryCode: 'SG',
        },
        {
          displayValue: 'London',
          value: 'England, United Kingdom',
          iataCode: 'LON',
          slug: 'gb-eng-london',
          type: 'city',
          isChild: false,
          keyword: '',
          countryCode: 'GB',
        },
        {
          displayValue: 'Fiji',
          value: 'Fiji',
          iataCode: 'NAN',
          slug: 'fj-fiji',
          type: 'city',
          isChild: false,
          keyword: '',
          countryCode: 'FJ',
        },
      ],
    },
  },
};
