import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { TopHotelAmenitiesProps } from '../../types';
import { StyledParentBox, StyledTopAmenitiesBox } from './styles';
import { AllAmenities } from '../all-amenities/main';
import TopHotelAmenitiesGrid from '../top-hotel-amenities-grid/main';

const AllHotelAmenitiesContent = ({ amenities, isMobile }: TopHotelAmenitiesProps) => {
  const { all, top } = amenities;
  const theme = useTheme();

  return (
    <StyledParentBox padding={isMobile ? '0' : `0 ${theme.spacing(6)} ${theme.spacing(6)}`}>
      {top && top.length > 0 && (
        <StyledTopAmenitiesBox
          data-testid="topHotelAmenitiesModalDialog"
          gap={theme.spacing(8)}
          padding={theme.spacing(8)}
        >
          <Typography variant="headingMd" data-testid="topHotelAmenitiesModalDialogHeading">
            Top amenities
          </Typography>
          <TopHotelAmenitiesGrid isMobile={isMobile} top={top} isModalDialogView={true} />
        </StyledTopAmenitiesBox>
      )}
      {all && all.length > 0 && <AllAmenities all={all} />}
    </StyledParentBox>
  );
};

export default AllHotelAmenitiesContent;
