import React from 'react';
import { Typography } from '@mui/material';
import { StyledParentBox, StyledTopAmenitiesBox } from './styles';
import { AllAmenities } from '@src/components/top-hotel-amenities/components/all-amenities/main';
import { Amenities } from '@src/components/top-hotel-amenities/types';
import { TopRoomDetailsAmenitiesGrid } from './components';
import { StyledEmptyBox, StyledEmptyTypography } from '../../styles';

interface AllRoomDetailsAmenitiesContentProps {
  amenities?: Amenities;
}

const AllRoomDetailsAmenitiesContent = ({ amenities }: AllRoomDetailsAmenitiesContentProps) => {
  const { top, all } = amenities || {};

  return (
    <>
      {!amenities || (top.length <= 0 && all.length <= 0) ? (
        <StyledEmptyBox data-testid="room-details-modal-no-amenities">
          <StyledEmptyTypography>
            The detailed list of room amenities cannot be displayed at this time
          </StyledEmptyTypography>
        </StyledEmptyBox>
      ) : (
        <StyledParentBox data-testid="room-modal-details-amenities">
          {top.length >= 2 && (
            <StyledTopAmenitiesBox>
              <Typography variant="headingMd">Top room amenities</Typography>
              <TopRoomDetailsAmenitiesGrid top={top} />
            </StyledTopAmenitiesBox>
          )}
          <AllAmenities all={amenities.all} />
        </StyledParentBox>
      )}
    </>
  );
};

export default AllRoomDetailsAmenitiesContent;
