import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Room } from '../room-tile';
import ChipTabs from './components/chip-tabs';
import { TabType } from './type';
import Gallery from '../room-gallery';
import { StyledModalBox } from './styles';
import AllRoomDetailsAmenitiesContent from './components/all-amenities/main';
import { Modal } from '@fctg-ds/core';
import DetailsContent from './components/details/main';

export interface RoomDetailsModalProps {
  open: boolean;
  title: Room['name'];
  images: Room['images'];
  description?: Room['description'];
  amenities?: Room['amenities'];
  isMobile?: boolean;
  handleClose: () => void;
}

export const RoomDetailsModal = ({
  isMobile,
  title,
  images,
  description,
  amenities,
  open,
  handleClose,
}: RoomDetailsModalProps) => {
  const [tab, setTab] = useState<TabType>('amenities');

  const handleTabChange = (tab: TabType) => {
    setTab(tab);
  };

  const onClose = () => {
    handleClose();
    setTab('amenities');
  };

  return (
    <Modal
      maxHeight="100%"
      maxWidth={!isMobile ? 'lg' : false}
      fullScreen={isMobile}
      title={title}
      open={open}
      onClose={onClose}
      contentStyles={{ overflowY: 'scroll' }}
      noContentPadding
    >
      <StyledModalBox>
        {images.length > 0 && <Gallery isRoomDetails isMobile={isMobile} images={images} />}
        <ChipTabs tab={tab} handleTabChange={handleTabChange} />
        <Box data-testid="room-details-modal-content">
          {tab === 'amenities' ? (
            <AllRoomDetailsAmenitiesContent amenities={amenities} />
          ) : (
            <DetailsContent description={description} />
          )}
        </Box>
      </StyledModalBox>
    </Modal>
  );
};
