import React from 'react';
import { StyledDottedLine, StyledEndBox, StyledLocationBox, StyledTypography } from './styles';
import { Typography } from '@mui/material';
import { AttractionType } from './type';

export interface AttractionProps {
  attraction: AttractionType;
  icon: React.ReactNode;
  showDottedLines?: boolean;
}

const Attraction = ({ attraction, icon, showDottedLines }: AttractionProps) => {
  const { name, distanceInMetres } = attraction;

  return (
    <StyledLocationBox data-testid={`attraction-${name}`}>
      {icon}
      <Typography variant="bodyMd">{name}</Typography>
      <StyledEndBox>
        <StyledDottedLine showDottedLines={showDottedLines} />
        <StyledTypography variant="bodyMd">
          {distanceInMetres < 1000 ? `${distanceInMetres} m` : `${(distanceInMetres / 1000).toFixed(1)} km`}
        </StyledTypography>
      </StyledEndBox>
    </StyledLocationBox>
  );
};

export default Attraction;
