import React from 'react';
import { StyledAgeSelectField, StyledAgesSelectorContainer, StyledTypography } from './styles';
import { getAgeItems } from './utilts';

const MIN_AGE = 2;
const MAX_AGE = 17;

export interface ChildAgeSelectorProps {
  childAges: number[];
  onChange: (index: number, value: number) => void;
  showError: boolean;
}

export const ChildAgeSelector = ({ childAges, onChange, showError }: ChildAgeSelectorProps) => {
  return (
    <>
      <StyledAgesSelectorContainer>
        {childAges.map((age, index) => (
          <StyledAgeSelectField
            key={[index, age].join(':')}
            displayEmpty
            label={`Child ${index + 1} age`}
            value={age}
            onChange={(event) => onChange(index, parseInt(event.target.value as string))}
            data-testid="child-age"
          >
            {getAgeItems({ minAge: MIN_AGE, maxAge: MAX_AGE })}
          </StyledAgeSelectField>
        ))}
      </StyledAgesSelectorContainer>
      {showError && <StyledTypography variant="bodySm">Please provide all child ages</StyledTypography>}
    </>
  );
};
