import React from 'react';
import Gallery from '../room-gallery/main';
import Header from './components/header/main';
import {
  StyledBottomBox,
  StyledEmptyBox,
  StyledRoomEmptyImage,
  StyledRoomImageBox,
  StyledRoomImageSection,
  StyledRoomTile,
  StyledRoomTileFlexibleBox,
  StyledTopBox,
} from './styles';
import { RoomTileProps } from './types';
import RoomOption from '../room-option/main';
import RoomOptionsButton from './components/room-options-button';
import TopRoomAmenities from './components/top-room-amenities/main';

export const RoomTile = ({
  id,
  name,
  leadRoomOption,
  bedGroups,
  squareMeters,
  roomOptions,
  images,
  isMobile,
  occupancy,
  cheapest,
  nights,
  travellers,
  amenities,
  variant = 'stays',
  handleShowRoomDetails,
  handleSelect,
  handleRooms,
  onEmitEvent,
}: RoomTileProps) => {
  return (
    <StyledRoomTile data-testid={`room-tile-${id}`}>
      <StyledTopBox data-testid="room-tile-top-section">
        <StyledRoomImageSection>
          {images.length === 0 && (
            <StyledRoomImageBox>
              <StyledRoomEmptyImage
                image="https://secure.fclmedia.com/assets/stays/assets/images/room-empty.png"
                data-testid="room-tile-empty-image"
              />
            </StyledRoomImageBox>
          )}
          {images.length > 0 && <Gallery images={images} isMobile={isMobile} />}
        </StyledRoomImageSection>
        <StyledRoomTileFlexibleBox data-testid="room-tile-top-section-inner">
          <Header bedGroups={bedGroups} squareMeters={squareMeters} name={name} occupancy={occupancy} />
          <TopRoomAmenities amenities={amenities} handleShowRoomDetails={handleShowRoomDetails} roomId={id} />
        </StyledRoomTileFlexibleBox>
      </StyledTopBox>
      <StyledBottomBox>
        <RoomOption
          roomOption={leadRoomOption}
          cheapest={cheapest}
          handleSelect={handleSelect}
          onEmitEvent={onEmitEvent}
          roomId={id}
          footer={
            <>
              {id &&
                (roomOptions.length > 1 ? (
                  <RoomOptionsButton roomId={id} handleRooms={handleRooms} otherRoomsLength={roomOptions.length - 1} />
                ) : (
                  <StyledEmptyBox data-testid="room-options-empty-box" />
                ))}
            </>
          }
          roomOptionVariant={'roomTile'}
          nights={nights}
          travellers={travellers}
          variant={variant}
        />
      </StyledBottomBox>
    </StyledRoomTile>
  );
};
