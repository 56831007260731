import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MapHotelPin = (props: SvgIconProps) => {
  return (
    <SvgIcon data-testid="map-hotel-icon" viewBox="0 0 32 40" {...props}>
      <svg width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2222_282350)">
          <path
            d="M31.7562 15.8781C31.7562 28.8306 15.8781 40 15.8781 40C15.8781 40 0 28.8306 0 15.8781C0 7.10866 7.10866 0 15.8781 0C24.6475 0 31.7562 7.10866 31.7562 15.8781Z"
            fill="#231F20"
          />
          <path
            d="M11.9086 16.672C13.2264 16.672 14.2903 15.6082 14.2903 14.2903C14.2903 12.9724 13.2264 11.9086 11.9086 11.9086C10.5907 11.9086 9.52685 12.9724 9.52685 14.2903C9.52685 15.6082 10.5907 16.672 11.9086 16.672ZM21.4354 11.9086H15.0842V17.4659H8.73295V10.3208H7.14514V22.2294H8.73295V19.8477H23.0232V22.2294H24.611V15.0842C24.611 13.3297 23.1899 11.9086 21.4354 11.9086Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_2222_282350">
            <rect width="31.7562" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
      ;
    </SvgIcon>
  );
};

export default MapHotelPin;
