import { styled } from '@fctg-ds/theme';
import { Box, Grid } from '@mui/material';

export const StyledModal = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: `${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(6)}`,
}));

export const StyledModalInner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(8),
}));

export const StyledModalTopBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
}));

export const StyledModalBottomBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'column',
  },
}));

export const StyledModalCategoryScoresBox = styled(Grid)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(6),
  justifyContent: 'space-between',
  alignContent: 'flex-start',
  alignItems: 'flex-end',
}));

export const StyledModalUserReviewsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  width: '100%',
}));
