import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { StyledSortDisclaimerMainBox, StyledDialog, StyledInfoIcon } from './styles';

const SortDisclaimer = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <StyledSortDisclaimerMainBox data-testid="sortDisclaimer">
        <Typography variant="captionMdStrong" data-testid="sortDisclaimerMainText">
          The sort order is influenced by multiple factors
        </Typography>
        <StyledInfoIcon data-testid="sortDisclaimerInfoIcon" onClick={handleOpen} />
      </StyledSortDisclaimerMainBox>
      <StyledDialog
        open={open}
        onClose={handleClose}
        title={'Sort order'}
        data-testid="sortDisclaimerDialog"
        id="sortDisclaimerDialog"
        options={[
          {
            text: 'Ok',
            action: handleClose,
            primaryAction: true,
          },
        ]}
      >
        <Typography variant="captionMdStrong" data-testid="sortDisclaimerDialogText">
          Our search tool is powered through a third-party aggregation partner. As you search, you can find
          accommodation by adjusting sorting and filtering options to match your preferences. If you don’t specify a
          sorting preference, we’ll display relevant options based on factors such as: location, review scores,
          popularity (measured by how many travellers book to stay at that property), content quality, availability, and
          rates competitiveness. The compensation or commission that our third-party partner may receive from bookings,
          may also influence the default recommended sorting order in the search results, such that products that
          generate higher compensation or commission feature more prominently. For any other sorting option (such as
          price or property class), we will display properties based only on the selected criteria.
        </Typography>
      </StyledDialog>
    </>
  );
};

export default SortDisclaimer;
