import { MenuItem } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { MenuList } from '@mui/material';

export const SortOptionSelectableStyled = styled(MenuItem)<{ isMobile: boolean }>(({ isMobile, theme }) => ({
  ...(isMobile && {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  }),
  borderRadius: theme.spacing(2),
}));

export const MobileMenuListStyled = styled(MenuList)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));
