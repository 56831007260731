import { IconButton } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';

export const StyledIncrementor = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: '120px',
}));

export const StyledCount = styled(Typography)(({ theme }) => ({
  padding: '0 1rem',
  fontSize: theme.token.typography.size.md.value,
  fontWeight: theme.token.typography.weight.regular.value,
  color: theme.token.color.neutral.text[' '].value,
}));

export const StyledDecrementButton = styled(IconButton)(() => ({
  display: 'flex',
  '&[aria-disabled=true]': {
    cursor: 'not-allowed',
  },
}));

export const StyledIncrementButton = styled(IconButton)(() => ({
  display: 'flex',
  '&[aria-disabled=true]': {
    cursor: 'not-allowed',
  },
}));
