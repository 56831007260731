import React from 'react';
import { Dialog } from '@fctg-ds/core';

export type ErrorStatus = 'origin' | 'destination' | 'dates' | 'sameOrigin' | 'internationalOrigin';

interface SessionTimeoutDialogProps {
  errorStatus: ErrorStatus | null;
  onClose: () => void;
}

const DIALOG_PROPS = {
  origin: {
    title: 'Please select your departure airport',
  },
  destination: {
    title: 'Please select your destination',
  },
  dates: {
    title: 'Please select your travel dates',
  },
  sameOrigin: {
    title: 'Please check your search as the departure and destination points must be in different locations',
  },
  internationalOrigin: {
    title: "We're sorry! Only Australia based airports are currently supported",
  },
};

const ErrorDialog = ({ errorStatus, onClose }: SessionTimeoutDialogProps) => {
  return (
    <Dialog
      open={errorStatus !== null}
      title={DIALOG_PROPS[errorStatus]?.title}
      onClose={onClose}
      options={[
        {
          text: 'Ok',
          action: onClose,
          primaryAction: true,
        },
      ]}
      data-testId="search-form-error-dialog"
    />
  );
};

export default ErrorDialog;
