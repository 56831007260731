import React from 'react';
import { Box } from '@mui/material';
import { ContainerType } from '../../types';
import SectionHeader from '../section-header/main';
import SectionContent from '../section-content/main';
import { styled } from '@fctg-ds/theme';
import { capitaliseWord } from '@src/helpers/capitaliseWord/capitaliseWord';

interface SectionContainerProps {
  data?: string;
  type: ContainerType;
}

const StyledSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  width: '100%',
  border: `1px ${theme.token.color.neutral.border.disabled.value} solid`,
  borderRadius: theme.spacing(3),
  padding: theme.spacing(6),
}));

const StyledSectioContainerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
}));

const HEADER_TITLES = [
  {
    type: 'fees',
    value: 'Fees',
  },
  {
    type: 'optional',
    value: 'Optional extras',
  },
  {
    type: 'know',
    value: 'Know before you go',
  },
];
const SectionContainer = ({ data, type }: SectionContainerProps) => {
  if (!data) return null;

  const capitalizedType = capitaliseWord(type);

  return (
    <SectionHeader type={type} title={HEADER_TITLES.find((title) => title.type === type)?.value || 'Additional info'}>
      <StyledSectionContainer data-testid={`policies${capitalizedType}Inner`}>
        <StyledSectioContainerBox data-testid={`policies${capitalizedType}InnerListBox`}>
          <SectionContent content={data} />
        </StyledSectioContainerBox>
      </StyledSectionContainer>
    </SectionHeader>
  );
};

export default SectionContainer;
