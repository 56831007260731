import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';
import { PrimaryButton, SecondaryButton } from '@fctg-ds/core';

export const DatePickerContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: theme.spacing(4),
}));

export const DoneButton = styled(PrimaryButton)<{ active: boolean }>(({ active, theme }) => ({
  width: 'fit',
  backgroundColor: active
    ? theme.token.color.primary[' '].value
    : theme.token.color.primary.background.medium.disabled.value,
  color: active ? theme.token.color.primary.background[' '].value : theme.token.color.icon.weak.disabled.value,
  '&:hover, &:active': {
    cursor: active ? 'pointer' : 'revert',
    backgroundColor: active
      ? theme.token.color.primary.background.strong.hovered.value
      : theme.token.color.primary.background.medium.disabled.value,
  },
}));

export const ClearButton = styled(SecondaryButton)(({ theme }) => ({
  width: 'fit',
  color: theme.token.color.neutral.text[' '].value,
  border: 'none',
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'underline',
    border: 'none',
  },
}));
