import React, { useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import pluralize from 'pluralize';
import { UserRatingProps } from './types';
import {
  StyledUserRatingContainer,
  StyledUserRatingInner,
  StyledUserRating,
  StyledUserRatingBox,
  StyledReviewsCountText,
  StyledInfoIcon,
  StyledPopoverContainer,
  StyledPopover,
  StyledSeeReviewsLink,
} from './styles';
import { getSuperlative } from '@src/helpers/getSuperlative/getSuperlative';

const TOOL_TIP_MESSAGE =
  'Displayed reviews originate from genuine guest experiences. Only travellers who have booked accommodation through one of our partner suppliers, can submit a review for their stay. All reviews are verified and managed according to strict guidelines, and are made publicly available regardless of their sentiment. The overall score is based on a the total number of reviews, however, only 100 recent reviews are accessible for viewing, at any given moment.';

const UserRating = ({
  count,
  overall,
  superlative,
  size = 'lg',
  showSeeMoreReviewsLink,
  handleShowGuestReviews,
  onEmitEvent,
}: UserRatingProps) => {
  const iconRef = useRef();
  const [open, setOpen] = useState(false);
  const onPopover = () => setOpen((e) => !e);

  const isGreen = ['exceptional', 'superb', 'excellent', 'very_good'].includes(superlative);

  const onShowGuestReviews = () => {
    handleShowGuestReviews();
    onEmitEvent &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: 'Guest Reviews',
          interactionLabel: 'openModal',
        },
      });
  };

  return (
    <>
      {count > 0.0 && overall > 0.0 && (
        <StyledUserRatingContainer data-testid="userRating">
          <StyledUserRatingInner>
            <StyledUserRatingBox isGreen={isGreen} size={size}>
              <StyledUserRating variant="priceXl" data-testid="userRatingOverall">
                {overall === 10.0 ? 10 : overall.toFixed(1)}
              </StyledUserRating>
            </StyledUserRatingBox>
            <Box>
              <Typography variant={size === 'lg' ? 'priceXl' : 'priceLg'} data-testid="userRatingType">
                {getSuperlative(superlative)}
              </Typography>
              <Box display="flex" gap={1}>
                <StyledReviewsCountText variant={size === 'lg' ? 'bodyMd' : 'bodySm'} data-testid="userRatingCount">
                  {pluralize('verified review', count, true)}
                </StyledReviewsCountText>
                <StyledInfoIcon data-testid="userRating-info-icon" ref={iconRef} onClick={onPopover} size={size} />
              </Box>
            </Box>
          </StyledUserRatingInner>
          {handleShowGuestReviews && showSeeMoreReviewsLink && (
            <StyledSeeReviewsLink onClick={onShowGuestReviews} data-testid="seeReviewsLink" variant="bodyMdStrong">
              See reviews
            </StyledSeeReviewsLink>
          )}
          <StyledPopover
            open={open}
            anchorEl={iconRef?.current}
            onClose={onPopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            data-testid="userRating-info-popover"
          >
            <StyledPopoverContainer>
              <Typography variant="bodySm">{TOOL_TIP_MESSAGE}</Typography>
            </StyledPopoverContainer>
          </StyledPopover>
        </StyledUserRatingContainer>
      )}
    </>
  );
};

export default UserRating;
