import React from 'react';
import { Typography } from '@mui/material';
import { formattedCurrency } from '@src/helpers/formattedCurrency';
import {
  StyledOriginalPriceTypography,
  StyledPackageDetailsPrice,
  StyledPriceComment,
  StyledPriceInfoBox,
  StyledPriceSavingsBox,
  StyledTaxesFeesText,
  StyledTotalPriceBox,
} from './styles';

export interface PackageDetailsPricingProps {
  total: number;
  currency: string;
  originalPrice?: number;
  amountSaved?: number;
}

const PackageDetailsPricing = ({ total, originalPrice, currency, amountSaved }: PackageDetailsPricingProps) => {
  return (
    <StyledPackageDetailsPrice data-testid="package-details-price">
      <StyledPriceInfoBox>
        <StyledTotalPriceBox>
          <Typography variant="priceXl">{formattedCurrency(total, currency, true)}</Typography>
          <Typography variant="captionMd">/ total</Typography>
        </StyledTotalPriceBox>
        {originalPrice && amountSaved && (
          <StyledPriceSavingsBox data-testid="package-details-price-savings">
            <StyledOriginalPriceTypography variant="captionMdStrong">
              {formattedCurrency(originalPrice, 'AUD', true)}
            </StyledOriginalPriceTypography>
            <StyledPriceComment>{formattedCurrency(amountSaved, 'AUD', false)} saved</StyledPriceComment>
          </StyledPriceSavingsBox>
        )}
      </StyledPriceInfoBox>
      <StyledTaxesFeesText variant="captionMd">Includes taxes & fees</StyledTaxesFeesText>
    </StyledPackageDetailsPrice>
  );
};

export default PackageDetailsPricing;
