import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Icon } from '@mui/material';
import { styled } from '@fctg-ds/theme';
import { NUM_OF_AMENITIES_DESKTOP, NUM_OF_AMENITIES_MOBILE, NUM_OF_AMENITIES_MODAL_DIALOG } from '../../constants';
import { TopAmenity } from '../../types';
import { getAmenityIcon } from '@src/helpers/getAmenityIcon/getAmenityIcon';

interface TopHotelAmenitiesGridProps {
  top: TopAmenity[];
  isMobile: boolean;
  isModalDialogView: boolean;
}

const StyledTopAmenityBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'flex-start',
}));

const TopHotelAmenitiesGrid = ({ top, isMobile, isModalDialogView }: TopHotelAmenitiesGridProps) => {
  const theme = useTheme();
  let itemsToShow = NUM_OF_AMENITIES_MOBILE;
  if (isModalDialogView) {
    itemsToShow = NUM_OF_AMENITIES_MODAL_DIALOG;
  } else if (!isMobile) {
    itemsToShow = NUM_OF_AMENITIES_DESKTOP;
  }

  return (
    <>
      {top && top.length > 0 && (
        <>
          <Grid
            container
            spacing={4}
            data-testid={`${isModalDialogView ? 'topHotelAmenitiesModalDialogGrid' : 'topHotelAmenitiesGrid'}`}
          >
            {top.slice(0, itemsToShow).map((topAmenity) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={isModalDialogView ? 3 : 6}
                data-testid={`${isModalDialogView ? 'topHotelAmenitiesModalDialogGridItem' : 'topHotelAmenitiesGridItem'}`}
              >
                <StyledTopAmenityBox>
                  <Icon
                    component={getAmenityIcon(topAmenity)}
                    sx={{ fontSize: theme.spacing(5) }}
                    data-testid={`${isModalDialogView ? 'topHotelAmenitiesModalDialogGridItemIcon' : 'topHotelAmenitiesGridItemIcon'}`}
                  />
                  <Typography
                    variant="bodySmStrong"
                    data-testid={`${isModalDialogView ? 'topHotelAmenitiesModalDialogGridItemLabel' : 'topHotelAmenitiesGridItemLabel'}`}
                  >
                    {topAmenity.name}
                  </Typography>
                </StyledTopAmenityBox>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default TopHotelAmenitiesGrid;
