import React, { useRef, useState } from 'react';
import { StyledDatePickerIcon, StyledDatesBox } from './styles';
import { SearchFormProps, Selection, InputType } from '../../types';
import { StaysDates } from '@src/common/types/types';
import { StyledTextfield } from '../../styles';
import { format } from 'date-fns';
import EditSearchDatePicker from '@src/components/edit-search-date-picker/main';
import DatePickerMobile from '@src/components/date-picker-mobile/main';
import { hasDateChanged } from '@src/helpers/hasDateChanged/hasDateChanged';

const DATE_FORMAT = 'EEE, d MMM';

interface DateSelectionProps {
  component: InputType;
  dates: SearchFormProps['dates'];
  isMobileOrTablet: boolean;
  onDone: SearchFormProps['onDatepickerDone'];
  onOpen: (value: InputType) => void;
  onClose: () => void;
  onEmitEvent?: SearchFormProps['onEmitEvent'];
}

export const DateSelection = ({
  component,
  dates,
  isMobileOrTablet,
  onDone,
  onOpen,
  onClose,
  onEmitEvent,
}: DateSelectionProps) => {
  const fieldRef = useRef<HTMLInputElement>();
  const [dateSelection, setDateSelection] = useState<StaysDates | null>(dates || null);
  const [selection, setSelection] = useState<Selection>(null);

  const onDateFieldClick = async (type: 'checkIn' | 'checkOut') => {
    setSelection(type === 'checkIn' ? 'start' : 'end');
    onOpen(type);
  };

  const setNewCheckInOutDates = (newDates: StaysDates) => {
    if (
      hasDateChanged(dateSelection.checkInDate, newDates.checkInDate) ||
      hasDateChanged(dateSelection.checkOutDate, newDates.checkOutDate)
    ) {
      setDateSelection(newDates);
      onDone(newDates);
      onEmitEvent &&
        onEmitEvent({
          event: 'trackInteraction',
          data: {
            interactionValue: 'Edit Dates',
            interactionLabel: 'newAvailabilitySearch',
          },
        });
    }
  };

  const onDateSelectionOpen = (type: 'checkIn' | 'checkOut') => {
    onDateFieldClick(type);
    onEmitEvent &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: 'Edit Dates',
          interactionLabel: 'openModal',
        },
      });
  };

  const onDateSelectionClose = () => {
    onClose();
    setSelection(null);
    onEmitEvent &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: 'Edit Dates',
          interactionLabel: 'closeModal',
        },
      });
  };

  return (
    <StyledDatesBox data-testid="check-in-out-date-selection">
      <StyledTextfield
        id="check-in-date-selection"
        startAdornment={<StyledDatePickerIcon />}
        label="Check in"
        value={dateSelection?.checkInDate ? format(dateSelection.checkInDate, DATE_FORMAT) : 'Start date'}
        fullWidth
        ref={fieldRef}
        onClick={() => onDateSelectionOpen('checkIn')}
        data-testId="check-in-date-selection"
        inputProps={{ inputMode: 'none' }}
      />
      <StyledTextfield
        id="check-out-date-selection"
        startAdornment={<StyledDatePickerIcon />}
        label="Check out"
        value={dateSelection?.checkOutDate ? format(dateSelection.checkOutDate, DATE_FORMAT) : 'End date'}
        fullWidth
        onClick={() => onDateSelectionOpen('checkOut')}
        data-testId="check-out-date-selection"
        inputProps={{ inputMode: 'none' }}
      />
      {!isMobileOrTablet ? (
        <EditSearchDatePicker
          open={component === 'checkIn' || component === 'checkOut'}
          dates={dateSelection}
          handleClose={onDateSelectionClose}
          setNewCheckInOutDates={setNewCheckInOutDates}
          inputSelection={selection}
          inputRef={fieldRef?.current}
        />
      ) : (
        <DatePickerMobile
          open={component === 'checkIn' || component === 'checkOut'}
          dates={dateSelection}
          handleClose={onDateSelectionClose}
          setNewCheckInOutDates={setNewCheckInOutDates}
        />
      )}
    </StyledDatesBox>
  );
};
