import { Box, Typography } from '@mui/material';
import { styled } from '@fctg-ds/theme';
import Stay from '@fctg-ds/icons/Stay';
import Remove from '@fctg-ds/icons/Remove';

export const StyledCalendarFooterNights = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

export const StyledStayIcon = styled(Stay)(({ theme }) => ({
  fontSize: theme.spacing(4),
  color: theme.token.color.neutral.icon.weak[' '].value,
  marginTop: theme.spacing(0.5),
}));

export const StyledCalendarFooterNightsText = styled(Typography)(({ theme }) => ({
  color: theme.token.color.neutral.text.weak.disabled.value,
}));

export const StyledDashIcon = styled(Remove)(({ theme }) => ({
  fontSize: theme.spacing(2.5),
}));
