import { Box, Link, Typography } from '@mui/material';
import { styled } from '@fctg-ds/theme';

export const StyledTopAmenitiesBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  padding: theme.spacing(8),
  backgroundColor: theme.token.color.neutral.background.medium[' '].value,
  width: '100%',
  borderRadius: theme.spacing(4),
  height: '100%',
  justifyContent: 'space-between',
}));

export const StyledTopAmenitiesLink = styled(Link)(({ theme }) => ({
  color: theme.token.color.neutral.text[' '].value,
  textDecoration: 'none',
  width: 'fit-content',
}));

export const StyledTopAmenitiesLinkText = styled(Typography)(() => ({
  textDecoration: 'underline',
  cursor: 'pointer',
}));

export const StyledTopContainer = styled(Typography)(({ theme }) => ({
  gap: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
}));
