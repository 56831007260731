import { Superlative } from '@src/components/guest-reviews/types';

export const getSuperlative = (superlative: Superlative): string => {
  switch (superlative) {
    case 'exceptional':
      return 'Exceptional';
    case 'superb':
      return 'Superb';
    case 'excellent':
      return 'Excellent';
    case 'very_good':
      return 'Very Good';
    case 'good':
      return 'Good';
    case 'substandard':
      return 'Substandard';
    case 'poor':
      return 'Poor';
    case 'bad':
      return 'Bad';
    default:
      return 'Terrible';
  }
};
