import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';

export const StyledCount = styled(Typography)(({ theme }) => ({
  color: theme.token.color.neutral.text.weak[' '].value,
}));

export const StyledChecklistOptionContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const StyledChecklistOptionInnerContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const StyledShowMoreOrLessLinkBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  alignItems: 'center',
}));

export const ChecklistOptionBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
