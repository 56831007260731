import React from 'react';
import {
  StyledCheckIcon,
  StyledPromotion,
  StyledRoomOptionsInclusionItem,
  StyledRoomOptionsInclusions,
} from './styles';
import { RoomTileProps } from '@src/components/room-tile';
import { Typography } from '@mui/material';
import { RoomOptionProps } from '../../types';

interface InclusionsProps {
  roomOptionVariant: RoomOptionProps['roomOptionVariant'];
  promotions: RoomTileProps['leadRoomOption']['promotions'];
}

const Inclusions = ({ roomOptionVariant, promotions }: InclusionsProps) => {
  return (
    <StyledRoomOptionsInclusions roomOptionVariant={roomOptionVariant} data-testid="room-options-inclusions">
      {promotions.length === 0 && <Typography variant="bodySm">This room rate has no extras.</Typography>}
      {promotions.length > 0 &&
        promotions.map(({ description, type }) => {
          const isBreakfast = type === 'breakfast';
          return (
            <StyledRoomOptionsInclusionItem data-testid="room-options-inclusion-item">
              <StyledCheckIcon />
              <StyledPromotion variant={isBreakfast ? 'bodySmStrong' : 'bodySm'} isBreakfast={isBreakfast}>
                {description}
              </StyledPromotion>
            </StyledRoomOptionsInclusionItem>
          );
        })}
    </StyledRoomOptionsInclusions>
  );
};

export default Inclusions;
