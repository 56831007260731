import Wifi from '@fctg-ds/icons/Wifi';
import Pool from '@fctg-ds/icons/Pool';
import HotTubIcon from '@mui/icons-material/HotTub';
import Spa from '@fctg-ds/icons/Spa';
import Restaurant from '@fctg-ds/icons/Restaurant';
import LandscapeIcon from '@mui/icons-material/Landscape';
import Accessible from '@fctg-ds/icons/Accessible';
import ConciergeIcon from '@src/assets/icons/ConciergeIcon';
import FitnessCenter from '@fctg-ds/icons/FitnessCenter';
import BusinessCenter from '@fctg-ds/icons/BusinessCenter';
import MultiBag from '@fctg-ds/icons/MultiBag';
import Parking from '@fctg-ds/icons/Parking';
import Bar from '@fctg-ds/icons/Bar';
import ChildCare from '@fctg-ds/icons/ChildCare';
import Activity from '@fctg-ds/icons/Activity';
import Laundry from '@fctg-ds/icons/Laundry';
import AirportShuttle from '@fctg-ds/icons/AirportShuttle';
import VacuumIcon from '@src/assets/icons/VacuumIcon';
import ReceptionBell from '@fctg-ds/icons/ReceptionBell';
import TvIcon from '@mui/icons-material/Tv';
import IronIcon from '@mui/icons-material/Iron';
import CheckIcon from '@mui/icons-material/Check';
import AcIcon from '@src/assets/icons/AcIcon';
import BathroomIcon from '@src/assets/icons/BathroomIcon';
import CoffeeIcon from '@src/assets/icons/CoffeeIcon';
import Lock from '@fctg-ds/icons/Lock';
import Phone from '@fctg-ds/icons/Phone';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import HairdryerIcon from '@src/assets/icons/HairdryerIcon';
import ToiletriesIcon from '@src/assets/icons/ToiletriesIcon';
import WorkdeskIcon from '@src/assets/icons/WorkdeskIcon';
import WardrobeIcon from '@src/assets/icons/WardrobeIcon';
import LoungeIcon from '@src/assets/icons/LoungeIcon';
import SoundproofingIcon from '@src/assets/icons/SoundproofingIcon';
import CurtainsIcon from '@src/assets/icons/CurtainsIcon';
import MinibarIcon from '@src/assets/icons/MinibarIcon';
import { TopAmenity } from '@src/components/top-hotel-amenities/types';

const AMENITIES_ICONS = [
  {
    type: 'wifi',
    icon: Wifi,
  },
  {
    type: 'pool',
    icon: Pool,
  },
  {
    type: 'tub',
    icon: HotTubIcon,
  },
  {
    type: 'spa',
    icon: Spa,
  },
  {
    type: 'restaurant',
    icon: Restaurant,
  },
  {
    type: 'view',
    icon: LandscapeIcon,
  },
  {
    type: 'accessibility',
    icon: Accessible,
  },
  {
    type: 'frontdesk',
    icon: ConciergeIcon,
  },
  {
    type: 'gym',
    icon: FitnessCenter,
  },
  {
    type: 'business',
    icon: BusinessCenter,
  },
  {
    type: 'luggage',
    icon: MultiBag,
  },
  {
    type: 'parking',
    icon: Parking,
  },
  {
    type: 'pool_bar',
    icon: Bar,
  },
  {
    type: 'kids',
    icon: ChildCare,
  },
  {
    type: 'tours',
    icon: Activity,
  },
  {
    type: 'laundry',
    icon: Laundry,
  },
  {
    type: 'shuttle',
    icon: AirportShuttle,
  },
  {
    type: 'housekeeping',
    icon: VacuumIcon,
  },
  {
    type: 'room_service',
    icon: ReceptionBell,
  },
  {
    type: 'airconditioning',
    icon: AcIcon,
  },
  {
    type: 'bathroom',
    icon: BathroomIcon,
  },
  {
    type: 'tv',
    icon: TvIcon,
  },
  {
    type: 'coffee',
    icon: CoffeeIcon,
  },

  {
    type: 'minibar',
    icon: MinibarIcon,
  },
  {
    type: 'safe',
    icon: Lock,
  },

  {
    type: 'curtains',
    icon: CurtainsIcon,
  },
  {
    type: 'soundproofing',
    icon: SoundproofingIcon,
  },
  {
    type: 'ironing',
    icon: IronIcon,
  },
  {
    type: 'seating_area',
    icon: LoungeIcon,
  },
  {
    type: 'telephone',
    icon: Phone,
  },
  {
    type: 'alarm_clock',
    icon: AccessAlarmIcon,
  },
  {
    type: 'wardrobe',
    icon: WardrobeIcon,
  },
  {
    type: 'work_desk',
    icon: WorkdeskIcon,
  },
  {
    type: 'toiletries',
    icon: ToiletriesIcon,
  },
  {
    type: 'hair_dryer',
    icon: HairdryerIcon,
  },
];

export const getAmenityIcon = (amenity: TopAmenity) =>
  AMENITIES_ICONS.find((icon) => icon.type === amenity.type)?.icon || CheckIcon;
