import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';
import Add from '@fctg-ds/icons/Add';
import { Divider } from '@fctg-ds/core';

export const StyledStickyPackageDetails = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1249px',
  backgroundColor: theme.token.color.neutral.background.weak[' '].value,
  padding: `${theme.spacing(6)} ${theme.spacing(8)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: `${theme.spacing(2.5)} ${theme.spacing(10)}`,
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(5)}`,
    gap: theme.spacing(2),
    alignItems: 'end',
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
    gap: theme.spacing(1),
    flexWrap: 'no-wrap',
  },
}));

export const StyledStickyPackageDetailsInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(1),
    maxWidth: '330px',
  },
}));

export const StyledStickyPackageDetailsContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: `${theme.spacing(1)} ${theme.spacing(2)}`,
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(1),
  },
}));

export const StyledTitleText = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  textOverflow: 'hidden',
  [theme.breakpoints.down('sm')]: { width: '150px' },
}));

export const StyledHeadingText = styled(Typography)(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledBodyText = styled(Typography)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

export const StyledAddIcon = styled(Add)(() => ({
  width: 16,
  height: 16,
}));

export const StyledDivider = styled(Divider)(() => ({
  margin: 0,
}));

export const StyledTravellersBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));
