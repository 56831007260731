import { isConsecutive } from '../isConsecutive/isConsecutive';
import isNil from 'lodash/isNil';
import { FilterBarActive } from '@src/components/filter-bar/types';

export const getNonConsecutiveLabel = (numbersList: number[]): string => {
  if (!numbersList || numbersList.length === 0) return '';

  const propertyText = numbersList.reduce((accumulator, item, index) => {
    if (index < numbersList.length - 2) accumulator += `${item}, `;
    if (index === numbersList.length - 2) accumulator += `${item}`;
    if (index === numbersList.length - 1) accumulator += ` or ${item} star`;

    return accumulator;
  }, '');

  return propertyText;
};

export const getPropertyClassChipLabel = (ratings: FilterBarActive['rating']): string => {
  if (!ratings || ratings.length === 0) return 'Property class';

  if (ratings.length === 1) return `${ratings[0]} star`;

  if (ratings.length === 5) return `1 - 5 star`;

  const sortedRatings = ratings.slice().sort((a, b) => a - b);

  if (ratings.length === 2) return `${sortedRatings[0]} or ${sortedRatings[1]} star`;

  if (isConsecutive(sortedRatings)) return `${sortedRatings[0]} - ${sortedRatings[sortedRatings.length - 1]} star`;

  return getNonConsecutiveLabel(sortedRatings);
};

export const getGuestReviewChipLabel = (value?: FilterBarActive['reviews']) =>
  value ? `Guest review score ${value}+` : 'Guest review score';

export const getPriceChipLabel = (value?: FilterBarActive['price'], currency = 'AU$') => {
  if (!value || isNil(value[0]) || isNil(value[1])) {
    return 'Price';
  }

  return `${currency}${value[0].toLocaleString()} - ${currency}${value[1].toLocaleString()}`;
};

export const getAreaChipLabel = (areas: FilterBarActive['area']) => {
  const MAX_CHIP_TOTAL_LENGTH = 22;
  const MAX_TEXT_LENGTH = 16; // Does not include ellipsis and count

  if (!areas || areas.length === 0) return 'Area';

  const labelInfo = areas.reduce(
    ({ label, count }, area) => {
      const updatedLabel = label.length === 0 ? area : `${label}, ${area}`;

      if (updatedLabel.length > MAX_CHIP_TOTAL_LENGTH) {
        return {
          label: `${updatedLabel.substring(0, MAX_TEXT_LENGTH)}... +${count}`,
          count: count + 1,
        };
      }

      return { label: updatedLabel, count };
    },
    { label: '', count: 1 },
  );

  return labelInfo.label;
};
