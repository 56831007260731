import { Box, CardMedia } from '@mui/material';
import { styled } from '@fctg-ds/theme';

export const StyledRoomTile = styled(Box)(({ theme }) => ({
  backgroundColor: theme.token.color.background[' '].value,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignContent: 'space-between',
  gap: 0,
  border: `${theme.spacing(0.25)} solid ${theme.token.color.neutral.border[' '].value}`,
  borderRadius: theme.spacing(4),
  overflow: 'hidden',
  height: '100%',
}));

export const StyledRoomTileFlexibleBox = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(6)} ${theme.spacing(6)} 0 ${theme.spacing(6)}`,
}));

export const StyledEmptyBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  display: 'block',
  minHeight: theme.spacing(16),
  background: theme.token.color.neutral.background.medium[' '].value,
}));

export const StyledTopBox = styled(Box)(() => ({
  width: '100%',
}));

export const StyledBottomBox = styled(Box)(({ theme }) => ({
  border: `${theme.spacing(4)} solid ${theme.token.color.neutral.background.medium[' '].value}`,
  borderRadius: theme.spacing(4),
  flex: 1,
}));

export const StyledRoomImageBox = styled(Box)(({ theme }) => ({
  height: '275px',
  backgroundColor: theme.token.color.disabled.border.value,
}));

export const StyledRoomEmptyImage = styled(CardMedia)(() => ({
  width: '100%',
  height: '275px',
  transform: 'scale(0.75)',
}));

export const StyledRoomImageSection = styled(Box)(() => ({
  width: '100%',
  height: '275px',
}));
