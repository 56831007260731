import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';

export const MainBoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: theme.spacing(2),
  [theme.breakpoints.between('xs', 'sm')]: {
    flexWrap: 'wrap',
  },
  [theme.breakpoints.between('lg', 1000)]: {
    flexWrap: 'wrap',
  },
}));

export const SelectionBoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
}));

export const IconStyle = styled(Box)<{ isActive?: boolean }>(({ theme, isActive }) => ({
  backgroundColor: isActive
    ? theme.token.color.success.text[' '].value
    : theme.token.color.neutral.text.weak[' '].value,
  width: theme.spacing(6),
  textAlign: 'center',
  borderRadius: theme.spacing(1),
}));

export const TextStyle = styled(Typography)(({ theme }) => ({
  color: theme.token.color.background.weak[' '].value,
}));
