import React from 'react';
import { Typography } from '@mui/material';
import { PoliciesProps } from './types';
import CheckInOut from './components/check-in-out/main';
import PaymentMethods from './components/payment-methods/main';
import SectionContainer from './components/section-container/main';
import { StyledPolicies, StyledPoliciesComponents } from './styles';

const Policies = ({ policies, checkInTime, checkOutTime }: PoliciesProps) => {
  const { checkInInstructions, knowBeforeYouGo, fees, optionalExtras } = policies;

  return (
    <StyledPolicies data-testid="policies">
      <Typography variant="headingMd" data-testid="policiesHeading">
        Policies & essential info
      </Typography>
      <StyledPoliciesComponents>
        <CheckInOut checkInTime={checkInTime} checkOutTime={checkOutTime} checkInInstructions={checkInInstructions} />
        <SectionContainer data={fees} type="fees" />
        <SectionContainer data={optionalExtras} type="optional" />
        <SectionContainer data={knowBeforeYouGo} type="know" />
        <PaymentMethods />
      </StyledPoliciesComponents>
    </StyledPolicies>
  );
};

export default Policies;
