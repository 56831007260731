import React from 'react';
import { Typography } from '@mui/material';
import {
  StyledHeader,
  StyledPersonIcon,
  StyledHeaderInner,
  StyledHeaderInnerContent,
  StyledTypography,
  StyledSup,
  StyledSizeSqmIcon,
  StyledStayIcon,
} from './styles';
import { RoomTileProps } from '../../types';

interface HeaderProps extends Pick<RoomTileProps, 'name' | 'bedGroups' | 'squareMeters' | 'occupancy'> {}

const Header = ({ name, bedGroups, squareMeters, occupancy }: HeaderProps) => {
  return (
    <StyledHeader data-testid="room-tile-header">
      <Typography variant="subheadingLg" data-testid="room-tile-name">
        {name}
      </Typography>
      {(bedGroups.length > 0 || squareMeters || occupancy) && (
        <StyledHeaderInner data-testid="room-details">
          {bedGroups.length > 0 && (
            <StyledHeaderInnerContent data-testid="room-details-bed-type">
              <StyledStayIcon />
              <StyledTypography variant="bodySm">{bedGroups.join(' OR ')}</StyledTypography>
            </StyledHeaderInnerContent>
          )}
          {squareMeters && (
            <StyledHeaderInnerContent data-testid="room-details-area">
              <StyledSizeSqmIcon />
              <StyledTypography variant="bodySm">
                {squareMeters}m<StyledSup data-testid="room-details-area-superscript">2</StyledSup>
              </StyledTypography>
            </StyledHeaderInnerContent>
          )}
          {occupancy && (
            <StyledHeaderInnerContent data-testid="room-details-occupancy">
              <StyledPersonIcon />
              <StyledTypography variant="bodySm">Sleeps {occupancy}</StyledTypography>
            </StyledHeaderInnerContent>
          )}
        </StyledHeaderInner>
      )}
    </StyledHeader>
  );
};

export default Header;
