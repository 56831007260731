import React from 'react';
import { Carousel } from '@fctg-ds/core';
import { ImagesCounter } from '@src/components/image-gallery/components';
import { StyledCardMedia, StyledGalleryBox, StyledCard } from './styles';

export interface MediaElement {
  url: string;
  caption: string;
}

export interface GalleryProps {
  isRoomDetails?: boolean;
  isMobile?: boolean;
  images: MediaElement[];
}

const RoomGallery = ({ images, isMobile, isRoomDetails }: GalleryProps) => {
  return (
    <StyledGalleryBox data-testid="room-tile-gallery">
      <Carousel
        loop
        data-testid="image-gallery-carousel"
        lazyLoad="nearby"
        placeholder={undefined}
        hidePagination={!isMobile}
        hideArrows={isMobile}
      >
        {images?.map((image) => (
          <StyledCard key={`room-image-${image.url}`}>
            <StyledCardMedia component="img" data-splide-lazy={image.url} isRoomDetails={isRoomDetails} />
          </StyledCard>
        ))}
      </Carousel>
      <ImagesCounter imagesCount={images.length} />
    </StyledGalleryBox>
  );
};

export default RoomGallery;
