import { SelectField } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';

export const StyledAgesSelectorContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  gripGap: theme.spacing(1),
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(4),
}));

export const StyledAgeSelectField = styled(SelectField)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.token.color.warning.text.weak[' '].value,
}));
