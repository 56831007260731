import { Typography } from '@mui/material';
import React from 'react';
import {
  StyledStickyPackageDetails,
  StyledStickyPackageDetailsInfo,
  StyledStickyPackageDetailsContent,
  StyledTitleText,
  StyledHeadingText,
  StyledBodyText,
  StyledAddIcon,
  StyledDivider,
  StyledTravellersBox,
} from './styles';
import { PackageDetailsProps } from '../../types';
import PackageDetailsPricing from '../package-details-pricing';

const StickyPackageDetails = ({
  departureDate,
  returnDate,
  travellersCount,
  nights,
  total,
  originalPrice,
  currency,
  amountSaved,
}: PackageDetailsProps) => {
  return (
    <StyledStickyPackageDetails data-testid="sticky-package-details">
      <StyledStickyPackageDetailsInfo>
        <StyledTitleText variant="headingMd">Package Details</StyledTitleText>
        <StyledStickyPackageDetailsContent data-testid="sticky-package-details-content">
          <StyledHeadingText variant="bodySmStrong">Travel dates</StyledHeadingText>
          <Typography variant="bodySm">
            {departureDate} – {returnDate}
          </Typography>
          <StyledDivider variant="dot" />
          <StyledHeadingText variant="bodySmStrong">Travellers</StyledHeadingText>
          <StyledTravellersBox>
            <Typography variant="bodySm">{travellersCount}</Typography>
            <StyledBodyText variant="bodySm">travellers</StyledBodyText>
          </StyledTravellersBox>
          <StyledDivider variant="dot" />
          <StyledHeadingText variant="bodySmStrong">Flights</StyledHeadingText>
          <Typography variant="bodySm">{travellersCount} return airfares</Typography>
          <StyledAddIcon />
          <StyledHeadingText variant="bodySmStrong">Stay</StyledHeadingText>
          <Typography variant="bodySm">{nights} nights</Typography>
        </StyledStickyPackageDetailsContent>
      </StyledStickyPackageDetailsInfo>
      <PackageDetailsPricing
        total={total}
        originalPrice={originalPrice}
        currency={currency}
        amountSaved={amountSaved}
      />
    </StyledStickyPackageDetails>
  );
};

export default StickyPackageDetails;
