import React from 'react';
import SectionHeader from '../section-header/main';
import { Box, Typography } from '@mui/material';
import { styled } from '@fctg-ds/theme';

const StyledPaymentMethods = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  width: '100%',
}));

const StyledPaymentMethodsIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  width: '100%',
  flexWrap: 'wrap',
}));

const StyledPaymentMethodsIcon = styled('img')(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(15),
}));

const PaymentMethods = () => {
  return (
    <SectionHeader type={'payment'} title={'Payment methods'}>
      <StyledPaymentMethods data-testid="policiesPaymentMethodsInner">
        <Typography variant="bodySm" data-testid="policiesPaymentMethodsInnerText">
          Flight Centre accepts the following payment methods:
        </Typography>
        <StyledPaymentMethodsIconContainer data-testid="policiesPaymentMethodsInnerIcons">
          <StyledPaymentMethodsIcon
            className="test-visa"
            alt="Visa"
            src="https://secure.fclmedia.com/assets/payment-options/visa.svg"
            data-testid="policiesPaymentMethodsInnerVisaIcon"
          />
          <StyledPaymentMethodsIcon
            className="test-mastercard"
            alt="Mastercard"
            src="https://secure.fclmedia.com/assets/payment-options/mastercard.svg"
            data-testid="policiesPaymentMethodsInnerMasterCardIcon"
          />
          <StyledPaymentMethodsIcon
            className="test-paypal"
            alt="PayPal"
            src="https://secure.fclmedia.com/assets/payment-options/paypal.svg"
            data-testid="policiesPaymentMethodsInnerPayPalIcon"
          />
          <StyledPaymentMethodsIcon
            className="test-diners"
            alt="Diners Club"
            src="https://secure.fclmedia.com/assets/payment-options/dinersclub.svg"
            data-testid="policiesPaymentMethodsInnerDinersClubIcon"
          />
          <StyledPaymentMethodsIcon
            className="test-amex"
            alt="American Express"
            src="https://secure.fclmedia.com/assets/payment-options/americanexpress.svg"
            data-testid="policiesPaymentMethodsInnerAmexIcon"
          />
        </StyledPaymentMethodsIconContainer>
      </StyledPaymentMethods>
    </SectionHeader>
  );
};

export default PaymentMethods;
