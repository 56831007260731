import { Box } from '@mui/material';
import { styled } from '@fctg-ds/theme';

export const StyledPolicies = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  width: '100%',
}));

export const StyledPoliciesComponents = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(10),
  width: '100%',
}));
