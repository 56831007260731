import { Box, Link, Typography } from '@mui/material';
import { styled } from '@fctg-ds/theme';

const mapMinHeight = '100px';
const staticMapHeight = '228px';

export const StyledHotelMapContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  width: '100%',
  height: '100%',
}));

export const StyledHotelAddressContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(1),
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export const StyledHotelMapAddress = styled(Typography)(({ theme }) => ({
  maxWidth: 'none',
  textTransform: 'capitalize',
  color: theme.token.color.neutral.text.weak[' '].value,
  [theme.breakpoints.up('lg')]: {
    maxWidth: '78%',
  },
}));

export const StyledNearbyAttractionsLink = styled(Typography)(({ theme }) => ({
  width: 'fit-content',
  cursor: 'pointer',
  textDecoration: 'underline',
  color: theme.token.color.primary.text[' '].value,
}));

export const StyledNearbyAttractionsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

export const StyledInitialNearbyAttractionsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

export const StyledHotelMapLink = styled(Link)(({ theme }) => ({
  cursor: 'pointer',
  width: 'fit-content',
  color: theme.token.color.primary[' '].value,
}));

export const StyledMapImageBox = styled(Box)<{ staticMapUrl: string }>(({ staticMapUrl, theme }) => ({
  backgroundImage: `url(${staticMapUrl})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  borderRadius: theme.spacing(4),
  cursor: 'pointer',
  [theme.breakpoints.up('xs')]: {
    backgroundSize: 'auto 200%',
    height: staticMapHeight,
    minHeight: staticMapHeight,
  },
  [theme.breakpoints.up(500)]: {
    backgroundSize: 'cover',
  },
  [theme.breakpoints.up('lg')]: {
    backgroundSize: 'auto 250%',
    height: 'fill-available',
    minHeight: mapMinHeight,
  },
  [theme.breakpoints.up(1000)]: {
    backgroundSize: 'cover',
  },
}));
