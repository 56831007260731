import { Amenities, TopAmenity } from '@src/components/top-hotel-amenities/types';
import { NUM_OF_AMENITIES_MOBILE } from '@src/components/top-hotel-amenities/constants';

export const mapTopAmenities = (amenities: Amenities): TopAmenity[] => {
  const { all, top } = amenities;

  if (!all || all.length === 0) return top;

  const newTopAmenities: TopAmenity[] = [];
  if (top.length < NUM_OF_AMENITIES_MOBILE) {
    const itemsToFetch = NUM_OF_AMENITIES_MOBILE - top.length;
    // Avoiding duplicate values.
    const uniqueAllAmenities = all.filter(
      (allItem) => !top.find((topItem) => topItem.name.toLowerCase() === allItem.toLowerCase()),
    );
    uniqueAllAmenities.slice(0, itemsToFetch).map((amenity) => {
      newTopAmenities.push({
        name: amenity,
        type: '',
      });
    });
  }

  return [...top, ...newTopAmenities];
};
