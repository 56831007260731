import React from 'react';
import { StepperProps as MuiStepperProps } from '@mui/material/Stepper';
import { StyledConnector, StyledStep, StyledStepLabel, StyledStepper } from './style';

export interface StepperProps extends MuiStepperProps {
  steps: string[];
}

export const Stepper = ({ steps, ...props }: StepperProps) => {
  const { activeStep, alternativeLabel } = props;
  return (
    <StyledStepper data-testid="stepper" connector={<StyledConnector alternativeLabel={alternativeLabel} />} {...props}>
      {steps.map((label, index) => {
        return (
          <StyledStep data-testid="stepper-step" key={`stepper-step-${label}`} completed={index < activeStep}>
            <StyledStepLabel>{label}</StyledStepLabel>
          </StyledStep>
        );
      })}
    </StyledStepper>
  );
};

export default Stepper;
