import { Typography } from '@mui/material';
import React from 'react';
import {
  StyledPackageDetails,
  StyledPackageDetailsInfo,
  StyledPackageDetailsContent,
  StyledAddIcon,
  StyledTravellersBox,
  StyledDivider,
} from './styles';
import { PackageDetailsProps } from '../../types';
import PackageDetailsPricing from '../package-details-pricing';

const StaticPackageDetails = ({
  departureDate,
  returnDate,
  travellersCount,
  nights,
  total,
  originalPrice,
  currency,
  amountSaved,
}: PackageDetailsProps) => {
  return (
    <StyledPackageDetails data-testid="static-package-details">
      <StyledPackageDetailsInfo>
        <Typography variant="headingMd">Package Details</Typography>
        <StyledPackageDetailsContent data-testid="static-package-details-content">
          <Typography variant="bodySmStrong">Travel dates</Typography>
          <Typography variant="bodySm">
            {departureDate} – {returnDate}
          </Typography>
          <StyledDivider variant="dot" />
          <StyledTravellersBox>
            <Typography variant="bodySmStrong">Travellers</Typography>
            <Typography variant="bodySm">{travellersCount}</Typography>
          </StyledTravellersBox>
          <StyledDivider variant="dot" />
          <Typography variant="bodySmStrong">Flights</Typography>
          <Typography variant="bodySm">{travellersCount} return airfares</Typography>
          <StyledAddIcon />
          <Typography variant="bodySmStrong">Stay</Typography>
          <Typography variant="bodySm">{nights} nights</Typography>
        </StyledPackageDetailsContent>
      </StyledPackageDetailsInfo>
      <PackageDetailsPricing
        total={total}
        originalPrice={originalPrice}
        currency={currency}
        amountSaved={amountSaved}
      />
    </StyledPackageDetails>
  );
};

export default StaticPackageDetails;
