import { Travellers } from '@src/components/travellers-selector/types';
import { PackagesResultsSearchFormProps } from '../types';
import { useRef, useState } from 'react';
import { CabinClassType } from '@src/common/types/types';

interface UseTravellersSelector {
  onChangeEvents: PackagesResultsSearchFormProps['onChangeEvents'];
}

export const useTravellersSelector = ({ onChangeEvents }: UseTravellersSelector) => {
  const ref = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);

  const onSubmit = (travellers: Travellers, cabinClass?: CabinClassType) => {
    onChangeEvents.onTravellersChange(travellers);
    if (cabinClass) onChangeEvents.onCabinClassChange(cabinClass);
  };

  const onOpen = () => {
    try {
      (document.activeElement as HTMLElement).blur();
    } catch (error) {
      console.error(error);
    }
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return {
    onSubmit,
    onOpen,
    onClose,
    open,
    ref,
  };
};
