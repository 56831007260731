import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@fctg-ds/theme';
import NightsPax from '@src/components/nights-pax/main';
import Price from '@src/components/price/main';
import { RoomPriceProps } from '../../types';

const StyledPriceBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  width: '100%',
  minHeight: '64px',
}));

const StyledPriceInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  whiteSpace: 'pre-line',
  textWrap: 'balance',
  width: '100%',
  marginRight: 0,
  [theme.breakpoints.between('md', 630)]: {
    width: '46%',
    marginRight: `-${theme.spacing(6)}`,
  },
  [theme.breakpoints.between('lg', 980)]: {
    width: '45%',
    marginRight: `-${theme.spacing(6)}`,
  },
  [theme.breakpoints.up(980)]: {
    marginRight: `-${theme.spacing(1)}`,
  },
}));

const PackagesRoomPrice = ({
  displayPrice,
  originalPrice,
  amountSaved,
  nights,
  travellers,
  roomOptionVariant,
}: RoomPriceProps) => {
  return (
    <StyledPriceBox data-testid="room-options-price-container">
      <StyledPriceInfo data-testid="room-options-price-content">
        <NightsPax nights={nights} travellersCount={travellers} showFlights />
      </StyledPriceInfo>
      <Price
        displayPrice={displayPrice}
        originalPrice={originalPrice}
        amountSaved={amountSaved}
        showPriceDifference
        showTaxDisclaimer={roomOptionVariant === 'roomTile'}
      />
    </StyledPriceBox>
  );
};

export default PackagesRoomPrice;
