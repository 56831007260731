import { styled } from '@fctg-ds/theme';
import { Box, Grid } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { PropertyClassMode } from './types';

export const StyledPropertyClassGridContainer = styled(Grid)<{ mode: PropertyClassMode }>(({ mode, theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: mode === 'popup' ? 0 : 'inherit',
  justifyContent: 'flex-start',
  maxWidth: 'none',
  gap: mode === 'popup' ? theme.spacing(2) : 0,
  [theme.breakpoints.between('md', 'lg')]: {
    flexWrap: mode === 'popup' ? 'nowrap' : 'wrap',
  },
  [theme.breakpoints.up(1000)]: {
    maxWidth: '360px',
  },
}));

export const StyledPropertyClassGridItem = styled(Grid)(({ theme }) => ({
  flexBasis: 'inherit',
  [theme.breakpoints.down('lg')]: {
    maxWidth: 'auto',
    flexBasis: 'auto',
  },
}));

export const StyledPropertyClassSelection = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledPropertyClassStarIcon = styled(StarIcon)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
}));
