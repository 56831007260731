import { FilterBarActive } from '@src/components/filter-bar/types';
import isNil from 'lodash/isNil';

export const isAreaChipActive = (area: FilterBarActive['area']) => area && area.length > 0;

export const isPropertyClassChipActive = (rating: FilterBarActive['rating']) => rating && rating.length > 0;

export const isPriceChipActive = (price: FilterBarActive['price']) => price && !isNil(price[0]) && !isNil(price[1]);

export const isGuestReviewChipActive = (reviews: FilterBarActive['reviews']) => !!reviews;

export const isFullyRefundableChipActive = (refundable: FilterBarActive['refundable']) => refundable;

export const isAvailableChipActive = (available: FilterBarActive['available']) => available;

export const isSortChipActive = (sort: FilterBarActive['sort']) => sort !== null;
