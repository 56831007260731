import Slider, { SliderProps } from '@mui/material/Slider';
import { styled } from '@fctg-ds/theme';

export default styled(Slider)<SliderProps>(({ theme }) => {
  return {
    color: theme.token.color.neutral.text.weak[' '].value,
    height: 8,
    padding: `0 !important`, // This is to prevent additional padding being added at runtime.
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: theme.token.color.brand[' '].value,
      border: `3px solid ${theme.token.color.neutral.background.weak[' '].value}`,
      boxShadow: theme.token.shadow.raised.value,
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: `0 0 0 ${theme.token.spacing[2].value} ${theme.token.color.inverted.background.hovered.value}`,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          boxShadow: theme.token.shadow.raised.value,
        },
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      borderRadius: '4px',
    },
  };
});
