import { CabinClassType } from '@src/common/types/types';

export const getCabinClassLabel = (option: CabinClassType) => {
  switch (option) {
    case 'ECONOMY':
      return 'Economy';
    case 'PREMIUM_ECONOMY':
      return 'Premium Economy';
    case 'BUSINESS':
      return 'Business Class';
    default:
      return 'First Class';
  }
};
