import React from 'react';
import { Typography } from '@mui/material';
import { StyledEmptyBox, StyledEmptyTypography } from '../../styles';
import DOMPurify from 'dompurify';

interface DetailsContentProps {
  description?: string;
}

const DetailsContent = ({ description }: DetailsContentProps) => {
  const sanitizer = DOMPurify.sanitize;
  return (
    <>
      {description ? (
        <Typography
          data-testid="room-details-modal-description"
          variant="bodySm"
          dangerouslySetInnerHTML={{ __html: sanitizer(description) }}
        />
      ) : (
        <StyledEmptyBox data-testid="room-details-modal-no-details">
          <StyledEmptyTypography>The room details cannot be displayed at this time.</StyledEmptyTypography>
        </StyledEmptyBox>
      )}
    </>
  );
};

export default DetailsContent;
