import { Box } from '@mui/material';
import { styled } from '@fctg-ds/theme';

export const StyledGuestReviewTileBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(5),
  backgroundColor: theme.token.color.neutral.background.medium[' '].value,
  width: '100%',
  borderRadius: theme.spacing(4),
}));

export const StyledGuestReviewTileHeader = styled(Box)<{ isMobile: boolean }>(({ isMobile, theme }) => ({
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
  gap: theme.spacing(6),
  width: '100%',
  flexWrap: 'wrap',
}));
