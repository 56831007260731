import React from 'react';
import { useTheme } from '@mui/material';
import { Modal } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { ControlPosition, AdvancedMarker, Map, MapProps } from '@vis.gl/react-google-maps';
import { APIProvider } from '@vis.gl/react-google-maps';
import { HOTEL_MAP_ID, HOTEL_MAP_DEFAULT_ZOOM } from '@src/common/constants';
import { HotelMapModalProps } from './types';
import MapHotelPin from '@src/assets/icons/MapHotelPin';

const StyledMapIcon = styled(MapHotelPin)(({ theme }) => ({
  fontSize: theme.spacing(12),
}));

const HotelMapModal = ({ modalTitle, hotelCoordinates, mapKey, isMobile, open, onClose }: HotelMapModalProps) => {
  const mapOptions: MapProps = {
    zoomControl: isMobile ? false : true,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    clickableIcons: false,
    zoomControlOptions: {
      position: ControlPosition.RIGHT_BOTTOM,
    },
    gestureHandling: 'greedy',
  };
  const theme = useTheme();

  return (
    <Modal
      contentStyles={{ height: '100%', marginTop: theme.spacing(4) }}
      noContentPadding
      fullScreen
      title={modalTitle}
      open={open}
      onClose={onClose}
      data-testid="hotel-map-modal"
    >
      <APIProvider apiKey={mapKey}>
        <Map defaultCenter={hotelCoordinates} defaultZoom={HOTEL_MAP_DEFAULT_ZOOM} mapId={HOTEL_MAP_ID} {...mapOptions}>
          <AdvancedMarker position={{ lat: hotelCoordinates.lat, lng: hotelCoordinates.lng }}>
            <StyledMapIcon />
          </AdvancedMarker>
        </Map>
      </APIProvider>
    </Modal>
  );
};

export default HotelMapModal;
