import { PackagesDates } from '@src/common/types/types';
import { format, parse } from 'date-fns';
import { useRef, useState } from 'react';
import { PackagesResultsSearchFormProps } from '../types';

interface useSearchFormDatePickerProps {
  onChangeEvents: PackagesResultsSearchFormProps['onChangeEvents'];
  departureDate: string;
  returnDate: string;
}

export const useSearchFormDatePicker = ({
  onChangeEvents,
  departureDate,
  returnDate,
}: useSearchFormDatePickerProps) => {
  const DATE_FORMAT = 'EEE, d MMM';

  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState<PackagesDates>({ departureDate, returnDate });

  const onOpen = () => {
    try {
      (document.activeElement as HTMLElement).blur();
    } catch (error) {
      console.error(error);
    }
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onClear = () => {
    setDates(null);
  };

  const onChange = (dates: PackagesDates) => {
    onChangeEvents.onDatesChange(dates);
    setDates(dates);
  };

  const departureDateFormatted = dates?.departureDate ? parse(dates.departureDate, 'yyyy-LL-dd', new Date()) : null;
  const returnDateFormatted = dates?.returnDate ? parse(dates.returnDate, 'yyyy-LL-dd', new Date()) : null;

  const displayValue =
    departureDateFormatted && returnDateFormatted
      ? `${format(departureDateFormatted, DATE_FORMAT)} - ${format(returnDateFormatted, DATE_FORMAT)}`
      : '';

  return {
    onOpen,
    onClose,
    onClear,
    onChange,
    open,
    dates,
    ref,
    displayValue,
  };
};
