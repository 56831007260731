import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

export const StyledFooter = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  boxShadow: `0 -1px 0 ${theme.token.color.border.disabled.value}`,
}));
