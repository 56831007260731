import { ResultsSortOption, ResultsSortOptionLabel } from '@src/common/sort-chip/constants';

export const fetchResultsLabelByOption = (sortOption: ResultsSortOption): ResultsSortOptionLabel => {
  switch (sortOption) {
    case 'highest_price':
      return 'Highest price';
    case 'lowest_price':
      return 'Lowest price';
    case 'property_class':
      return 'Highest property class';
    case 'guest_review':
      return 'Highest review score';
    default:
      return 'Recommended';
  }
};
