import { Typography } from '@mui/material';
import { HighlightedTextProps } from './type';
import React from 'react';

export const HighlightedText = ({ text, keyword }: HighlightedTextProps) => {
  const higlightedSections = text.split(new RegExp(`(${keyword})`, 'gi'));
  return (
    <Typography variant="bodySmStrong">
      {keyword ? (
        <span>
          {higlightedSections.map((section: string, sectionIndex: number) =>
            section.toLowerCase() === keyword.toLowerCase() ? (
              <Typography
                key={`highlighted-section-${section}-${sectionIndex}`}
                variant="bodySmStrong"
                component="b"
                sx={(theme) => ({ color: theme.token.color.primary[' '].value })}
              >
                {section}
              </Typography>
            ) : (
              section
            ),
          )}
        </span>
      ) : (
        text
      )}
    </Typography>
  );
};
