import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';
import { Popover } from '@fctg-ds/core';

export const DatePickerPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    [theme.breakpoints.up('lg')]: {
      minHeight: '570px',
      height: '48%',
      minWidth: '710px',
      width: '63.5%',
    },
    [theme.breakpoints.up(1090)]: {
      width: '63.8%', // Just little pixel issues here and there, to ensure a smooth transition.
    },
    [theme.breakpoints.up(1150)]: {
      width: '64.7%',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '799px',
      width: 'auto',
    },
    [theme.breakpoints.up(1500)]: {
      height: '33%',
    },
  },
}));

export const DatePickerContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(6),
  paddingTop: theme.spacing(4.5),
  margin: `0 -${theme.spacing(2.5)} 0 -${theme.spacing(1.5)}`,
}));

export const FooterStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  minHeight: '70px',
  flexWrap: 'wrap',
  borderTop: `${theme.spacing(0.25)} solid ${theme.token.color.neutral.border[' '].value}`,
  padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
  },
}));

export const FooterButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  alignItems: 'center',
  flexGrow: '1',
  flexWrap: 'wrap',
}));
