import React from 'react';
import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';
import { createPortal } from 'react-dom';

const StyledStickyPackageDetailsWrapper = styled(Box)(() => ({
  position: 'fixed',
  width: '100%',
  display: 'grid',
  placeItems: 'center',
  top: 0,
  left: 0,
  boxShadow: '0px 6px 6px -2px #0000000A',
}));

interface StickyPackageDetailsWrapperProps {
  children: React.ReactNode;
}

const StickyPackageDetailsWrapper = ({ children }: StickyPackageDetailsWrapperProps) => {
  return createPortal(<StyledStickyPackageDetailsWrapper>{children}</StyledStickyPackageDetailsWrapper>, document.body);
};

StickyPackageDetailsWrapper.displayName = 'StaysSkuPagePolicyWrapper';

export default StickyPackageDetailsWrapper;
