import React, { useState } from 'react';
import { StyledFormBox } from './styles';
import { InputType, SearchFormProps } from './types';
import { DateSelection, TravellersSelection } from './components';

export const EditSearchForm = ({
  dates,
  travellers,
  isMobileOrTablet = false,
  onDatepickerDone,
  onTravellersDone,
  scrollToRooms,
  onEmitEvent,
}: SearchFormProps) => {
  const [component, setOpenComponent] = useState<InputType>('closed');

  const onOpenComponent = async (value: InputType) => {
    await scrollToRooms();
    setOpenComponent(value);
  };

  const onClose = () => {
    setOpenComponent('closed');
  };

  return (
    <StyledFormBox>
      <DateSelection
        component={component}
        dates={dates}
        isMobileOrTablet={isMobileOrTablet}
        onDone={onDatepickerDone}
        onOpen={onOpenComponent}
        onClose={onClose}
        onEmitEvent={onEmitEvent}
      />
      <TravellersSelection
        component={component}
        isMobileOrTablet={isMobileOrTablet}
        travellers={travellers}
        onDone={onTravellersDone}
        onOpen={onOpenComponent}
        onClose={onClose}
        onEmitEvent={onEmitEvent}
      />
    </StyledFormBox>
  );
};
