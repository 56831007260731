import React from 'react';
import {
  StyledDatesTextField,
  StyledAutocompleteTextField,
  StyledFieldGroup,
  StyledPackagesResultsSearchForm,
  StyledPersonIcon,
  StyledPrimaryButton,
  StyledTravellersTextField,
  StyledTextfieldShineThrough,
} from './styles';
import FlightForward from '@fctg-ds/icons/FlightForward';
import LocationPin from '@fctg-ds/icons/LocationPin';
import DateRange from '@fctg-ds/icons/DateRange';
import Search from '@fctg-ds/icons/Search';
import { PackagesResultsSearchFormProps } from '../../types';
import SearchFormDatePicker from '@src/components/search-form-date-picker/main';
import { useSearchFormDatePicker } from '../../hooks/useSearchFormDatePicker';
import { useAutocomplete } from '../../hooks/useAutocomplete';
import TravellersSelector from '@src/components/travellers-selector/main';
import { useTravellersSelector } from '../../hooks/useTravellersSelector';
import { getCabinClassLabel } from '@src/components/travellers-selector/components/cabin-class/constants';
import Autocomplete from '@src/components/autocomplete/main';
import { getTravellersText } from '@src/helpers/getTravellersText/getTravellersText';
import ErrorDialog from '../error-dialog/main';
import { useErrorDialog } from '../../hooks/useErrorDialog';
import { OptionType } from '@src/components/autocomplete/types';

const PackagesResultsSearchFormDesktop = ({
  origin,
  destination,
  departureDate,
  returnDate,
  travellers,
  cabinClass,
  brandRegion,
  onChangeEvents,
  onFetchOptions,
}: PackagesResultsSearchFormProps) => {
  const {
    open,
    mode,
    options,
    onOpen,
    onChange,
    onFetch,
    onClose,
    onClear,
    originRef,
    destinationRef,
    getAutoCompleteRef,
  } = useAutocomplete({
    onChangeEvents,
    onFetchOptions,
  });
  const datePickerControls = useSearchFormDatePicker({
    onChangeEvents,
    departureDate,
    returnDate,
  });
  const travellersSelectorControls = useTravellersSelector({
    onChangeEvents,
  });
  const errorDialogControls = useErrorDialog({
    onChangeEvents,
    origin,
    destination,
    returnDate,
    departureDate,
    autocompleteOnChange: onChange,
    autocompleteOnOpen: onOpen,
  });

  const travellersSelectorLabel = `${getTravellersText(travellers)} • ${getCabinClassLabel(cabinClass)}`;

  return (
    <StyledPackagesResultsSearchForm data-testid="packages-results-search-form-desktop">
      <StyledFieldGroup layout={[2]}>
        <StyledAutocompleteTextField
          value={origin?.displayValue}
          placeholder="Where from?"
          hiddenLabel
          startAdornment={<FlightForward />}
          autoComplete="off"
          onClick={() => onOpen('origin')}
          ref={originRef}
          data-testid="origin-selection-desktop"
        />
        <StyledAutocompleteTextField
          id="destination-selection"
          value={destination?.displayValue}
          placeholder="Where to?"
          hiddenLabel
          startAdornment={<LocationPin />}
          autoComplete="off"
          onClick={() => onOpen('destination')}
          ref={destinationRef}
          data-testid="destination-selection-desktop"
        />
      </StyledFieldGroup>
      <StyledDatesTextField
        value={datePickerControls.displayValue}
        placeholder="Add dates"
        hiddenLabel
        startAdornment={<DateRange />}
        fullWidth
        ref={datePickerControls.ref}
        onClick={datePickerControls.onOpen}
        data-testid="dates-selection-desktop"
      />
      <StyledTravellersTextField
        value={travellersSelectorLabel}
        hiddenLabel
        startAdornment={<StyledPersonIcon />}
        fullWidth
        ref={travellersSelectorControls.ref}
        onClick={travellersSelectorControls.onOpen}
        data-testid="travellers-selection-desktop"
      />
      <StyledPrimaryButton
        placeholder={undefined}
        onClick={errorDialogControls.onValidatedSubmit}
        data-testid="results-search-form-button"
      >
        <Search />
      </StyledPrimaryButton>
      {open && (
        <Autocomplete
          mode={mode}
          options={options}
          onChange={(value: OptionType) => errorDialogControls.onValidatedChange(value, mode)}
          onClose={onClose}
          onClear={onClear}
          fetchOptions={onFetch}
          anchorEl={getAutoCompleteRef()}
          isOpen={open}
          brandRegion={brandRegion}
          desktopBreakpoint="lg"
        />
      )}
      <TravellersSelector
        isMobileOrTablet={false}
        travellers={travellers}
        cabinClass={cabinClass}
        open={travellersSelectorControls.open}
        onSubmit={travellersSelectorControls.onSubmit}
        onClose={travellersSelectorControls.onClose}
        anchorEl={travellersSelectorControls.ref?.current}
        inputComponent={
          <>
            {travellersSelectorControls.ref?.current && (
              <StyledTextfieldShineThrough
                value={travellersSelectorLabel}
                width={travellersSelectorControls.ref.current.getBoundingClientRect().width}
                startAdornment={<StyledPersonIcon />}
                hiddenLabel
                focused
              />
            )}
          </>
        }
      />
      <SearchFormDatePicker
        isOpen={datePickerControls.open}
        onClose={datePickerControls.onClose}
        onChange={datePickerControls.onChange}
        dates={datePickerControls.dates}
        anchorEl={datePickerControls.ref?.current}
      />
      <ErrorDialog errorStatus={errorDialogControls.errorStatus} onClose={errorDialogControls.onClose} />
    </StyledPackagesResultsSearchForm>
  );
};

export default PackagesResultsSearchFormDesktop;
