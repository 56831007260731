import React from 'react';
import { Modal, Drawer, IconButton } from '@fctg-ds/core';
import CloseIcon from '@mui/icons-material/Close';
import { AllHotelAmenitiesProps } from '../../types';
import AllHotelAmenitiesContent from '../all-hotel-amenities-content/main';

const AllHotelAmenitiesContainer = ({ amenities, isMobile, open, handleOpenOrClose }: AllHotelAmenitiesProps) => {
  return (
    <>
      {isMobile ? (
        <Drawer
          open={open}
          onOpen={() => true}
          onClose={handleOpenOrClose}
          hideDragHandle
          drawerHeaderTitle="Property amenities"
          leftActionBtn={
            <IconButton onClick={handleOpenOrClose}>
              <CloseIcon />
            </IconButton>
          }
          data-testid="allHotelAmenitiesDialog"
        >
          <AllHotelAmenitiesContent amenities={amenities} isMobile={true} />
        </Drawer>
      ) : (
        <Modal
          open={open}
          title="Property amenities"
          onClose={handleOpenOrClose}
          showClose={true}
          slideUp={true}
          maxHeight={'80%'}
          maxWidth={'lg'}
          noContentPadding
          data-testid="allHotelAmenitiesModal"
        >
          <AllHotelAmenitiesContent amenities={amenities} isMobile={false} />
        </Modal>
      )}
    </>
  );
};

export default AllHotelAmenitiesContainer;
