import React from 'react';
import { StyledPaper } from './styles';
import { Box } from '@mui/material';

interface TextFieldShineThrough {
  component: React.ReactNode;
}

export const TextFieldShineThrough = ({ component }: TextFieldShineThrough) => {
  return (
    <StyledPaper elevation={0}>
      <Box width="100%">{component}</Box>
    </StyledPaper>
  );
};
