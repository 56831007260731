import React from 'react';
import DOMPurify from 'dompurify';
import { Box, Typography } from '@mui/material';
import { styled } from '@fctg-ds/theme';

interface SectionContentProps {
  content: string;
}

const StyledPoliciesSectionContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
  '& ul': {
    padding: `0 0 0 ${theme.spacing(6)}`,
    margin: 0,
  },
  '& li': {
    marginBottom: theme.spacing(2),
  },
  '& li: last-child': {
    marginBottom: 0,
  },
  '& p: first-child': {
    marginTop: 0,
  },
  '& p: last-child': {
    marginBottom: 0,
  },
}));

const SectionContent = ({ content }: SectionContentProps) => {
  const sanitizer = DOMPurify.sanitize;

  return (
    <StyledPoliciesSectionContent data-testid="policiesSectionContentBox">
      <Typography variant="bodySm" dangerouslySetInnerHTML={{ __html: sanitizer(content) }} />
    </StyledPoliciesSectionContent>
  );
};

export default SectionContent;
