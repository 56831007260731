import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FilterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon data-testid="filter-bar-icon" {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.82929 7C9.41746 8.16519 8.30622 9 7 9C5.34315 9 4 7.65685 4 6C4 4.34315 5.34315 3 7 3C8.30622 3 9.41746 3.83481 9.82929 5H20V7H9.82929ZM8 6C8 6.55228 7.55228 7 7 7C6.44772 7 6 6.55228 6 6C6 5.44772 6.44772 5 7 5C7.55228 5 8 5.44772 8 6Z"
          fill="#212121"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.1707 11C14.5825 9.83481 15.6938 9 17 9C18.6569 9 20 10.3431 20 12C20 13.6569 18.6569 15 17 15C15.6938 15 14.5825 14.1652 14.1707 13L4 13V11L14.1707 11ZM16 12C16 12.5523 16.4477 13 17 13C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11C16.4477 11 16 11.4477 16 12Z"
          fill="#212121"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 21C8.30622 21 9.41746 20.1652 9.82929 19H20V17H9.82929C9.41746 15.8348 8.30622 15 7 15C5.34315 15 4 16.3431 4 18C4 19.6569 5.34315 21 7 21ZM7 19C7.55228 19 8 18.5523 8 18C8 17.4477 7.55228 17 7 17C6.44772 17 6 17.4477 6 18C6 18.5523 6.44772 19 7 19Z"
          fill="#212121"
        />
      </svg>
      ;
    </SvgIcon>
  );
};

export default FilterIcon;
