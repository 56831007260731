import { ProductTile, ProductTileProps } from '@fctg/ui-stays-components';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { StyledBox, StyledIconButton, StyledProductTileWrapper } from './styles';
import { EmitEventProps } from '@src/common/types/types';
import { MapModalProps } from '@src/components/map-modal';

interface MapProductTileContextProps {
  selectedHotel: ProductTileProps | null;
  onMarkerClick: (hotelId: ProductTileProps['hotelId']) => void;
}

interface ProductTileProviderProps {
  children?: React.ReactNode;
  productTileData: ProductTileProps[];
  onEmitEvent?: (event: EmitEventProps) => void;
  variant?: MapModalProps['variant'];
}

const MapProductTileContext = React.createContext<MapProductTileContextProps>(null);

export const MapProductTileWrapper = ({
  children,
  productTileData,
  onEmitEvent,
  variant = 'stays',
}: ProductTileProviderProps) => {
  const [selectedHotel, setSelectedHotel] = useState<ProductTileProps | null>(productTileData[0]); // Set default to first result
  const onMarkerClick = (hotelId: ProductTileProps['hotelId']) => {
    const hotel = productTileData.find((hotel) => hotel.hotelId === hotelId);
    setSelectedHotel(hotel || null);
  };
  const gtmInteraction = () => {
    onEmitEvent &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: variant === 'stays' ? 'staySelect' : 'packageSelect',
        },
      });
  };

  return (
    <MapProductTileContext.Provider
      value={{
        selectedHotel,
        onMarkerClick,
      }}
    >
      {children}
      {selectedHotel &&
        createPortal(
          <StyledBox>
            <StyledProductTileWrapper>
              <Box onClick={gtmInteraction} onContextMenu={gtmInteraction}>
                <ProductTile isMapView {...selectedHotel} showCarousel={false} />
              </Box>
              <StyledIconButton size="small" variant="contained" onClick={() => setSelectedHotel(null)}>
                <CloseIcon />
              </StyledIconButton>
            </StyledProductTileWrapper>
          </StyledBox>,
          document.body,
        )}
    </MapProductTileContext.Provider>
  );
};

export const useMapProductTile = () => React.useContext(MapProductTileContext);
