import React from 'react';
import { Typography } from '@mui/material';
import { StyledTileButtonBox, StyledTileButton } from './styles';
import { RoomTileProps } from '../../../room-tile/types';
import { RoomOptionProps } from '../../types';

interface TileButtonProps {
  roomOptionVariant: RoomOptionProps['roomOptionVariant'];
  roomId: RoomTileProps['id'];
  roomRateId: RoomTileProps['leadRoomOption']['roomRateId'];
  handleSelect: RoomTileProps['handleSelect'];
  onEmitEvent: RoomTileProps['onEmitEvent'];
}

const SelectButton = ({ roomId, roomOptionVariant, roomRateId, handleSelect, onEmitEvent }: TileButtonProps) => {
  const onSelect = () => {
    handleSelect({ roomRateId, roomId });
    onEmitEvent &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: 'CTA Buttons',
          interactionLabel: 'Room Selected',
        },
      });
  };
  return (
    <StyledTileButtonBox roomOptionVariant={roomOptionVariant} data-testid="room-option-select-button-box">
      <StyledTileButton onClick={onSelect} data-testid="room-option-select-button" color="primary" fullWidth>
        <Typography variant="bodyMdStrong">Select</Typography>
      </StyledTileButton>
    </StyledTileButtonBox>
  );
};

export default SelectButton;
