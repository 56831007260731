import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';

export const StyledDrawerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  paddingBottom: theme.spacing(4),
}));

export const StyledMobileSelectText = styled(Typography)(() => ({
  textAlign: 'center',
}));
