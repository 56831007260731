import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

export const StyledBoxChip = styled(Box)<{ isActive: boolean; fullWidth?: boolean }>(
  ({ theme, isActive, fullWidth }) => ({
    width: fullWidth ? '98px' : '68px',
    margin: `${theme.spacing(2)} 0 0 0`,
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    borderRadius: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid',
    cursor: 'pointer',
    ...(isActive
      ? {
          color: theme.token.color.border.active.value,
          borderColor: theme.token.color.border.hovered.value,
          border: '2px solid',
        }
      : {
          color: theme.token.color.text.disabled.value,
          borderColor: theme.token.color.border.disabled.value,
        }),
    ...(!fullWidth
      ? {
          [theme.breakpoints.between('lg', 1000)]: {
            width: '68px',
          },
          [theme.breakpoints.between('xs', 'sm')]: {
            width: '68px',
          },
          width: '98px',
        }
      : {}),
  }),
);
