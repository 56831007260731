import { Box } from '@mui/material';
import { styled } from '@fctg-ds/theme';
import { SecondaryButton } from '@fctg-ds/core';

export const StyledRoomOptionsButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: `0 ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
}));

export const StyledRoomOptionsButton = styled(SecondaryButton)(({ theme }) => ({
  minHeight: theme.spacing(12),
  border: `1px solid ${theme.token.color.neutral.border[' '].value}`,
}));
