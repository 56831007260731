import React, { useEffect, useState } from 'react';
import { SelectionDate } from '@src/components/calendar-date-picker/types';
import { DateInputStyle, InputInfoStyle, StyledDatePickerIcon, StyledInputLabel, StyledInputValue } from './styles';
import { format } from 'date-fns';
import { Selection } from '../../types';

interface DateInputProps {
  date: SelectionDate;
  type: 'checkIn' | 'checkOut';
  initialSelection: Selection;
  handleSelectionChange: (selection: Selection) => void;
}

const DateInput = ({ date, type, initialSelection, handleSelectionChange }: DateInputProps) => {
  const DATE_FORMAT = 'EEE, d MMM';
  const [selection, setSelection] = useState<Selection>(initialSelection);

  useEffect(() => {
    setSelection(initialSelection);
  }, [initialSelection]);

  const handleClick = () => {
    if (type === 'checkIn') {
      if (selection !== 'start') {
        setSelection('start');
        handleSelectionChange('start');
      }
    } else {
      if (selection !== 'end') {
        setSelection('end');
        handleSelectionChange('end');
      }
    }
  };

  return (
    <DateInputStyle
      placeholder={undefined}
      isActive={type === 'checkIn' ? selection === 'start' : selection === 'end'}
      onClick={handleClick}
    >
      <StyledInputLabel variant="captionMd">Check {type === 'checkIn' ? 'in' : 'out'}</StyledInputLabel>
      <InputInfoStyle>
        <StyledDatePickerIcon />
        <StyledInputValue variant="bodyMd">
          {date && date.day
            ? format(new Date(`${date.year}/${date.month}/${date.day}`), DATE_FORMAT)
            : type === 'checkIn'
              ? 'Start date'
              : 'End date'}
        </StyledInputValue>
      </InputInfoStyle>
    </DateInputStyle>
  );
};

export default DateInput;
