import React from 'react';
import { Checkbox } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';
import { EmitEventProps } from '@src/common/types/types';

export interface CancellationPolicyProps {
  checked: boolean;
  count: number;
  handleChange: () => void;
  onEmitEvent?: (event: EmitEventProps) => void;
}
export const StyledCount = styled(Typography)(({ theme }) => ({
  color: theme.token.color.neutral.text.weak[' '].value,
}));

export const StyledCancellationPolicyContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const StyledCheckboxContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  marginRight: theme.spacing(-4),
}));

const CancellationPolicy = ({ checked, count, handleChange, onEmitEvent }: CancellationPolicyProps) => {
  return (
    <StyledCancellationPolicyContainer data-testid="cancellation-policy">
      <StyledCheckboxContainer>
        <StyledCheckbox
          onChange={() => {
            onEmitEvent &&
              !checked &&
              onEmitEvent({
                event: 'trackInteraction',
                data: {
                  interactionValue: 'filterBy',
                  interactionLabel: 'fullyRefundable',
                },
              });
            handleChange();
          }}
          checked={checked}
          name={'fully-refundable'}
        />
        <Typography variant="bodyMd">Fully refundable</Typography>
      </StyledCheckboxContainer>
      <StyledCount variant="bodyMd">{count || 0}</StyledCount>
    </StyledCancellationPolicyContainer>
  );
};

export default CancellationPolicy;
