import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Button } from '@fctg-ds/core';

export const DateInputStyle = styled(Button)<{ isActive: boolean }>(({ theme, isActive }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '54px',
  width: '100%',
  justifyContent: 'space-evenly',
  alignItems: 'flex-start',
  textDecoration: 'none',
  padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
  backgroundColor: theme.token.color.neutral.background.weak[' '].value,
  outline: isActive
    ? `${theme.spacing(0.5)} solid ${theme.token.color.neutral.text[' '].value}`
    : `${theme.spacing(0.25)} solid ${theme.token.color.neutral.border[' '].value}`,
  borderRadius: theme.spacing(2.5),
  cursor: 'pointer',
  '&:active, &:hover': {
    textDecoration: 'none',
  },
}));

export const InputInfoStyle = styled(Box)(({ theme }) => ({
  minWidth: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const StyledDatePickerIcon = styled(DateRangeIcon)(({ theme }) => ({
  color: theme.token.color.neutral.icon.weak[' '].value,
  marginTop: '-2px',
  marginLeft: '1px',
}));

export const StyledInputLabel = styled(Typography)(({ theme }) => ({
  marginTop: '1px',
  fontSize: '10.5px',
  color: theme.token.color.neutral.text.weak[' '].value,
}));

export const StyledInputValue = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  color: theme.token.color.neutral.text[' '].value,
}));
