import React from 'react';
import { Box, Typography } from '@mui/material';
import { Policy } from '../../types';
import SectionContent from '../section-content/main';
import { styled } from '@fctg-ds/theme';

interface CheckInOutInstructionsProps {
  checkInInstructions?: Policy['checkInInstructions'];
}

const StyledCheckInOutContentInnerListBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}));

const StyledCheckInOutContentInnerHighlightsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  backgroundColor: theme.token.color.neutral.background.medium[' '].value,
  borderRadius: theme.spacing(3),
  padding: theme.spacing(6),
}));

const CheckInOutInstructions = ({ checkInInstructions }: CheckInOutInstructionsProps) => {
  const { checkIn, checkInSpecial } = checkInInstructions;

  return (
    <>
      {checkIn && (
        <StyledCheckInOutContentInnerListBox data-testid="policiesCheckInOutInstructionsBox">
          <SectionContent content={checkIn} />
        </StyledCheckInOutContentInnerListBox>
      )}
      {checkInSpecial && (
        <StyledCheckInOutContentInnerHighlightsBox data-testid="policiesCheckInOutHighlightsBox">
          <Typography variant="bodySm">{checkInSpecial}</Typography>
        </StyledCheckInOutContentInnerHighlightsBox>
      )}
    </>
  );
};

export default CheckInOutInstructions;
