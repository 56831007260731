import React from 'react';
import { Box } from '@mui/material';
import { FilterBarProps } from './types';
import { DesktopFilterbar, MobileFilterbar } from './components';
import { RESULTS_SORT_OPTIONS } from '@src/common/sort-chip/constants';

const FilterBar = ({
  isMobile,
  scrollWidth,
  metaData,
  activeProps,
  onChangeEvents,
  onEmitEvent,
  mapEnabled = true,
}: FilterBarProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {isMobile ? (
        <MobileFilterbar
          scrollWidth={scrollWidth}
          metaData={metaData}
          activeProps={activeProps}
          onChangeEvents={onChangeEvents}
          onEmitEvent={onEmitEvent}
          sortOptions={RESULTS_SORT_OPTIONS}
        />
      ) : (
        <DesktopFilterbar
          activeProps={activeProps}
          onChangeEvents={onChangeEvents}
          metaData={metaData}
          onEmitEvent={onEmitEvent}
          mapEnabled={mapEnabled}
        />
      )}
    </Box>
  );
};

export default FilterBar;
