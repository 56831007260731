import { Box, styled } from '@mui/material';
import { FieldGroup, TextField } from '@fctg-ds/core';
import FlightForward from '@fctg-ds/icons/FlightForward';
import LocationPin from '@fctg-ds/icons/LocationPin';

export const StyledPackagesResultsSearchForm = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

export const StyledFieldGroup = styled(FieldGroup)(({ theme }) => ({
  width: '100%',
  borderColor: theme.token.color.neutral.background.weak[' '].value,
  minWidth: '190px',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '50%',
  backgroundColor: theme.token.color.neutral.background.medium[' '].value,
  borderRadius: theme.spacing(3),
  '.MuiInputBase-root': {
    height: theme.spacing(10),
    backgroundColor: theme.token.color.neutral.background.medium[' '].value,
    borderColor: theme.token.color.neutral.background.weak[' '].value,
    cursor: 'pointer',
  },
  '.MuiInputBase-root:hover': {
    backgroundColor: theme.token.color.neutral.background.medium[' '].value,
    borderColor: theme.token.color.neutral.background.weak[' '].value,
  },
  '& .MuiInputBase-input': {
    fontSize: theme.spacing(3.5),
    cursor: 'pointer',
  },
  '.MuiFilledInput-root.Mui-focused': {
    backgroundColor: theme.token.color.neutral.background.medium[' '].value,
  },
  '.MuiFilledInput-root.Mui-focused::before': {
    borderWidth: '1px',
  },
}));

export const StyledAutocompleteTextField = styled(StyledTextField)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-input': {
    marginLeft: theme.spacing(1.5),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  '& .MuiFilledInput-root:hover': {
    zIndex: 2,
  },
}));

export const StyledMobileDatesTextField = styled(StyledTextField)(() => ({
  minWidth: '185px',
}));

export const StyledTravellersTextField = styled(StyledTextField)(() => ({
  minWidth: 'fit-content',
}));

export const StyledFlightForward = styled(FlightForward)(({ theme }) => ({
  fontSize: theme.spacing(4),
}));

export const StyledLocationPin = styled(LocationPin)(({ theme }) => ({
  fontSize: theme.spacing(4),
}));
