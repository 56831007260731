import { Category } from './types';

export const getCategoryDisplay = (category: Category) => {
  switch (category) {
    case 'cleanliness':
      return 'Cleanliness';
    case 'service':
      return 'Staff & service';
    case 'condition':
      return 'Property conditions & facilities';
    case 'amenities':
      return 'Amenities';
    default:
      return category;
  }
};
