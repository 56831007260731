import { Grid } from '@mui/material';
import RoomOption from '@src/components/room-option/main';
import { StyledRoomOptionModalBox } from '../../styles';
import React from 'react';
import { RoomOptionsModalProps } from '../..';
import {
  StyledBoxGrid,
  StyledModal,
  StyledModalContainer,
  StyledSelectText,
  StyledDesktopHeaderBox,
  StyledStaysSelectText,
} from './styles';
import { StickyPackageDetailsDesktop } from '../sticky-package-details-desktop/main';

interface RoomOptionsModalDesktopProps {
  open: RoomOptionsModalProps['open'];
  title: RoomOptionsModalProps['title'];
  roomOptions: RoomOptionsModalProps['roomOptions'];
  roomId: RoomOptionsModalProps['roomId'];
  handleClose: RoomOptionsModalProps['handleClose'];
  leadRoomOption: RoomOptionsModalProps['leadRoomOption'];
  handleSelect: RoomOptionsModalProps['handleSelect'];
  nights: RoomOptionsModalProps['nights'];
  travellers: RoomOptionsModalProps['travellers'];
  variant: RoomOptionsModalProps['variant'];
}

export const RoomOptionsModalDesktop = ({
  open,
  title,
  roomOptions,
  leadRoomOption,
  roomId,
  handleClose,
  handleSelect,
  nights,
  travellers,
  variant,
}: RoomOptionsModalDesktopProps) => {
  return (
    <StyledModal
      maxWidth="lg"
      maxHeight="100%"
      noContentPadding
      title={title}
      onClose={handleClose}
      open={open && roomOptions.length > 0}
    >
      <StyledModalContainer data-testid="room-option-modal-desktop">
        {variant === 'packages' && (
          <StyledDesktopHeaderBox>
            <StyledSelectText variant="bodyMd">Select one of the following room options for your stay</StyledSelectText>
            <StickyPackageDetailsDesktop leadRoomOption={leadRoomOption} nights={nights} travellers={travellers} />
          </StyledDesktopHeaderBox>
        )}
        <StyledBoxGrid>
          {variant === 'stays' && (
            <StyledStaysSelectText variant="bodyMd">
              Select one of the following room options for your stay
            </StyledStaysSelectText>
          )}
          <Grid container spacing={4}>
            {roomOptions.map((room, index) => (
              <Grid item lg={roomOptions.length <= 2 ? 6 : 4} md={6} xs={12} key={`room-option-modal-item-${index}`}>
                <StyledRoomOptionModalBox isLeadRoom={index === 0}>
                  <RoomOption
                    handleSelect={handleSelect}
                    roomOption={room}
                    nights={nights}
                    travellers={travellers}
                    roomId={roomId}
                    roomOptionVariant="roomOption"
                    variant={variant}
                  />
                </StyledRoomOptionModalBox>
              </Grid>
            ))}
          </Grid>
        </StyledBoxGrid>
      </StyledModalContainer>
    </StyledModal>
  );
};
