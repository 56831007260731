/**
 * Global service to format the currency display.
 * Considers the Prefix text, symbol, amount, commas and decimals.
 *
 * @param amount number the package price.
 * @param currencyCode string of the currency code, eg "AUD".
 * @returns CurrencyProps object with prefix string ("AU", "R"), symbol ("$", "€")
 * and amount with separators (",") and/or decimals.
 */
import { CurrencyRulesProps, CurrencyProps } from './types';

const currencyRules: CurrencyRulesProps = {
  // Australian Dollar
  AUD: {
    locale: 'en-AU',
    prefix: 'AU',
    decimals: 2,
    showDecimals: false,
  },
  // New Zealand Dollar
  NZD: {
    locale: 'en-NZ',
    prefix: 'NZ',
    decimals: 2,
    showDecimals: false,
  },
  // United States Dollar
  USD: {
    locale: 'en-US',
    prefix: 'US',
    decimals: 2,
    showDecimals: false,
  },
  // Canadian Dollar
  CAD: {
    locale: 'en-CA',
    prefix: 'CA',
    decimals: 2,
    showDecimals: false,
  },
  // Indian Rupee
  INR: {
    locale: 'en-IN',
    prefix: 'IN',
    decimals: 2,
    showDecimals: false,
  },
  // Hong Kong Dollar
  HKD: {
    locale: 'en-HK',
    prefix: 'HK',
    decimals: 2,
    showDecimals: false,
  },
  // Great Britain Pound Sterling
  GBP: {
    locale: 'en-GB',
    prefix: 'GB',
    decimals: 2,
    showDecimals: false,
  },
  // Euro
  EUR: {
    locale: 'en-EU',
    prefix: 'EU',
    decimals: 2,
    showDecimals: false,
  },
  // South African Rand
  ZAR: {
    locale: 'en-ZA',
    prefix: '',
    decimals: 2,
    showDecimals: false,
  },
  // Japanese Yen
  JPY: {
    locale: 'en-JP',
    prefix: 'JP',
    decimals: 2,
    showDecimals: false,
  },
};

const currenciesList: string[] = Object.keys(currencyRules);

export function currencyDisplay(
  amount: number | undefined,
  currencyCode: string,
  differenceMode?: boolean,
): CurrencyProps {
  const returnCurrency: CurrencyProps = {
    prefix: '',
    symbol: '',
    displayAmount: amount?.toString() || '',
  };

  if (Number.isNaN(amount) || !currenciesList.includes(currencyCode)) {
    return returnCurrency;
  }

  if (!differenceMode && (amount <= 0 || !amount)) {
    return returnCurrency;
  }

  const { locale, decimals, showDecimals, prefix } = currencyRules[currencyCode];

  const currencyFormatParts = new Intl.NumberFormat(locale, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: currencyCode,
    maximumFractionDigits: showDecimals ? decimals : 0,
    minimumFractionDigits: showDecimals ? decimals : 0,
  }).formatToParts(amount);
  let formattedAmount = '';

  currencyFormatParts.forEach((format) => {
    if (currencyCode === 'ZAR' && format.type === 'group') {
      // Replacing the standard " " with "," to delimit the groups for ZAR.
      formattedAmount += ',';
    } else if (
      format.type === 'integer' ||
      format.type === 'group' ||
      format.type === 'decimal' ||
      format.type === 'fraction'
    ) {
      formattedAmount += format.value;
    } else if (format.type === 'currency') {
      returnCurrency.symbol = format.value;
    }
  });

  returnCurrency.displayAmount = formattedAmount;

  const sign = !differenceMode || amount === 0 ? '' : amount < 0 ? '-' : '+';
  returnCurrency.prefix = `${sign}${prefix}`;

  return returnCurrency;
}

export function formattedCurrency(
  amount: number | undefined,
  currencyCode: string,
  displayPrefix?: boolean,
  differenceMode?: boolean,
): string {
  const formattedPrice: CurrencyProps = currencyDisplay(amount, currencyCode, differenceMode);
  return `${displayPrefix ? formattedPrice.prefix : ''}${formattedPrice.symbol}${formattedPrice.displayAmount}`;
}
