import React, { useState } from 'react';
import { ChecklistOptionProps, ChecklistProps, ShowMoreOrLessLinkProps } from './types';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import {
  ChecklistOptionBox,
  StyledChecklistOptionContainer,
  StyledChecklistOptionInnerContainer,
  StyledCount,
  StyledShowMoreOrLessLinkBox,
} from './styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Checkbox } from '@fctg-ds/core';

const ChecklistOption = ({ handleChange, selected, name, count, onEmitEvent }: ChecklistOptionProps) => {
  return (
    <StyledChecklistOptionContainer data-testid="checklist-option">
      <StyledChecklistOptionInnerContainer>
        <Checkbox
          sx={{ marginRight: '-16px' }}
          name={name}
          onChange={() => {
            handleChange(name);
            onEmitEvent &&
              !selected.includes(name) &&
              onEmitEvent({
                event: 'trackInteraction',
                data: {
                  interactionValue: 'filterBy',
                  interactionLabel: 'Neighbourhoods',
                },
              });
          }}
          checked={selected.includes(name)}
        />
        <Typography variant="bodyMd">{name}</Typography>
      </StyledChecklistOptionInnerContainer>
      <StyledCount variant="bodyMd">{count}</StyledCount>
    </StyledChecklistOptionContainer>
  );
};

const ShowMoreOrLessLink = ({ showMore, onClick }: ShowMoreOrLessLinkProps) => {
  return (
    <StyledShowMoreOrLessLinkBox data-testid="show-button" onClick={onClick}>
      <Typography variant="bodySmStrong">{showMore ? 'Show Less' : 'Show More'}</Typography>
      {showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </StyledShowMoreOrLessLinkBox>
  );
};

const Checklist = ({ selected, options, limit = 10, handleChange, onEmitEvent }: ChecklistProps) => {
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore((value) => !value);
  };

  const renderedOptions = !showMore && limit ? options.slice(0, limit) : options;
  const showOptions =
    renderedOptions.length !== options.length || (showMore !== undefined && limit < renderedOptions.length);

  return (
    <Box data-testid="checklist">
      <ChecklistOptionBox data-testid="checklist-options">
        {renderedOptions.map(({ name, count }, index) => (
          <ChecklistOption
            key={`checklist-option-${index}`}
            selected={selected}
            handleChange={handleChange}
            name={name}
            count={count}
            onEmitEvent={onEmitEvent}
          />
        ))}
      </ChecklistOptionBox>
      {limit > 0 && showOptions && <ShowMoreOrLessLink showMore={showMore} onClick={handleShowMore} />}
    </Box>
  );
};

export default Checklist;
