import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';
import React from 'react';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

interface MoreImagesIconProps {
  imagesCount: number;
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.token.color.neutral.background.weak[' '].value,
}));

const StyledPhotoLibraryIcon = styled(PhotoLibraryIcon)(({ theme }) => ({
  color: theme.token.color.neutral.background.weak[' '].value,
}));

const StyledImagesCounterBox = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.token.color.background.strong[' '].value}CC`,
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: theme.spacing(2),
  gap: theme.spacing(1),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4),
  },
  bottom: 0,
  right: 0,
}));

export const ImagesCounter = ({ imagesCount }: MoreImagesIconProps) => {
  return (
    <StyledImagesCounterBox data-testid="image-gallery-images-counter">
      <StyledPhotoLibraryIcon />
      <StyledTypography>{imagesCount}</StyledTypography>
    </StyledImagesCounterBox>
  );
};
