import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

export const StyledPaxCountChangerBox = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: theme.spacing(2),
    alignItems: 'center',
    flexFlow: 'row wrap',
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  };
});

export const StyledPaxCountChangerTextContainer = styled(Box)(() => {
  return {
    display: 'flex',
  };
});

export const StyledPaxCountChangerText = styled(Box)(({ theme }) => {
  return {
    fontSize: theme.token.typography.size.sm.value,
    fontWeight: theme.token.typography.weight.medium.value,
    color: theme.token.color.neutral.text[' '].value,
    paddingRight: '0.5rem',
  };
});

export const StyledPaxCountChangerSubtext = styled(Box)(({ theme }) => {
  return {
    fontSize: theme.token.typography.size.sm.value,
    fontWeight: theme.token.typography.weight.regular.value,
    color: theme.token.color.neutral.text.weak[' '].value,
  };
});
