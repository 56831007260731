import React from 'react';
import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';
import { BadgeProps } from './types';

export const StyledBadge = styled(Box)<{ type: BadgeProps['type'] }>(({ type, theme }) => ({
  height: theme.spacing(6),
  display: 'grid',
  placeItems: 'center',
  color: theme.token.color.neutral.background.weak[' '].value,
  backgroundColor:
    type === 'discount' ? theme.token.color.upsell.text.weak[' '].value : theme.token.color.red[600].value,
  borderRadius: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderColor: 'transparent',
}));

const Badge = ({ type, children }: BadgeProps) => {
  return (
    <StyledBadge type={type} data-testid="badgeBox">
      <Typography variant="captionMdStrong" data-testid="badgeText">
        {children}
      </Typography>
    </StyledBadge>
  );
};

export default Badge;
