import React from 'react';
import { Carousel } from '@fctg-ds/core';
import { Box, Typography } from '@mui/material';
import { TileProps } from '../../../destination-tile/types';

export interface DestinationTilesCarouselProps {
  arrowsPosition?: 'top' | 'center' | 'bottom';
  hidePagination?: boolean;
  header?: string;
  itemsPerView?: number;
  children: React.ReactElement<TileProps>[] | React.ReactElement<TileProps>;
}

export const DestinationTilesCarouselLayout = ({
  arrowsPosition = 'top',
  hidePagination = true,
  header = '',
  itemsPerView = 3,
  children,
}: DestinationTilesCarouselProps) => {
  return (
    <Carousel
      ariaLabel="Tiles Carousel"
      header={
        <Box pb={5}>
          <Typography variant="headingLg">{header}</Typography>
        </Box>
      }
      itemsPerView={itemsPerView}
      hidePagination={hidePagination}
      arrowsPosition={arrowsPosition}
      data-testid="tiles-carousel-component"
      component="div"
      placeholder={undefined}
    >
      {children}
    </Carousel>
  );
};

export default DestinationTilesCarouselLayout;
