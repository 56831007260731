import { Typography } from '@mui/material';
import { BoxChip } from '../box-chip';
import { ByGuestReviewSelectionProps, ByGuestReviewsProps, GuestReviewIconProps } from './types';
import React from 'react';
import { IconStyle, MainBoxStyle, SelectionBoxStyle, TextStyle } from './styles';
import { GUEST_REVIEW_OPTIONS } from './constants';

const ByGuestReviewsIcon = ({ isActive, value }: GuestReviewIconProps) => {
  return (
    <IconStyle isActive={isActive}>
      <TextStyle variant="subheadingSm">{value}+</TextStyle>
    </IconStyle>
  );
};

const ByGuestReviewSelection = ({ isActive, option, onChange, onEmitEvent }: ByGuestReviewSelectionProps) => (
  <BoxChip
    fullWidth
    isActive={isActive}
    onChange={() => {
      onEmitEvent &&
        !isActive &&
        onEmitEvent({
          event: 'trackInteraction',
          data: {
            interactionValue: 'filterBy',
            interactionLabel: 'userRating',
          },
        });
      onChange(option.value);
    }}
  >
    <SelectionBoxStyle>
      <ByGuestReviewsIcon isActive={isActive} value={option.value} />
      <Typography variant="captionMd">{option.label}</Typography>
    </SelectionBoxStyle>
  </BoxChip>
);

export const ByGuestReviews = ({ selected, onChange, onEmitEvent }: ByGuestReviewsProps) => {
  return (
    <MainBoxStyle>
      {GUEST_REVIEW_OPTIONS.map((option) => (
        <ByGuestReviewSelection
          isActive={selected === option.value}
          onChange={onChange}
          option={option}
          onEmitEvent={onEmitEvent}
        />
      ))}
    </MainBoxStyle>
  );
};
