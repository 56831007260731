import React from 'react';
import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';
import Bar from '../bar';

export interface GraphProps {
  chartData: Array<{ label: number; count: number }>;
  value: [number, number];
  graphTitlePrefix: string;
}

const GraphContainer = styled(Box)(({ theme }) => ({
  height: theme.token.spacing[20].value,
  display: 'flex',
  alignItems: 'flex-end',
}));

const PriceSliderGraph = ({ chartData = [], value, graphTitlePrefix }: GraphProps) => {
  const countArray = chartData.map((data) => data.count);
  const stepWidth = chartData.length > 1 ? chartData[1].label - chartData[0].label : 0;

  return (
    <GraphContainer>
      {chartData.map((data, key) => (
        <Bar
          key={key}
          active={data.label >= value[0] && data.label + stepWidth <= value[1]}
          itemCount={countArray.length}
          calculatedHeight={(data.count / Math.max(...countArray)) * 100}
          title={`${graphTitlePrefix}${data.count}`}
        />
      ))}
    </GraphContainer>
  );
};

export default PriceSliderGraph;
