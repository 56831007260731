import React from 'react';
import { RoomTileProps } from '../room-tile';
import { RoomOptionsModalDesktop, RoomOptionsDrawerMobile } from './components';

export interface RoomOptionsModalProps {
  open: boolean;
  title: string;
  roomOptions: RoomTileProps['roomOptions'];
  leadRoomOption: RoomTileProps['leadRoomOption'];
  roomId: RoomTileProps['id'];
  handleClose: () => void;
  handleSelect: RoomTileProps['handleSelect'];
  isMobile?: boolean;
  nights: number;
  travellers: number;
  variant: RoomTileProps['variant'];
}

export const RoomOptionsModal = ({
  open,
  isMobile,
  title,
  roomOptions,
  leadRoomOption,
  roomId,
  handleClose,
  handleSelect,
  nights,
  travellers,
  variant = 'stays',
}: RoomOptionsModalProps) => {
  return (
    <>
      {isMobile ? (
        <RoomOptionsDrawerMobile
          open={open}
          roomOptions={roomOptions}
          leadRoomOption={leadRoomOption}
          roomId={roomId}
          title={title}
          handleClose={handleClose}
          handleSelect={handleSelect}
          nights={nights}
          travellers={travellers}
          variant={variant}
        />
      ) : (
        <RoomOptionsModalDesktop
          open={open}
          roomOptions={roomOptions}
          leadRoomOption={leadRoomOption}
          roomId={roomId}
          title={title}
          handleClose={handleClose}
          handleSelect={handleSelect}
          nights={nights}
          travellers={travellers}
          variant={variant}
        />
      )}
    </>
  );
};
