import { Box, Link, Typography } from '@mui/material';
import { styled } from '@fctg-ds/theme';

export const StyledAmenities = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(6)} 0`,
  flexDirection: 'column',
  flexWrap: 'wrap',
}));

export const StyledAmenityItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'row',
  alignItems: 'center',
  paddingBottom: theme.spacing(3),
}));

export const StyledAmenitiesLink = styled(Link)(({ theme }) => ({
  color: theme.token.color.neutral.text[' '].value,
  textDecoration: 'none',
}));

export const StyledTypography = styled(Typography)(() => ({
  cursor: 'pointer',
  textDecoration: 'underline',
}));
