import { Box } from '@mui/material';
import { styled } from '@fctg-ds/theme';

export const StyledCategoryScoreMainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(2),
}));

export const StyledCategoryScoreScoreContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
  width: '100%',
}));

export const StyledCategoryScoreBarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: theme.spacing(3),
}));

export const StyledCategoryScoreLeftBar = styled(Box)<{ score: number }>(({ score, theme }) => ({
  width: `${score * 10}%`,
  borderRadius: theme.spacing(0.5),
  height: 'inherit',
  backgroundColor: score > 6.9 ? theme.token.color.green[500].value : theme.token.color.neutral.icon.weak[' '].value,
}));

export const StyledCategoryScoreRightBar = styled(Box)<{ score: number }>(({ score, theme }) => ({
  width: `${(10 - score) * 10}%`,
  borderTopRightRadius: theme.spacing(0.5),
  borderBottomRightRadius: theme.spacing(0.5),
  height: 'inherit',
  backgroundColor:
    score > 6.9 ? theme.token.color.success.background.medium[' '].value : theme.token.color.neutral.border[' '].value,
}));
