import { MenuItem } from '@fctg-ds/core';
import React from 'react';

interface MenuAgeItemsProps {
  minAge: number;
  maxAge: number;
}

export const getAgeItems = ({ minAge, maxAge }: MenuAgeItemsProps) => {
  const ageItems = [];
  const ages = Array(maxAge + 1 - minAge);

  Array.from(ages).forEach((_, index) => {
    const currentAge = index + minAge;
    ageItems.push(
      <MenuItem key={currentAge} value={currentAge} data-testid={`child-age-selector-item-${currentAge}`}>
        {currentAge}
      </MenuItem>,
    );
  });

  return ageItems;
};
