import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';

export const StyledMainBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  background: theme.token.color.neutral.background[' '].value,
  borderTop: `1px solid ${theme.token.color.disabled.border.value}`,
  padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
}));

export const StyledPackageDetailsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const StyledPriceDetailsBox = styled(StyledPackageDetailsBox)(() => ({
  alignItems: 'end',
  gap: 0,
}));

export const StyledPriceStaticText = styled(Box)(({ theme }) => ({
  width: '100%',
  color: theme.token.color.neutral.text.weak[' '].value,
  textAlign: 'end',
}));

export const StyledMobilePackageDetailsInfoText = styled(Typography)(() => ({
  maxWidth: '170px',
}));

export const StyledIncludesText = styled(Typography)(() => ({
  textWrap: 'nowrap',
}));
