import React, { useState, useEffect } from 'react';
import CalendarFooter from '../calendar-footer/main';
import {
  FooterButtons,
  FooterStyle,
  StyledDatePickerHeader,
  StyledDatePickerPopover,
  StyledDatePickerPopoverContentBox,
  StyledDatePickerPopoverMainBox,
  StyledDatesTextField,
} from './styles';
import ClearButton from '../edit-search-date-picker/components/clear-button/main';
import DoneButton from '../edit-search-date-picker/components/done-button/main';
import { DatePickerDesktopProps } from './types';
import { SelectionDate } from '../calendar-date-picker/types';
import { toSelectionDate } from '@src/helpers/toSelectionDate/toSelectionDate';
import { format, parse } from 'date-fns';
import { toStringDate } from '@src/helpers/toStringDate/toStringDate';
import CalendarDatePicker from '../calendar-date-picker/main';
import { DateRange } from '@mui/icons-material';
import { isSameDatesSelected } from '@src/helpers/isSameDatesSelected/isSameDatesSelected';
import { isWithinMaxStayDuration } from '@src/helpers/isWithinMaxStayDuration/isWithinMaxStayDuration';

const Footer = ({ startDate, endDate, setStartDate, setEndDate, handleDone }) => (
  <FooterStyle data-testid="search-form-date-picker-footer">
    <CalendarFooter checkInDate={startDate} checkOutDate={endDate} />
    <FooterButtons>
      {(startDate || endDate) && (
        <ClearButton
          handleClear={() => {
            setStartDate(null);
            setEndDate(null);
          }}
        >
          Clear
        </ClearButton>
      )}
      <DoneButton handleDone={handleDone} dates={{ checkInDate: startDate, checkOutDate: endDate }}>
        Done
      </DoneButton>
    </FooterButtons>
  </FooterStyle>
);

const DatePickerDesktop = ({
  open,
  dates,
  onChange,
  anchorEl,
  setShowErrorModal,
  handleClose,
}: DatePickerDesktopProps) => {
  const DATE_FORMAT = 'EEE, d MMM';

  const [startDate, setStartDate] = useState<SelectionDate | null>(
    dates?.departureDate ? toSelectionDate(dates.departureDate) : null,
  );
  const [endDate, setEndDate] = useState<SelectionDate | null>(
    dates?.returnDate ? toSelectionDate(dates.returnDate) : null,
  );

  useEffect(() => {
    setStartDate(toSelectionDate(dates?.departureDate));
    setEndDate(toSelectionDate(dates?.returnDate));
  }, [open]);

  const checkInDateFormatted = startDate ? parse(toStringDate(startDate), 'yyyy-LL-dd', new Date()) : null;
  const checkOutDateFormatted = endDate ? parse(toStringDate(endDate), 'yyyy-LL-dd', new Date()) : null;

  const handleSelectedDates = (newSelectionDate: SelectionDate) => {
    const formattedNewDate = parse(toStringDate(newSelectionDate), 'yyyy-LL-dd', new Date());

    if (startDate && endDate) {
      setStartDate(newSelectionDate);
      setEndDate(null);
    } else if (startDate && formattedNewDate.valueOf() > checkInDateFormatted.valueOf()) {
      setEndDate(newSelectionDate);
    } else {
      setStartDate(newSelectionDate);
    }
  };

  const handleDone = () => {
    if (!startDate || !endDate || isSameDatesSelected(startDate, endDate)) {
      return;
    }
    if (!isWithinMaxStayDuration(endDate, startDate)) {
      setShowErrorModal(true);
      return;
    }
    onChange({ departureDate: toStringDate(startDate), returnDate: toStringDate(endDate) });
    handleClose();
  };

  return (
    <StyledDatePickerPopover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transitionDuration={0}
      data-testid="search-form-date-picker"
    >
      <StyledDatePickerPopoverMainBox>
        <StyledDatePickerPopoverContentBox>
          <StyledDatePickerHeader>
            <StyledDatesTextField
              width={`${anchorEl?.clientWidth}px`}
              value={
                startDate && endDate
                  ? `${format(checkInDateFormatted, DATE_FORMAT)} - ${format(checkOutDateFormatted, DATE_FORMAT)}`
                  : ''
              }
              placeholder="Travel dates"
              hiddenLabel
              startAdornment={<DateRange />}
            />
          </StyledDatePickerHeader>
          <CalendarDatePicker
            initialCalendarDates={{ checkInDate: startDate, checkOutDate: endDate }}
            setNewCalendarDates={{ checkInDate: startDate, checkOutDate: endDate }}
            handleSelectedDate={handleSelectedDates}
          />
        </StyledDatePickerPopoverContentBox>
        <Footer
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleDone={handleDone}
        />
      </StyledDatePickerPopoverMainBox>
    </StyledDatePickerPopover>
  );
};

export default DatePickerDesktop;
