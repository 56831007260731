import { Box } from '@mui/material';
import { ByGuestReviews } from '@src/components/by-guest-reviews';
import { Ratings } from '@src/components/property-class/types';
import React from 'react';
import { EmitEventProps } from '@src/common/types/types';

interface GuestReviewProps {
  onChange: (value: Ratings) => void;
  selected: number;
  onEmitEvent?: (event: EmitEventProps) => void;
}

export const ByGuestReviewsMobile = ({ selected, onChange, onEmitEvent }: GuestReviewProps) => (
  <Box display="flex" justifyContent="center" width="100%">
    <ByGuestReviews selected={selected} onChange={onChange} onEmitEvent={onEmitEvent} />
  </Box>
);
