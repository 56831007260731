import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';

export const StyledPackageDetailsPrice = styled(Box)(({ theme }) => ({
  minWidth: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const StyledPriceInfoBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledOriginalPriceTypography = styled(Typography)(({ theme }) => ({
  color: theme.token.color.black[' '].value,
  textAlign: 'end',
  textDecoration: 'line-through',
  opacity: '0.54',
}));

export const StyledPriceComment = styled(Box)(({ theme }) => ({
  height: theme.spacing(5),
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.token.color.upsell['background'].medium[' '].value,
  color: theme.token.color.upsell['text'].medium[' '].value,
  fontSize: theme.spacing(3),
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)} ${theme.spacing(0.5)} ${theme.spacing(2)}`,
  borderRadius: theme.token.radius.sm.value,
}));

export const StyledTotalPriceBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'baseline',
}));

export const StyledPriceSavingsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'baseline',
}));

export const StyledTaxesFeesText = styled(Typography)(({ theme }) => ({
  color: theme.token.color.neutral.text.weak[' '].value,
}));
