import React from 'react';
import { styled } from '@fctg-ds/theme';
import { SecondaryButton } from '@fctg-ds/core';

export interface ClearButtonProps {
  children: React.ReactNode;
  handleClear: () => void;
}

const StyledClearButton = styled(SecondaryButton)(({ theme }) => ({
  width: 'fit',
  color: theme.token.color.neutral.text[' '].value,
  border: 'none',
  textDecoration: 'underline',
  '&:hover': { textDecoration: 'underline', border: 'none' },
}));

const ClearButton = ({ children, handleClear }: ClearButtonProps) => {
  return <StyledClearButton onClick={handleClear}>{children}</StyledClearButton>;
};

export default ClearButton;
