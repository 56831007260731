import React from 'react';
import { useBreakpointMapper } from '@fctg-ds/core';

import { Box, BoxProps } from '@mui/system';
import { nanoid } from 'nanoid';
import DestinationTile from '../destination-tile';
import DestinationTilesCarouselLayout from './components/carousel-layout';
import DestinationTilesStackedLayout from './components/stacked-layout';
import { Destination } from '@components/destination-tile/types';

export interface DestinationTilesProps extends BoxProps {
  destinations: Destination[];
  handleSelectTile?: (tile: Destination) => () => void;
  imageHeight?: number;
  headerText?: string;
  subText: string;
}
const DEFAULT_HEADER_TEXT = 'Explore trending destinations';

const DestinationTiles = ({
  destinations,
  imageHeight = 160,
  headerText = DEFAULT_HEADER_TEXT,
  subText,
  ...args
}: DestinationTilesProps) => {
  const [breakpointValues] = useBreakpointMapper({
    xs: { isDesktop: false },
    sm: { isDesktop: false },
    md: { isDesktop: true },
    lg: { isDesktop: true },
    xl: { isDesktop: true },
  });
  return (
    <Box data-testid="destination-tiles-component" {...args}>
      {breakpointValues.isDesktop ? (
        <DestinationTilesCarouselLayout header={headerText}>
          {destinations.map((destination) => (
            <DestinationTile key={nanoid()} subText={subText} destination={destination} imageHeight={imageHeight} />
          ))}
        </DestinationTilesCarouselLayout>
      ) : (
        <DestinationTilesStackedLayout header={DEFAULT_HEADER_TEXT}>
          {destinations.map((tile) => (
            <DestinationTile key={nanoid()} subText={subText} destination={tile} imageHeight={imageHeight} />
          ))}
        </DestinationTilesStackedLayout>
      )}
    </Box>
  );
};

export default DestinationTiles;
