import React from 'react';
import { ResultsHeaderProps } from './types';
import { Typography } from '@mui/material';
import SortChip from '@components/sort-chip';
import SortDisclaimer from '@components/sort-disclaimer';
import { RESULTS_SORT_OPTIONS } from '@src/common/sort-chip/constants';
import { fetchResultsLabelByOption } from '@src/helpers/fetchSortByLabel/fetchResultsLabelByOption';
import Stepper from '@components/stepper';
import {
  ResultsHeaderBox,
  ResultsHeaderStyle,
  SortBoxDesktop,
  SortBoxMobile,
  SortBoxStyle,
  SortByTextStyle,
} from './styles';
import { MapFab } from '../map-fab';

const STEPS = ['Find stay', 'Select room', 'Review flights'];

const ResultsHeader = ({
  header,
  sortValue,
  isMobile,
  variant,
  handleOnSelect,
  onEmitEvent,
  onMapClick,
  mapEnabled = true,
}: ResultsHeaderProps) => (
  <ResultsHeaderBox variant={variant}>
    {variant === 'packages' && <Stepper activeStep={0} steps={STEPS} alternativeLabel={isMobile} />}
    <ResultsHeaderStyle variant={variant}>
      <Typography variant="headingMd">{header}</Typography>
      {!isMobile && (
        <SortBoxDesktop>
          <SortBoxStyle>
            <SortByTextStyle variant="bodySmStrong">Sort by</SortByTextStyle>
            <SortChip
              sortValue={sortValue}
              sortOptions={RESULTS_SORT_OPTIONS}
              handleOnSelect={handleOnSelect}
              fetchLabelByOption={fetchResultsLabelByOption}
              onEmitEvent={onEmitEvent}
            />
          </SortBoxStyle>
          <SortDisclaimer />
        </SortBoxDesktop>
      )}
    </ResultsHeaderStyle>
    {isMobile && (
      <SortBoxMobile>
        <SortDisclaimer />
        <MapFab showFab={mapEnabled} onMapClick={onMapClick} onEmitEvent={onEmitEvent} />
      </SortBoxMobile>
    )}
  </ResultsHeaderBox>
);

export default ResultsHeader;
