import { styled } from '@fctg-ds/theme';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

interface StyleProps {
  isActive: boolean;
  isSoldOut: boolean;
}

export const StyledMarker = styled(Box)<StyleProps>(({ theme, isActive, isSoldOut }) => ({
  backgroundColor: theme.token?.color?.neutral?.background[' '].value,
  color: theme.token?.color?.black[' '].value,
  borderRadius: theme.spacing(2),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderColor: theme.token?.color?.black[' '].value,
  border: `2px solid ${theme.token?.color?.black[' '].value}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  cursor: 'pointer',
  ...(isActive && !isSoldOut
    ? {
        backgroundColor: theme.token?.color?.black[' '].value,
        color: theme.token?.color?.neutral?.background[' '].value,
        border: `2px solid ${theme.token?.color?.neutral?.background[' '].value}`,
      }
    : {}),
  ...(isSoldOut
    ? {
        backgroundColor: isActive
          ? theme.token.color.neutral.text.weak[' '].value
          : `${theme.token?.color?.neutral?.background[' '].value}`,
        color: isActive
          ? theme.token?.color?.neutral?.background[' '].value
          : theme.token.color.neutral.text.weak[' '].value,
        border: `2px solid ${theme.token.color.disabled.border.value}`,
      }
    : {}),
}));

export const StyledDipInner = styled(Box)<StyleProps>(({ theme, isActive, isSoldOut }) => ({
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  bottom: -10,
  position: 'absolute',
  borderLeft: `${theme.spacing(2.5)} solid transparent`,
  borderRight: `${theme.spacing(2.5)} solid transparent`,
  borderTop: `${theme.spacing(2.5)} solid ${theme.token?.color?.black[' '].value}`,
  ...(isActive
    ? {
        borderTop: `10px solid ${theme.token?.color?.neutral?.background[' '].value}`,
      }
    : {}),
  ...(isSoldOut
    ? {
        borderTop: `10px solid ${theme.token.color.disabled.border.value}`,
      }
    : {}),
}));

export const StyledDipOuter = styled(Box)<StyleProps>(({ theme, isActive, isSoldOut }) => ({
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  bottom: -10,
  position: 'absolute',
  borderLeft: '7px solid transparent',
  borderRight: '7px solid transparent',
  borderTop: `7px solid ${theme.token?.color?.neutral?.background[' '].value}`,
  ...(!isSoldOut && isActive
    ? {
        borderTop: `7px solid ${theme.token?.color?.black[' '].value}`,
      }
    : {}),
  ...(isSoldOut && isActive
    ? {
        borderTop: `7px solid ${theme.token?.color?.neutral.text.weak[' '].value}`,
      }
    : {}),
}));

export const StyledTypography = styled(Typography)<{ isActive?: boolean }>(({ theme, isActive }) => ({
  ...(isActive ? { color: theme.token.color.neutral.text.weak[' '].value } : {}),
}));
