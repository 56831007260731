import React from 'react';
import ReactDOM from 'react-dom';
import { Cluster, Renderer } from '@googlemaps/markerclusterer';
import { StyledMarker } from './styles';

// workaround to get custom cluster markers
const useClusterMarker = (): Renderer => {
  return {
    render: ({ markers, position, count }: Cluster) => {
      const clusterMarkerElement = document.createElement('div');

      // this isn;t ideal but enabling react component is challenging.  also createRoot is not available in react 17
      ReactDOM.render(<StyledMarker markers={markers} />, clusterMarkerElement);
      return new google.maps.marker.AdvancedMarkerElement({
        position: {
          lat: position.lat(),
          lng: position.lng(),
        },
        content: clusterMarkerElement,
        // adjust zIndex to be above other markers
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
      });
    },
  };
};

export default useClusterMarker;
