import { Box } from '@mui/material';
import { styled } from '@fctg-ds/theme';

export const StyledRoomOptionsLabel = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const StyledRoomOptionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexGrow: 1,
  borderRadius: theme.spacing(4),
  height: '100%',
}));

export const StyledTopRoomOptionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(4),
}));

export const StyledHeaderRoomOptionContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const StyledBottomRoomOptionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  alignItems: 'normal',
  gap: 0,
  width: '100%',
  marginTop: `-${theme.spacing(1)}`,
  justifyContent: 'flex-end',
}));

export const StyledBottomRoomOptionInner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',
  padding: `${theme.spacing(1)} ${theme.spacing(4)} ${theme.spacing(4)}`,
  [theme.breakpoints.only('xs')]: {
    padding: theme.spacing(2.5),
  },
}));
