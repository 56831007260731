export type ExampleReducerState = { name: string };

const exampleReducer = (state: ExampleReducerState = { name: '' }, action) => {
  switch (action.type) {
    case 'example/name':
      return { name: action.name };
    default:
      return state;
  }
};

export default exampleReducer;
