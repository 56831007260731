import React from 'react';
import { Modal } from '@fctg-ds/core';
import UserRating from '../user-rating/main';
import { GuestReviewModalProps } from './types';
import GuestReviewTile from '../guest-review-tile/main';
import {
  StyledModal,
  StyledModalTopBox,
  StyledModalInner,
  StyledModalBottomBox,
  StyledModalCategoryScoresBox,
  StyledModalUserReviewsBox,
} from './styles';
import CategoryScore from '../guest-reviews/components/category-score';
import ScoreBoxGridItem from './components/score-box-grid-item/main';
import { isValidCategoryScore } from '@src/helpers/isValidCategoryScore/isValidCategoryScore';
import SortChip from '../sort-chip/main';
import { GUEST_REVIEWS_SORT_OPTIONS } from '@src/common/sort-chip/constants';
import { fetchGuestReviewsLabelByOption } from '@src/helpers/fetchSortByLabel/fetchGuestReviewsLabelByOption';

const GuestReviewsModal = ({
  guestReviewsSummary,
  guestReviews,
  isMobile,
  open,
  handleShowGuestReviews,
  sortValue,
  onSort,
  onEmitEvent,
}: GuestReviewModalProps) => {
  const { count, overall, superlative, cleanliness, service, condition, amenities } = guestReviewsSummary;

  const onClose = () => {
    handleShowGuestReviews();
    onEmitEvent &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: 'Guest Reviews',
          interactionLabel: 'closeModal',
        },
      });
  };

  return (
    <Modal
      open={open}
      title="Guest reviews"
      onClose={onClose}
      showClose
      slideUp
      maxHeight={'80%'}
      maxWidth={'lg'}
      fullScreen={isMobile}
      noContentPadding
      data-testid="guest-reviews-modal"
    >
      <StyledModal>
        <StyledModalInner data-testid="guest-reviews-modal-inner">
          <StyledModalTopBox>
            <UserRating count={count} overall={overall} superlative={superlative} size={'sm'} />
          </StyledModalTopBox>
          <StyledModalBottomBox>
            <StyledModalCategoryScoresBox container data-testid="guest-reviews-modal-category-score">
              {isValidCategoryScore(service) && (
                <ScoreBoxGridItem>
                  <CategoryScore category={'service'} score={service} />
                </ScoreBoxGridItem>
              )}
              {isValidCategoryScore(cleanliness) && (
                <ScoreBoxGridItem>
                  <CategoryScore category={'cleanliness'} score={cleanliness} />
                </ScoreBoxGridItem>
              )}
              {isValidCategoryScore(amenities) && (
                <ScoreBoxGridItem>
                  <CategoryScore category={'amenities'} score={amenities} />
                </ScoreBoxGridItem>
              )}
              {isValidCategoryScore(condition) && (
                <ScoreBoxGridItem>
                  <CategoryScore category={'condition'} score={condition} />
                </ScoreBoxGridItem>
              )}
            </StyledModalCategoryScoresBox>
            <SortChip
              sortValue={sortValue}
              sortOptions={GUEST_REVIEWS_SORT_OPTIONS}
              handleOnSelect={onSort}
              fetchLabelByOption={fetchGuestReviewsLabelByOption}
              onEmitEvent={onEmitEvent}
              eventType="guestReviews"
            />
            <StyledModalUserReviewsBox>
              {guestReviews.map((guestReview, index) => (
                <GuestReviewTile key={`guest-review-${index}`} {...guestReview} isMobile={isMobile} />
              ))}
            </StyledModalUserReviewsBox>
          </StyledModalBottomBox>
        </StyledModalInner>
      </StyledModal>
    </Modal>
  );
};

export default GuestReviewsModal;
