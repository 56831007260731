import { ChipTab } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

export const StyledChipBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const StyledChip = styled(ChipTab)(({ theme }) => ({
  textAlign: 'center',
  width: '50%',
  minHeight: '40px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  '& .MuiBox-root': {
    width: '100%',
  },
}));
