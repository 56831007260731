import { Box, Typography } from '@mui/material';
import { styled } from '@fctg-ds/theme';
import Info from '@fctg-ds/icons/Info';
import { RoomOptionProps } from '../../types';

export const StyledRefundType = styled(Box)<{
  refundable: boolean;
  roomOptionVariant: RoomOptionProps['roomOptionVariant'];
}>(({ refundable, roomOptionVariant, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  justifyContent: 'center',
  background: refundable
    ? theme.token.color.upsell.background.medium[' '].value
    : theme.token.color.neutral.background.medium[' '].value,
  borderBottom: `${theme.spacing(0.25)} solid ${refundable ? theme.token.color.upsell.background.medium.hovered.value : theme.token.color.neutral.border[' '].value}`,
  borderTopLeftRadius: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1),
  alignItems: 'center',
  height: '100%',
  padding: theme.spacing(1),
  cursor: 'pointer',
  textAlign: 'center',
  maxHeight: 'none',
  [theme.breakpoints.up('sm')]: {
    maxHeight: theme.spacing(7),
  },
  [theme.breakpoints.between(320, 330)]: {
    gap: roomOptionVariant === 'roomOption' && 0,
  },
  [theme.breakpoints.between('md', 620)]: {
    gap: roomOptionVariant === 'roomTile' && 0,
  },
  [theme.breakpoints.between('lg', 910)]: {
    gap: roomOptionVariant === 'roomTile' && 0,
  },
}));

export const StyledRefundText = styled(Typography)<{
  refundable: boolean;
  roomOptionVariant: RoomOptionProps['roomOptionVariant'];
}>(({ refundable, roomOptionVariant, theme }) => ({
  color: refundable && theme.token.color.upsell.text.medium[' '].value,
  fontSize: theme.spacing(3.5),
  [theme.breakpoints.up('xs')]: {
    fontSize: roomOptionVariant === 'roomTile' ? theme.spacing(3.25) : theme.spacing(3),
  },
  [theme.breakpoints.up(332)]: {
    fontSize: roomOptionVariant === 'roomTile' && theme.spacing(3.5),
  },
  [theme.breakpoints.up(338)]: {
    fontSize: roomOptionVariant === 'roomOption' && theme.spacing(3),
  },
  [theme.breakpoints.up(352)]: {
    fontSize: roomOptionVariant === 'roomOption' && theme.spacing(3.25),
  },
  [theme.breakpoints.up(366)]: {
    fontSize: roomOptionVariant === 'roomOption' && theme.spacing(3.5),
  },
  [theme.breakpoints.up('md')]: {
    fontSize: theme.spacing(3),
  },
  [theme.breakpoints.up(642)]: {
    fontSize: theme.spacing(3.25),
  },
  [theme.breakpoints.up(672)]: {
    fontSize: theme.spacing(3.5),
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.spacing(3),
  },
  [theme.breakpoints.up(956)]: {
    fontSize: roomOptionVariant === 'roomTile' && theme.spacing(3.25),
  },
  [theme.breakpoints.up(994)]: {
    fontSize: roomOptionVariant === 'roomTile' && theme.spacing(3.5),
  },
}));

export const StyledInfoIcon = styled(Info)<{ refundable: boolean }>(({ refundable, theme }) => ({
  height: 'auto',
  color: refundable && theme.token.color.upsell.text.medium[' '].value,
  width: theme.spacing(4),
  [theme.breakpoints.between(320, 330)]: {
    width: theme.spacing(3.25),
  },
  [theme.breakpoints.between('md', 610)]: {
    width: theme.spacing(3.25),
  },
}));

export const StyledPopoverContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  width: theme.spacing(126),
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const StyledPopoverContent = styled(Typography)<{ highlightLastLine: boolean }>(
  ({ highlightLastLine, theme }) => ({
    marginBottom: theme.spacing(4),
    '&:last-child': {
      fontWeight: highlightLastLine ? 500 : 400,
      marginBottom: theme.spacing(0),
    },
  }),
);
