import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';
import Add from '@fctg-ds/icons/Add';
import { Divider } from '@fctg-ds/core';

export const StyledPackageDetails = styled(Box)(({ theme }) => ({
  backgroundColor: theme.token.color.neutral.background.weak[' '].value,
  border: `1px solid ${theme.token.color.black[' '].value}`,
  borderRadius: theme.token.radius.lg.value,
  padding: `${theme.spacing(6)} ${theme.spacing(8)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: `${theme.spacing(2.5)} ${theme.spacing(10)}`,
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    padding: `${theme.spacing(6)} ${theme.spacing(8)}`,
    gap: `${theme.spacing(2.5)} ${theme.spacing(10)}`,
    alignItems: 'center',
  },
}));

export const StyledPackageDetailsInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

export const StyledPackageDetailsContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

export const StyledAddIcon = styled(Add)(() => ({
  width: 16,
  height: 16,
}));

export const StyledDivider = styled(Divider)(() => ({
  margin: 0,
}));

export const StyledTravellersBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));
