import React from 'react';
import { MapFilterHotel } from '../markers/main';
import { useMapProductTile } from '../product-tile/main';
import { StyledDipInner, StyledDipOuter, StyledMarker, StyledTypography } from './styles';
import { formattedCurrency } from '@src/helpers/formattedCurrency';

interface MapFilterMarkerProps {
  hotel: MapFilterHotel;
}

const MapFilterMarker = ({ hotel }: MapFilterMarkerProps) => {
  const { selectedHotel } = useMapProductTile();
  const isActive = selectedHotel && hotel.hotelId === selectedHotel?.hotelId;
  const isSoldOut = !hotel.available;

  const getLabel = () => {
    if (hotel.available) return formattedCurrency(hotel.price.total, hotel.price.currency, true);
    return 'Sold out';
  };

  return (
    <StyledMarker isActive={isActive} isSoldOut={isSoldOut}>
      <StyledDipInner isActive={isActive} isSoldOut={isSoldOut} />
      <StyledDipOuter isActive={isActive} isSoldOut={isSoldOut} />
      <StyledTypography isActive={isSoldOut && !isActive} variant="priceSm">
        {getLabel()}
      </StyledTypography>
    </StyledMarker>
  );
};

export default MapFilterMarker;
