import { styled } from '@fctg-ds/theme';
import { Box, Grid, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import React from 'react';

export const StyledListItemBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'center',
}));

export const StyledAllAmenitiesBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
}));

export const StyledListIcon = styled(FiberManualRecordIcon)(({ theme }) => ({
  fontSize: theme.spacing(1.5),
}));

interface AllAmenitiesProps {
  all: string[];
}

export const AllAmenities = ({ all }: AllAmenitiesProps) => {
  return (
    <StyledAllAmenitiesBox data-testid="AllHotelAmenitiesModalDialogList">
      <Grid container spacing={1} data-testid="AllHotelAmenitiesListModalDialogGrid">
        {all.map((item) => (
          <Grid item xs={12} md={6} data-testid="AllHotelAmenitiesListModalDialogGridItem">
            <StyledListItemBox>
              <StyledListIcon data-testid="AllHotelAmenitiesListModalDialogGridItemIcon" />
              <Typography variant="bodySm" data-testid="AllHotelAmenitiesListModalDialogGridItemText">
                {item}
              </Typography>
            </StyledListItemBox>
          </Grid>
        ))}
      </Grid>
    </StyledAllAmenitiesBox>
  );
};
