import React from 'react';
import { Grid } from '@mui/material';
import { TopAmenity } from '../../../../../top-hotel-amenities/types';
import AmenityItem from '../amenity-item/main';

interface TopHotelAmenitiesGridProps {
  top: TopAmenity[];
}

const MAX_AMENITIES = 9;

const TopRoomDetailsAmenitiesGrid = ({ top }: TopHotelAmenitiesGridProps) => {
  const topAmenities = top.slice(0, MAX_AMENITIES);
  return (
    <Grid data-testid="room-details-modal-top-amenities-grid" container spacing={4}>
      {topAmenities.map((topAmenity) => (
        <Grid item xs={12} md={4}>
          <AmenityItem data-testid="amenity-item" topAmenity={topAmenity} />
        </Grid>
      ))}
    </Grid>
  );
};

export default TopRoomDetailsAmenitiesGrid;
