import { Typography } from '@mui/material';
import { Box, BoxProps, Stack } from '@mui/system';
import React from 'react';
import { Link } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import DestinationTileImage from './components/image';
import { Destination } from './types';

export interface DestinationTileProps {
  destination: Destination;
  subText: string;
  imageHeight?: number;
  handleSelectTile?: (tile: Destination) => () => void;
}

export interface TileProps extends BoxProps {
  children: React.ReactNode;
  url: string;
  title: string;
}
export interface TileContentProps extends BoxProps {
  children: React.ReactNode;
}

const Tile = ({ children, url, title, ...rest }: TileProps) => {
  return (
    <Link
      href={url}
      title={title}
      target="_blank"
      underline="none"
      sx={{ display: 'block', width: 'auto', '&:hover': { color: 'initial' } }}
    >
      <Box display="flex" flexDirection="column" gap={4} data-testid="tile-component" {...rest}>
        {children}
      </Box>
    </Link>
  );
};

const TileContent = ({ children, ...args }: TileContentProps) => {
  return (
    <Box display="flex" flexDirection="column" {...args}>
      {children}
    </Box>
  );
};

const TileName = styled(Typography)(({ theme }) => ({
  fontSize: theme.token.typography.size.md.value,
  fontWeight: theme.token.typography.weight.medium.value,
  color: theme.token.color.black[' '].value,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const DestinationTile = ({ destination, subText, imageHeight = 160 }: DestinationTileProps) => {
  return (
    <Tile url={destination.destinationUrl} title={destination.destination.city}>
      <DestinationTileImage imageHeight={imageHeight} imageUrl={destination.imageUrl} />
      <TileContent sx={{ gap: 2 }}>
        <Stack direction="row" spacing={1}>
          <TileName data-testid="tile-title">{destination.destination.city}</TileName>
        </Stack>

        <Box display="flex">
          <Typography
            variant="caption"
            sx={(theme) => ({
              color: theme.token.color.text.weak[' '].value,
            })}
          >
            {subText}
          </Typography>
        </Box>
      </TileContent>
    </Tile>
  );
};

export default DestinationTile;
