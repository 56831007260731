import React from 'react';
import { Typography, Icon, useTheme } from '@mui/material';
import { getAmenityIcon } from '@src/helpers/getAmenityIcon/getAmenityIcon';
import { Amenities } from '@src/components/top-hotel-amenities/types';
import { StyledAmenities, StyledAmenityItem, StyledAmenitiesLink, StyledTypography } from './styles';
import { getTopAmenities } from '@src/helpers/getTopAmenities/getTopAmenities';

interface TopRoomAmenitiesProps {
  amenities: Amenities;
  roomId: string;
  handleShowRoomDetails: (roomId: string) => void;
}

const TopRoomAmenities = ({ amenities, roomId, handleShowRoomDetails }: TopRoomAmenitiesProps) => {
  const theme = useTheme();

  const topAmenitiesToShow = getTopAmenities(amenities, 4);

  const onClick = () => {
    if (handleShowRoomDetails) handleShowRoomDetails(roomId);
  };

  return (
    <StyledAmenities data-testid="room-tile-amenities">
      {topAmenitiesToShow &&
        topAmenitiesToShow.map((topAmenity, index) => (
          <StyledAmenityItem key={`amenity-item-${index}`} data-testid="room-tile-amenity-item">
            <Icon
              component={getAmenityIcon(topAmenity)}
              sx={{ fontSize: theme.spacing(5) }}
              data-testid={'room-tile-amenity-icon'}
            />
            <Typography variant="bodySm">{topAmenity.name}</Typography>
          </StyledAmenityItem>
        ))}
      <StyledAmenitiesLink>
        <StyledTypography variant="bodySm" onClick={onClick}>
          More room details
        </StyledTypography>
      </StyledAmenitiesLink>
    </StyledAmenities>
  );
};

export default TopRoomAmenities;
