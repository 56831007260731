import { format, parse, isValid } from 'date-fns';

/**
 * Helper function to return a hotel's check in and check out times in AM/PM format
 *
 * @param time String input in a 24-hour "hh:mm" format.
 * @returns String value with AM/PM, such as "2 pm" or "9:30 am"
 */
export const formatCheckInOutTime = (time: string): string => {
  if (!time) return null;

  const parsedDate = parse(time, `HH:mm`, new Date());

  return isValid(parsedDate) ? format(parsedDate, `h${parsedDate.getMinutes() > 0 ? `:mm` : ''} aaa`) : time;
};
