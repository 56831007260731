import { Box, Typography } from '@mui/material';
import { styled } from '@fctg-ds/theme';
import Stay from '@fctg-ds/icons/Stay';
import SizeSqm from '@fctg-ds/icons/SizeSqm';
import PersonIcon from '@mui/icons-material/Person';

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  flexDirection: 'column',
  paddingBottom: theme.spacing(5),
  borderBottom: `${theme.spacing(0.25)} solid ${theme.token.color.neutral.border[' '].value}`,
}));

export const StyledHeaderInner = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  flexDirection: 'row',
  flexWrap: 'wrap',
}));

export const StyledHeaderInnerContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'row',
  alignItems: 'flex-start',
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.token.color.neutral.text.weak[' '].value,
  marginTop: theme.spacing(0.5),
}));

export const StyledStayIcon = styled(Stay)(({ theme }) => ({
  color: theme.token.color.neutral.icon.weak[' '].value,
  width: theme.spacing(5.5),
  height: 'auto',
}));

export const StyledSizeSqmIcon = styled(SizeSqm)(({ theme }) => ({
  color: theme.token.color.neutral.icon.weak[' '].value,
  width: theme.spacing(5.5),
  height: 'auto',
}));

export const StyledPersonIcon = styled(PersonIcon)(({ theme }) => ({
  color: theme.token.color.neutral.icon.weak[' '].value,
  width: theme.spacing(5.5),
  height: 'auto',
}));
export const StyledSup = styled('sup')(({ theme }) => ({
  position: 'relative',
  verticalAlign: 'top',
  top: `-${theme.spacing(0.5)}`,
}));
