import React from 'react';
import { Icon, useTheme } from '@mui/material';
import ConciergeIcon from '@src/assets/icons/ConciergeIcon';
import CurrencyDollar from '@fctg-ds/icons/CurrencyDollar';
import Gift from '@fctg-ds/icons/Gift';
import Info from '@fctg-ds/icons/Info';
import CreditCard from '@fctg-ds/icons/CreditCard';
import {
  StyledPoliciesSectionHeader,
  StyledPoliciesSectionHeaderIconSection,
  StyledPoliciesSectionHeaderIconBox,
  StyledPoliciesSectionHeaderIconTitle,
  StyledPoliciesSectionHeaderContentSection,
  StyledPoliciesSectionHeaderContentTitle,
} from './styles';
import { ContainerType } from '../../types';
import { capitaliseWord } from '@src/helpers/capitaliseWord/capitaliseWord';

interface SectionHeaderProps {
  title: string;
  type: ContainerType;
  children: React.ReactNode;
}

const HEADER_ICONS = [
  {
    type: 'check',
    icon: ConciergeIcon,
  },
  {
    type: 'fees',
    icon: CurrencyDollar,
  },
  {
    type: 'optional',
    icon: Gift,
  },
  {
    type: 'know',
    icon: Info,
  },
  {
    type: 'payment',
    icon: CreditCard,
  },
];

const SectionHeader = ({ title, type, children }: SectionHeaderProps) => {
  const theme = useTheme();
  const capitalizedType = capitaliseWord(type);

  return (
    <StyledPoliciesSectionHeader data-testid={`policiesSectionHeader-${capitalizedType}`}>
      <StyledPoliciesSectionHeaderIconSection data-testid={`policiesSectionHeaderIconSection-${capitalizedType}`}>
        <StyledPoliciesSectionHeaderIconBox data-testid={`policiesSectionHeaderIconBox-${capitalizedType}`}>
          <Icon
            component={HEADER_ICONS.find((icon) => icon.type === type)?.icon}
            sx={{
              fontSize: theme.spacing(6),
              color: theme.token.color.neutral.icon.weak[' '].value,
              svg: {
                fill: theme.token.color.neutral.icon.weak[' '].value,
              },
            }}
            data-testid={`policiesSectionHeaderIcon-${capitalizedType}`}
          />
        </StyledPoliciesSectionHeaderIconBox>
        <StyledPoliciesSectionHeaderIconTitle
          variant="bodyLgStrong"
          data-testid={`policiesSectionHeaderTitleBox-${capitalizedType}`}
        >
          {title}
        </StyledPoliciesSectionHeaderIconTitle>
      </StyledPoliciesSectionHeaderIconSection>
      <StyledPoliciesSectionHeaderContentSection data-testid={`policiesSectionHeaderContentArea-${capitalizedType}`}>
        <StyledPoliciesSectionHeaderContentTitle
          variant="bodyLgStrong"
          data-testid={`policiesSectionHeaderContentAreaTitle-${capitalizedType}`}
        >
          {title}
        </StyledPoliciesSectionHeaderContentTitle>
        {children}
      </StyledPoliciesSectionHeaderContentSection>
    </StyledPoliciesSectionHeader>
  );
};

export default SectionHeader;
