import React, { ReactNode } from 'react';
import { styled } from '@fctg-ds/theme';
import { Grid } from '@mui/material';

const StyledGuestReviewScoreBoxGridItem = styled(Grid)(({ theme }) => ({
  flexBasis: '100%',
  [theme.breakpoints.up('md')]: {
    flexBasis: '46.5%',
  },
  [theme.breakpoints.up('lg')]: {
    flexBasis: '48%',
  },
}));

const ScoreBoxGridItem = ({ children }: { children: ReactNode | ReactNode[] }) => {
  return (
    <StyledGuestReviewScoreBoxGridItem xs={12} md={6} lg={12} item>
      {children}
    </StyledGuestReviewScoreBoxGridItem>
  );
};

export default ScoreBoxGridItem;
