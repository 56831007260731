import { Modal } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';

export const StyledModal = styled(Modal)(({ theme }) => ({
  '& .MuiTypography-alignCenter': {
    ...theme.typography.headingSm,
  },
}));

export const StyledModalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(1),
  paddingBottom: theme.spacing(4),
}));

export const StyledBoxGrid = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(4)}`,
}));

export const StyledSelectText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  padding: `${theme.spacing(1)} ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
}));

export const StyledStaysSelectText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  padding: `${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(6)} ${theme.spacing(4)}`,
}));

export const StyledDesktopHeaderBox = styled(Box)(({ theme }) => ({
  top: 0,
  width: '100%',
  position: 'sticky',
  backgroundColor: theme.token.color.neutral.background.weak[' '].value,
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
  zIndex: 10,
}));
