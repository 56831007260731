import { Box } from '@mui/material';
import { Dialog } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const StyledSortDisclaimerMainBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  color: theme.token.color.neutral.text.weak[' '].value,
}));

export const StyledInfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  fontSize: theme.spacing(4),
  ':hover': {
    cursor: 'pointer',
  },
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  minWidth: '100%',
  [theme.breakpoints.up('md')]: {
    minWidth: '483px',
  },
  '& .MuiStack-root': {
    maxHeight: 'fit-content',
  },
}));
