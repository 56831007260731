import { SelectionDate } from '@src/components/calendar-date-picker/types';
import { format, isValid } from 'date-fns';

export const toStringDate = (date: SelectionDate): string | null => {
  if (!date || !date.day) return null;

  const dateFormat = new Date(date.year, date.month - 1, date.day);

  if (!isValid(dateFormat)) return null;

  return format(dateFormat, 'yyyy-MM-dd');
};
