import Checklist from '@components/checklist';
import React from 'react';
import { FilterBarMetaData, FilterBarProps } from '@components/filter-bar';
import { EmitEventProps } from '@src/common/types/types';

export type AreaFilterProps = {
  selected: FilterBarProps['activeProps']['area'];
  onChange: FilterBarProps['onChangeEvents']['onChangeArea'];
  areas: FilterBarMetaData['area'];
  onEmitEvent?: (event: EmitEventProps) => void;
};

const AreaFilter = ({ selected, onChange, areas, onEmitEvent }: AreaFilterProps) => {
  return (
    <Checklist selected={selected || []} options={areas || []} handleChange={onChange} onEmitEvent={onEmitEvent} />
  );
};

export default AreaFilter;
