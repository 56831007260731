import React from 'react';
import { useTheme } from '@mui/material';
import { DesktopSortOptionsListProps } from '../types';
import { Menu } from '@fctg-ds/core';
import SortOptionsList from '../sort-option-list/main';

const DesktopSortOptionsList = ({
  open,
  sortValue,
  anchorEl,
  handleOnClose,
  handleOnSelect,
  onEmitEvent,
  eventType,
  sortOptions,
  fetchLabelByOption,
}: DesktopSortOptionsListProps) => {
  const theme = useTheme();

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={handleOnClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          width: theme.spacing(90.5),
        },
      }}
    >
      <SortOptionsList
        sortValue={sortValue}
        sortOptions={sortOptions}
        handleOnClose={handleOnClose}
        handleOnSelect={handleOnSelect}
        onEmitEvent={onEmitEvent}
        eventType={eventType}
        fetchLabelByOption={fetchLabelByOption}
      />
    </Menu>
  );
};

export default DesktopSortOptionsList;
