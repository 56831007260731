import { Box, Typography } from '@mui/material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { styled } from '@fctg-ds/theme';
import { UrgencyIndicatorProps } from './main';

export const StyledUrgencyIndicatorBox = styled(Box)<{ variant: UrgencyIndicatorProps['variant'] }>(
  ({ variant = 'stays', theme }) => ({
    display: 'flex',
    width: '100%',
    maxWidth: variant === 'packages' ? '60%' : 'none',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    color: theme.token.color.warning[' '].value,
    justifyContent: variant === 'packages' ? 'flex-end' : 'flex-start',
    overflow: 'hidden',
    flexWrap: 'wrap',
    marginBottom: variant === 'packages' ? theme.spacing(3) : 'none',
  }),
);

export const StyledAccessAlarmIcon = styled(AccessAlarmIcon)(({ theme }) => ({
  fontSize: theme.spacing(4),
}));

export const StyledUrgencyText = styled(Typography)(() => ({
  letterSpacing: 'normal',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '80%',
}));
