import React, { useEffect, useRef } from 'react';
import { AdvancedMarker, useMap } from '@vis.gl/react-google-maps';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import MapFilterMarker from '@components/map-filter/components/marker';
import useClusterMarker from '@components/map-filter/components/cluster-marker';
import { MapFilterProps } from '../..';
import { useMapProductTile } from '../product-tile/main';
import { EmitEventProps } from '@src/common/types/types';

const CLUSTER_RADIUS = 125;

interface MapFilterMarkersProps {
  productTileData: MapFilterProps['productTileData'];
  onEmitEvent?: (event: EmitEventProps) => void;
}

interface HotelPrice {
  total: number;
  currency?: string;
  originalPrice?: number;
  amountSaved?: number;
  percentageSaved?: number;
}

export interface MapFilterHotel {
  available: boolean;
  hotelId: string;
  price: HotelPrice;
  position: {
    lat: number;
    lng: number;
  };
}

const MapFilterMarkers = ({ productTileData, onEmitEvent }: MapFilterMarkersProps) => {
  const { onMarkerClick, selectedHotel } = useMapProductTile();
  const map = useMap();
  const markerRefs = useRef<google.maps.marker.AdvancedMarkerElement[]>([]);
  const clusterer = useRef<MarkerClusterer | null>(null);
  const renderer = useClusterMarker();
  const lastzIndex = markerRefs?.current[markerRefs?.current?.length - 1]?.zIndex;

  // Initialize MarkerClusterer
  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({
        map,
        renderer,
        algorithm: new SuperClusterAlgorithm({ radius: CLUSTER_RADIUS }),
      });
    }
  }, [map]);

  // Update markers
  useEffect(() => {
    if (markerRefs?.current?.length > 0) {
      clusterer.current?.clearMarkers(true);
      clusterer.current?.addMarkers(markerRefs?.current, false);
    }
  }, [markerRefs?.current?.length]);

  const getMarkersData = (): MapFilterHotel[] =>
    productTileData
      .filter((hotel) => hotel?.geoLocation?.lon && hotel?.geoLocation?.lat)
      .map((hotel) => ({
        hotelId: hotel.hotelId,
        price: hotel.price,
        position: {
          lat: parseFloat(hotel.geoLocation.lat),
          lng: parseFloat(hotel.geoLocation.lon),
        },
        available: hotel.available,
        name: hotel.name,
      }));

  return (
    <>
      {getMarkersData().map((hotel, index) => (
        <AdvancedMarker
          gmpClickable
          key={hotel.hotelId}
          position={hotel.position}
          collisionBehavior={google.maps.CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL}
          zIndex={
            hotel?.hotelId === selectedHotel?.hotelId && lastzIndex
              ? Number(google.maps.Marker.MAX_ZINDEX) + lastzIndex + 1
              : index
          }
          onClick={() => {
            onMarkerClick(hotel.hotelId);
            onEmitEvent({
              event: 'trackInteraction',
              data: {
                interactionValue: 'mapFilter',
                interactionLabel: 'priceSelect',
              },
            });
          }}
          ref={(marker) => {
            if (marker) {
              marker.zIndex = index;
              markerRefs.current[index] = marker;
            }
          }}
        >
          <MapFilterMarker hotel={hotel} />
        </AdvancedMarker>
      ))}
    </>
  );
};

export default MapFilterMarkers;
