import React from 'react';
import { Dialog } from '@fctg-ds/core';
import { ChildAgesSelectorBox, StyledBox, StyledChildAgesSelectorTypography, StyledOuterBox } from './styles';
import { PaxCountChanger } from '../pax-count-changer/main';
import { ChildAgeSelector } from '../child-age-selector/main';
import { ErrorState } from './types';

interface PaxCountProps {
  adult: number;
  childAges: number[];
  infant: number;
  handleAdultChange: (newAdultCount: number) => void;
  handleChildAgesChange: (newChildrenCount: number) => void;
  handleInfantChange: (newInfantCount: number) => void;
  isChildAgesValid: boolean;
  onSelectChildAge: (index: number, value: number) => void;
  error: ErrorState;
}

const PaxCount = ({
  adult,
  childAges,
  infant,
  handleAdultChange,
  handleChildAgesChange,
  handleInfantChange,
  isChildAgesValid,
  onSelectChildAge,
  error,
}: PaxCountProps) => {
  return (
    <>
      <StyledOuterBox data-testid="pax-count">
        <StyledBox>
          <PaxCountChanger
            data-testid="pax-count-changer-adult"
            title="Adults"
            value={adult}
            min={1}
            onChange={(newAdultCount) => handleAdultChange(newAdultCount)}
          />
          <PaxCountChanger
            data-testid="pax-count-changer-child"
            title="Children"
            value={childAges.length}
            subTitle={'2 - 17 years'}
            onChange={(newChildrenCount) => handleChildAgesChange(newChildrenCount)}
          />
          <PaxCountChanger
            data-testid="pax-count-changer-infant"
            title="Infants"
            value={infant}
            subTitle="under 2 years"
            onChange={(newInfantCount) => handleInfantChange(newInfantCount)}
          />
        </StyledBox>
        {childAges.length > 0 && (
          <ChildAgesSelectorBox>
            <ChildAgeSelector showError={!isChildAgesValid} childAges={childAges} onChange={onSelectChildAge} />
            <StyledChildAgesSelectorTypography>
              Select child's age at time of return travel
            </StyledChildAgesSelectorTypography>
          </ChildAgesSelectorBox>
        )}
      </StyledOuterBox>
      <Dialog
        data-testid="travellers-selector-error-dialog"
        open={!!error}
        onClose={error?.defaultAction}
        title={error?.title || ''}
        options={[...(error?.options || [])]}
      >
        {error?.message}
      </Dialog>
    </>
  );
};

export default PaxCount;
