import React from 'react';
import { Typography } from '@mui/material';
import { StyledGuestReviewTileBox, StyledGuestReviewTileHeader } from './styles';
import Reviewer from './components/reviewer/main';
import Score from './components/score/main';
import Source from './components/source/main';
import { parseISO, format } from 'date-fns';
import { getSuperlative } from '@src/helpers/getSuperlative/getSuperlative';
import { GuestReview } from '../guest-reviews/types';

const GuestReviewTile = (props: GuestReview) => {
  const { date_submitted, rating, reviewer_name, text, superlative, isMobile } = props;

  return (
    <StyledGuestReviewTileBox data-testid="guest-review-tile">
      <StyledGuestReviewTileHeader data-testid="guest-review-tile-header" isMobile={isMobile}>
        <Reviewer name={reviewer_name} date={formatDate(date_submitted)} />
        <Score rating={rating} superlative={getSuperlative(superlative)} />
      </StyledGuestReviewTileHeader>
      <Typography variant="bodySm" data-testid="guest-review-tile-text">
        {text.trim()}
      </Typography>
      <Source verification_source={props.verification_source} />
    </StyledGuestReviewTileBox>
  );
};

const formatDate = (date: string) => {
  return format(parseISO(date), 'MMM yyyy');
};

export default GuestReviewTile;
