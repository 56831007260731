import React from 'react';
import { StyledUrgencyIndicatorBox, StyledAccessAlarmIcon, StyledUrgencyText } from './styles';

export interface UrgencyIndicatorProps {
  variant?: 'stays' | 'packages';
}

const UrgencyIndicator = ({ variant = 'stays' }: UrgencyIndicatorProps) => {
  const message = 'Hurry, booking fast';

  return (
    <StyledUrgencyIndicatorBox data-testid="urgencyIndicator" variant={variant}>
      <StyledAccessAlarmIcon data-testid="urgencyIndicatorIcon" />
      <StyledUrgencyText data-testid="urgencyIndicatorText" variant="captionMdStrong">
        {message}
      </StyledUrgencyText>
    </StyledUrgencyIndicatorBox>
  );
};

export default UrgencyIndicator;
