import React, { useEffect, useState } from 'react';
import CalendarDatePicker from '@src/components/calendar-date-picker/main';
import CalendarFooter from '@src/components/calendar-footer/main';
import ErrorDialog from '@src/components/error-dialog/main';
import DateSelection from './components/date-selection/main';
import ClearButton from './components/clear-button/main';
import DoneButton from './components/done-button/main';
import { hasDatePassed } from '@src/helpers/hasDatePassed/hasDatePassed';
import { isWithinMaxStayDuration } from '@src/helpers/isWithinMaxStayDuration/isWithinMaxStayDuration';
import { toSelectionDate } from '@src/helpers/toSelectionDate/toSelectionDate';
import { toStringDate } from '@src/helpers/toStringDate/toStringDate';
import { isSameDatesSelected } from '@src/helpers/isSameDatesSelected/isSameDatesSelected';
import { EditSearchDatePickerProps, Selection } from './types';
import { SelectionDate } from '@src/components/calendar-date-picker/types';
import { DatePickerPopover, DatePickerContent, FooterStyle, FooterButtons } from './styles';

const EditSearchDatePicker = ({
  open,
  dates,
  inputSelection,
  handleClose,
  setNewCheckInOutDates,
  inputRef,
}: EditSearchDatePickerProps) => {
  const CUSTOM_ANCHOR_ADJUSTMENT = -16;
  const [anchorElement, setAnchorElement] = React.useState(null);
  const [startDate, setStartDate] = useState<SelectionDate | null>(toSelectionDate(dates?.checkInDate));
  const [endDate, setEndDate] = useState<SelectionDate | null>(toSelectionDate(dates?.checkOutDate));
  const [selection, setSelection] = useState<Selection>(inputSelection || null);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  useEffect(() => {
    setStartDate(toSelectionDate(dates?.checkInDate));
    setEndDate(toSelectionDate(dates?.checkOutDate));
    setSelection(inputSelection || null);
    setAnchorElement(inputRef);
  }, [open]);

  const handleSelectedDates = (newSelectionDate) => {
    switch (selection) {
      case 'start':
        setStartDate(newSelectionDate);
        setSelection('end');
        setEndDate(hasDatePassed(endDate, newSelectionDate) ? null : endDate);
        break;
      case 'end':
        setEndDate(hasDatePassed(newSelectionDate, startDate) ? null : newSelectionDate);
        setSelection(hasDatePassed(newSelectionDate, startDate) ? 'end' : startDate ? null : 'start');
        setStartDate(hasDatePassed(newSelectionDate, startDate) ? newSelectionDate : startDate);
        break;
      default:
        setStartDate(newSelectionDate);
        setEndDate(null);
        setSelection('end');
    }
  };

  const handleSelectionChange = (newSelection: Selection) => {
    setSelection(newSelection);
  };

  const handleDone = () => {
    if (startDate && endDate && !isSameDatesSelected(startDate, endDate)) {
      if (isWithinMaxStayDuration(endDate, startDate)) {
        onClose();
        setNewCheckInOutDates({ checkInDate: toStringDate(startDate), checkOutDate: toStringDate(endDate) });
      } else {
        setShowErrorModal(true);
      }
    }
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    setSelection('start');
  };

  const onClose = () => {
    setAnchorElement(null);
    setSelection(null);
    handleClose();
  };

  return (
    <>
      <DatePickerPopover
        open={open}
        anchorEl={anchorElement}
        onClose={onClose}
        anchorOrigin={{
          vertical: CUSTOM_ANCHOR_ADJUSTMENT,
          horizontal: CUSTOM_ANCHOR_ADJUSTMENT,
        }}
        transitionDuration={0}
        footer={
          <FooterStyle data-testid="edit-search-date-picker-footer">
            <CalendarFooter checkInDate={startDate} checkOutDate={endDate} />
            <FooterButtons>
              {(startDate || endDate) && <ClearButton handleClear={handleClear}>Clear</ClearButton>}
              <DoneButton handleDone={handleDone} dates={{ checkInDate: startDate, checkOutDate: endDate }}>
                Done
              </DoneButton>
            </FooterButtons>
          </FooterStyle>
        }
        footerHeight={74}
        data-testid="edit-search-date-picker"
      >
        <DatePickerContent data-testid="edit-search-date-picker-content">
          <DateSelection
            dates={{
              checkInDate: startDate,
              checkOutDate: endDate,
            }}
            initialSelection={selection}
            handleSelectionChange={handleSelectionChange}
          />
          <CalendarDatePicker
            initialCalendarDates={{ checkInDate: startDate, checkOutDate: endDate }}
            setNewCalendarDates={{ checkInDate: startDate, checkOutDate: endDate }}
            handleSelectedDate={handleSelectedDates}
          />
        </DatePickerContent>
      </DatePickerPopover>
      <ErrorDialog
        errorMessage="Length of stay cannot exceed 28 days. Please modify your date selection."
        open={showErrorModal}
        handleClose={() => {
          setShowErrorModal(false);
        }}
      />
    </>
  );
};

export default EditSearchDatePicker;
