import React from 'react';
import { Box, Typography } from '@mui/material';
import PriceSlider from '@src/components/price-slider';
import PropertyClass from '@src/components/property-class';
import SearchBar from '@src/components/search-bar';
import AreaFilter from '@src/components/area-filter';
import CancellationPolicy from '@src/components/cancellation-policy/main';
import { MobileFilterBarProps } from '../mobile/types';
import { ByGuestReviews } from '@src/components/by-guest-reviews';
import Availability from '@src/components/availability';

export const AllFilters = ({ onChangeEvents, activeProps, metaData, onEmitEvent }: MobileFilterBarProps) => {
  return (
    <Box data-testid="stays-filter-bar-container">
      <Box data-testid="stays-filter-bar-search-container" marginTop={4}>
        <SearchBar
          onChangeHotelName={onChangeEvents.onChangeHotelName}
          hotelName={activeProps.hotelName}
          onEmitEvent={onEmitEvent}
        />
      </Box>
      <Box data-testid="stays-filter-bar-price-container" marginTop={8}>
        <Box>
          <Typography marginBottom={4} variant="headingSm">
            Price
          </Typography>
        </Box>
        <Box maxWidth="100%" padding={4}>
          <PriceSlider
            chartData={metaData.price?.chartData}
            min={metaData.price?.min}
            max={metaData.price?.max}
            onChange={onChangeEvents.onChangePrice}
            value={activeProps.price}
            onEmitEvent={onEmitEvent}
          />
        </Box>
      </Box>
      <Box data-testid="stays-filter-bar-guest-review-container" mt={8} mb={2}>
        <Typography variant="headingSm">Guest review score</Typography>
        <Box display="flex" justifyContent="flex-start" width="100%">
          <ByGuestReviews
            selected={activeProps.reviews}
            onChange={onChangeEvents.onChangeReview}
            onEmitEvent={onEmitEvent}
          />
        </Box>
      </Box>
      <Box data-testid="stays-filter-bar-property-class-container">
        <Typography variant="headingSm" mt={8}>
          Property class
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <PropertyClass
            selected={activeProps.rating}
            onChange={onChangeEvents.onChangeRating}
            mode={'popup'}
            onEmitEvent={onEmitEvent}
          />
        </Box>
      </Box>
      <Box data-testid="stays-filter-bar-area-container" marginTop={8}>
        <Typography variant="headingSm">Area</Typography>
        <AreaFilter
          selected={activeProps.area}
          onChange={onChangeEvents.onChangeArea}
          areas={metaData.area}
          onEmitEvent={onEmitEvent}
        />
      </Box>
      <Box data-testid="stays-filter-bar-payment-container" marginTop={8}>
        <Typography variant="headingSm">Cancellation policy</Typography>
        <CancellationPolicy
          handleChange={onChangeEvents.onChangeRefundable}
          checked={activeProps.refundable}
          count={metaData.refundable?.count}
          onEmitEvent={onEmitEvent}
        />
      </Box>
      <Box data-testid="stays-filter-bar-availability-container" marginTop={8}>
        <Typography variant="headingSm">Availability</Typography>
        <Availability
          checked={activeProps.available}
          count={metaData.available?.count}
          handleChange={onChangeEvents.onChangeAvailable}
          onEmitEvent={onEmitEvent}
        />
      </Box>
    </Box>
  );
};
