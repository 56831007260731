import { useState } from 'react';
import { ErrorStatus } from '../components/error-dialog/main';
import { OptionType } from '@src/components/autocomplete/types';
import { SearchFormChangeEvents } from '../types';
import { AutocompleteOption } from '../types';

const VALID_COUNTRY_CODES = ['AU'];

interface UseErrorDialogProps {
  onChangeEvents: SearchFormChangeEvents;
  origin: AutocompleteOption;
  destination: AutocompleteOption;
  returnDate: string;
  departureDate: string;
  autocompleteOnChange: (value: OptionType) => void;
  autocompleteOnOpen: (mode: 'origin' | 'destination') => void;
}

export const useErrorDialog = ({
  onChangeEvents,
  origin,
  destination,
  returnDate,
  departureDate,
  autocompleteOnChange,
  autocompleteOnOpen,
}: UseErrorDialogProps) => {
  const [errorStatus, setErrorStatus] = useState<ErrorStatus>(null);

  const onValidatedSubmit = () => {
    if (!origin) setErrorStatus('origin');
    else if (!destination) setErrorStatus('destination');
    else if (!returnDate || !departureDate) setErrorStatus('dates');
    else onChangeEvents.onSubmit();
  };

  const onValidatedChange = (value: OptionType, mode: 'origin' | 'destination') => {
    if (mode === 'origin' && !VALID_COUNTRY_CODES.includes(value.countryCode)) {
      setErrorStatus('internationalOrigin');
      return;
    }
    if (mode === 'origin' && destination && value.iataCode === destination.iataCode) {
      setErrorStatus('sameOrigin');
      return;
    }
    if (mode === 'destination' && origin && value.iataCode === origin.iataCode) {
      setErrorStatus('sameOrigin');
      return;
    }
    autocompleteOnChange(value);
  };

  const onClose = () => {
    if (errorStatus === 'internationalOrigin') {
      setErrorStatus(null);
      autocompleteOnOpen('origin');
    } else {
      setErrorStatus(null);
    }
  };

  return {
    errorStatus,
    onValidatedSubmit,
    onValidatedChange,
    onClose,
  };
};
