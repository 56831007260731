import React from 'react';
import { StyledPopover } from './styles';
import PaxCount from '../pax-count/main';
import { TextFieldShineThrough } from '../text-field-shine-through/main';
import { StyledFooter, StyledPopoverContent } from './styles';
import { PrimaryButton } from '@fctg-ds/core';
import { ErrorState } from '../pax-count/types';
import { CabinClass } from '../cabin-class/main';
import { CabinClassType } from '@src/common/types/types';

interface TravellersSelectorDesktopProps {
  open: boolean;
  anchorElement: HTMLInputElement;
  onClose: () => void;
  onDone: () => void;
  adult: number;
  childAges: number[];
  infant: number;
  handleAdultChange: (newAdultCount: number) => void;
  handleChildAgesChange: (newChildrenCount: number) => void;
  handleInfantChange: (newInfantCount: number) => void;
  handleCabinClass: (value: CabinClassType) => void;
  isChildAgesValid: boolean;
  onSelectChildAge: (index: number, value: number) => void;
  error: ErrorState;
  cabinClass: CabinClassType;
  inputComponent?: React.ReactNode;
}

const TravellersSelectorDesktop = ({
  open,
  anchorElement,
  onClose,
  onDone,
  adult,
  childAges,
  infant,
  handleAdultChange,
  handleChildAgesChange,
  handleInfantChange,
  handleCabinClass,
  isChildAgesValid,
  onSelectChildAge,
  cabinClass,
  error,
  inputComponent,
}: TravellersSelectorDesktopProps) => {
  const CUSTOM_ANCHOR_ADJUSTMENT = -16;
  return (
    <StyledPopover
      open={open}
      onClose={onClose}
      data-testid="travellers-selector-desktop-popover"
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: 'top',
        horizontal: CUSTOM_ANCHOR_ADJUSTMENT,
      }}
      width={anchorElement?.getBoundingClientRect().width}
      transitionDuration={0}
      footerHeight={0}
    >
      <TextFieldShineThrough component={inputComponent} />
      <StyledPopoverContent>
        <PaxCount
          adult={adult}
          childAges={childAges}
          infant={infant}
          handleAdultChange={handleAdultChange}
          handleChildAgesChange={handleChildAgesChange}
          handleInfantChange={handleInfantChange}
          isChildAgesValid={isChildAgesValid}
          onSelectChildAge={onSelectChildAge}
          error={error}
        />
        {cabinClass && <CabinClass value={cabinClass} onChange={handleCabinClass} />}
        <StyledFooter>
          <PrimaryButton onClick={onDone} data-testid="travellers-done-button">
            Done
          </PrimaryButton>
        </StyledFooter>
      </StyledPopoverContent>
    </StyledPopover>
  );
};

export default TravellersSelectorDesktop;
