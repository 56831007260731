import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

export const StyledCabinClassBox = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
}));

export const StyledDividerBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(2),
}));
