import { Modal } from '@fctg-ds/core';
import MapFilter from '@src/components/map-filter';
import React from 'react';
import { ProductTileProps } from '@fctg/ui-stays-components';
import { EmitEventProps } from '@src/common/types/types';

export interface MapModalProps {
  title: string;
  open: boolean;
  destination: google.maps.LatLngLiteral;
  productTileData: ProductTileProps[];
  mapKey: string;
  onClose: () => void;
  onEmitEvent?: (event: EmitEventProps) => void;
  variant?: 'stays' | 'packages';
}

export const MapModal = ({
  title,
  open,
  destination,
  productTileData,
  mapKey,
  onClose,
  onEmitEvent,
  variant = 'stays',
}: MapModalProps) => {
  return (
    <Modal
      contentStyles={{ height: '100%', marginTop: '16px' }}
      noContentPadding
      fullScreen
      title={title}
      open={open}
      onClose={() => {
        onEmitEvent &&
          onEmitEvent({
            event: 'trackInteraction',
            data: {
              interactionValue: 'mapFilter',
              interactionLabel: 'closeModal',
            },
          });
        onClose();
      }}
    >
      <MapFilter
        mapKey={mapKey}
        destination={destination}
        productTileData={productTileData}
        onEmitEvent={onEmitEvent}
        variant={variant}
      />
    </Modal>
  );
};
