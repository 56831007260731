import React from 'react';
import { formattedCurrency } from '@src/helpers/formattedCurrency';
import { PriceProps } from './types';
import PriceDifference from './components/PriceDifference';
import TotalPrice from './components/TotalPrice';
import {
  StyledPriceContainer,
  StyledOriginalPriceBox,
  StyledOriginalPriceTypography,
  StyledPriceBox,
  StyledPriceComment,
  StyledDifferencePriceBox,
  StyledDifferencePriceTypography,
} from './styles';

const Price = ({
  displayPrice,
  originalPrice,
  currency = 'AUD',
  amountSaved,
  showPriceDifference = false,
  showTaxDisclaimer = false,
}: PriceProps) => {
  return (
    <StyledPriceContainer data-testid="price" showPriceDifference={showPriceDifference}>
      <StyledPriceBox alignItems="baseline">
        {showPriceDifference ? (
          <PriceDifference displayPrice={displayPrice} currency={currency} />
        ) : (
          <TotalPrice displayPrice={displayPrice} currency={currency} />
        )}
      </StyledPriceBox>
      {!showPriceDifference ? (
        <StyledOriginalPriceBox>
          {originalPrice && amountSaved && (
            <StyledOriginalPriceTypography variant="captionMdStrong" data-testid="originalPrice">
              {formattedCurrency(originalPrice, currency, true)}
            </StyledOriginalPriceTypography>
          )}
          {amountSaved && (
            <StyledPriceComment data-testid="priceComment">
              {formattedCurrency(amountSaved, currency, false)} saved
            </StyledPriceComment>
          )}
        </StyledOriginalPriceBox>
      ) : (
        <StyledDifferencePriceBox showTaxDisclaimer={showTaxDisclaimer}>
          <StyledDifferencePriceTypography data-testid="priceTotal" variant="captionMd">
            Total price
          </StyledDifferencePriceTypography>
          {showTaxDisclaimer && (
            <StyledDifferencePriceTypography data-testid="priceDisclaimer" variant="captionMd">
              Incl. taxes & fees
            </StyledDifferencePriceTypography>
          )}
        </StyledDifferencePriceBox>
      )}
    </StyledPriceContainer>
  );
};

export default Price;
