import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import LocationOn from '@mui/icons-material/LocationOn';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import { OptionIconProps } from './type';
import React from 'react';

export const OptionIcon = ({ type, mode }: OptionIconProps) => {
  switch (mode) {
    case 'origin':
      return type === 'airport' ? <FlightTakeoffIcon /> : <FlightLandIcon />;
    default:
      return type === 'city' ? <LocationOn /> : <FlightLandIcon />;
  }
};
