import React from 'react';
import { Box, Typography } from '@mui/material';
import { differenceInDays, format } from 'date-fns';
import pluralize from 'pluralize';
import { StyledCalendarFooterNights, StyledCalendarFooterNightsText, StyledDashIcon, StyledStayIcon } from './styles';
import { CalendarDates } from '@src/components/calendar-date-picker/types';

const CalendarFooter = ({ checkInDate, checkOutDate }: CalendarDates) => {
  if (!checkInDate || !checkOutDate) return null;

  const checkInDateFormatted = new Date(`${checkInDate.year}/${checkInDate.month}/${checkInDate.day}`);
  const checkOutDateFormatted = new Date(`${checkOutDate.year}/${checkOutDate.month}/${checkOutDate.day}`);

  const nights = differenceInDays(checkOutDateFormatted, checkInDateFormatted);

  if (nights <= 0) return null;

  const DATE_FORMAT = 'EEE, d MMM';

  return (
    <Box data-testid="calendar-footer-content">
      <StyledCalendarFooterNights>
        <StyledStayIcon data-testid="calendar-footer-content-stay-icon" />
        <StyledCalendarFooterNightsText variant="bodySm" data-testid="calendar-footer-content-nights">
          {pluralize('night', nights, true)}
        </StyledCalendarFooterNightsText>
      </StyledCalendarFooterNights>
      <Typography variant="bodySmStrong" data-testid="calendar-footer-content-dates">
        {format(checkInDateFormatted, DATE_FORMAT)} <StyledDashIcon /> {format(checkOutDateFormatted, DATE_FORMAT)}
      </Typography>
    </Box>
  );
};

export default CalendarFooter;
