import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';

export const StyledMainBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  background: theme.token.color.neutral.background.medium[' '].value,
  padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
  borderRadius: theme.token.radius.base.value,
}));

export const StyledPackageDetailsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: `0 ${theme.spacing(2)}`,
}));

export const StyledPriceDetailsBox = styled(StyledPackageDetailsBox)(({ theme }) => ({
  display: 'flex',
  flexShrink: 0,
  gap: theme.spacing(0.5),
}));

export const StyledTotalText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export const StyledPackageDetailsInfoText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
}));
