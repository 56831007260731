import React from 'react';
import { Typography } from '@mui/material';
import { formattedCurrency } from '@src/helpers/formattedCurrency';
import { styled } from '@fctg-ds/theme';
import { DisplayPriceProps } from '../../types';

export const StyledPriceDifferenceTypography = styled(Typography)<{ isGreen: boolean }>(({ theme, isGreen }) => ({
  color: isGreen ? theme.token.color.success.text[' '].value : theme.token.color.black[' '].value,
}));

const PriceDifference = ({ displayPrice, currency }: DisplayPriceProps) => {
  return (
    <StyledPriceDifferenceTypography variant="priceXl" data-testid="priceDifference" isGreen={displayPrice <= 0}>
      {formattedCurrency(displayPrice, currency, true, true)}
    </StyledPriceDifferenceTypography>
  );
};

export default PriceDifference;
