import { Button } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

export const StyledMapOuter = styled(Box)<{ enabled?: boolean }>(({ theme, enabled }) => ({
  ...(!enabled ? { opacity: 0.5 } : {}),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.token.color.neutral.border[' '].value,
  padding: theme.spacing(12),
  borderRadius: theme.spacing(4),
  background: 'url("https://secure.fclmedia.com/assets/map/sidebar-map.svg")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));

export const StyledButton = styled(Button)<{ enabled?: boolean }>(({ theme, enabled }) => ({
  ...(!enabled ? { cursor: 'default' } : {}),
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center',
  backgroundColor: theme.token.color.background.weak[' '].value,
  padding: theme.spacing(4),
  borderRadius: theme.spacing(3),
  width: theme.spacing(41),
  height: theme.spacing(12),
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: theme.token.color.background.weak[' '].value,
  },
}));
