import { HOTEL_STATIC_MAP_DEFAULT_ZOOM, HOTEL_STATIC_MAP_ID } from '@src/common/constants';

interface StaticMapProps {
  mapKey: string;
  hotelCoordinates: google.maps.LatLngLiteral;
}

export const generateStaticMapUrl = ({ hotelCoordinates, mapKey }: StaticMapProps): string => {
  if (!mapKey || !hotelCoordinates) return null;

  const { lat, lng } = hotelCoordinates;

  if (!lat || !lng) return null;

  const googleApiUrl = 'https://maps.googleapis.com/maps/api/staticmap';
  const mapIconUrl = 'https://secure.fclmedia.com/assets/stays/assets/icons/hotel-map-icon.png';
  const mapSize = '1280x1280';
  const url = new URL(googleApiUrl);
  const params = new URLSearchParams(url.search);
  params.append('center', `${lat},${lng}`);
  params.append('markers', `icon:${encodeURI(mapIconUrl)}|${lat},${lng}`);
  params.append('zoom', `${HOTEL_STATIC_MAP_DEFAULT_ZOOM}`);
  params.append('key', `${mapKey}`);
  params.append('map_id', `${HOTEL_STATIC_MAP_ID}`);
  params.append('size', `${mapSize}`);
  params.append('scale', '2');
  params.append('format', 'png');

  return `${url.toString()}?${params.toString()}`;
};
