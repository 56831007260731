import { AutocompleteModal } from '@fctg/ui-autocomplete-modal';
import { debounce } from 'lodash';
import { AutocompleteProps, OptionType } from './types';
import { AUTOCOMPLETE_CONFIG, MIN_WIDTH } from './constants';
import { AutocompleteOption } from './components';
import React, { useState } from 'react';

const Autocomplete = ({
  isOpen,
  options,
  onChange,
  onClose,
  onClear,
  fetchOptions,
  brandRegion,
  anchorEl,
  mode,
  desktopBreakpoint,
}: AutocompleteProps) => {
  const [loading, setLoading] = useState(false);

  const onDebounce = debounce(async (value) => {
    await fetchOptions(value);
    setLoading(false);
  }, 300);

  const width = anchorEl ? anchorEl?.getBoundingClientRect()?.width + 32 : 0;

  return (
    <AutocompleteModal<OptionType>
      id="stays-autocomplete"
      renderOption={({ displayValue, value, iataCode, isChild, type, keyword }) => (
        <AutocompleteOption
          mode={mode}
          displayValue={displayValue}
          value={value}
          iataCode={iataCode}
          isChild={isChild}
          type={type}
          keyword={keyword}
          key={`option-${displayValue}-${iataCode}`}
        />
      )}
      defaultOptions={AUTOCOMPLETE_CONFIG[brandRegion].popularLocations[mode]}
      isOpen={isOpen}
      isLoading={loading}
      anchorEl={anchorEl}
      options={options}
      onChange={onChange}
      onClose={onClose}
      defaultFooterText={AUTOCOMPLETE_CONFIG[brandRegion].defaultFooterText[mode]}
      placeholder={AUTOCOMPLETE_CONFIG[brandRegion].placeholder[mode]}
      popoverProps={{
        anchorOrigin: { horizontal: -16, vertical: -16 },
        width: width < MIN_WIDTH ? MIN_WIDTH : width,
      }}
      onMinSearchTextReached={async (value: string) => {
        onClear();
        setLoading(true);
        await onDebounce(value);
      }}
      desktopBreakpoint={desktopBreakpoint}
    />
  );
};

export default Autocomplete;
