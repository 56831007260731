import React from 'react';
import { Box, Grid, Link, Typography, styled } from '@mui/material';
import SearchBar from '@src/components/search-bar';
import PriceSlider from '@src/components/price-slider';
import PropertyClass from '@src/components/property-class';
import AreaFilter from '@components/area-filter';
import { FilterBarActive, FilterBarChangeEvents, FilterBarMetaData } from '../..';
import CancellationPolicy from '@src/components/cancellation-policy/main';
import { ByGuestReviews } from '@src/components/by-guest-reviews';
import Availability from '@components/availability';
import { MapSection } from '@src/components/map-section';
import { EmitEventProps } from '@src/common/types/types';

const StyledDesktopBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.token.color.background[' '].value,
  marginBottom: 0,
  marginRight: theme.spacing(6),
}));

const StyledInnerBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
}));

interface DesktopFilterbarProps {
  activeProps: FilterBarActive;
  onChangeEvents: FilterBarChangeEvents;
  metaData: FilterBarMetaData;
  onEmitEvent?: (event: EmitEventProps) => void;
  mapEnabled: boolean;
}

export const DesktopFilterbar = ({
  activeProps,
  onChangeEvents,
  metaData,
  onEmitEvent,
  mapEnabled,
}: DesktopFilterbarProps) => {
  return (
    <StyledDesktopBox data-testid="stays-filter-desktop">
      <Box data-testid="stays-filter-bar-map-container" borderRadius={4}>
        <MapSection onMapClick={onChangeEvents.onMapClick} onEmitEvent={onEmitEvent} enabled={mapEnabled} />
      </Box>
      <Box data-testid="stays-filter-bar-search-container" marginTop={8}>
        <SearchBar
          onChangeHotelName={onChangeEvents.onChangeHotelName}
          hotelName={activeProps.hotelName}
          onEmitEvent={onEmitEvent}
        />
      </Box>
      <Box data-testid="stays-filter-bar-filter-by-container" marginTop={8}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="headingMd">Filter by</Typography>
          </Grid>
          <Grid item marginTop={1.5}>
            <Link color="inherit" onClick={onChangeEvents.onClearAll}>
              <Typography
                variant="bodySm"
                sx={() => ({
                  textDecoration: 'underline',
                  cursor: 'pointer',
                })}
              >
                Clear all filters
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Box data-testid="stays-filter-bar-price-container" marginTop={8}>
        <Box>
          <Typography marginBottom={4} variant="headingSm">
            Price
          </Typography>
        </Box>
        <Box maxWidth="100%" padding={4}>
          <PriceSlider
            min={metaData.price?.min}
            max={metaData.price?.max}
            chartData={metaData.price?.chartData}
            onChange={onChangeEvents.onChangePrice}
            value={activeProps.price}
            onEmitEvent={onEmitEvent}
          />
        </Box>
      </Box>
      <Box data-testid="stays-filter-bar-guest-review-container" marginTop={8} marginBottom={8}>
        <Typography variant="headingSm">Guest review score</Typography>
        <StyledInnerBox>
          <ByGuestReviews
            selected={activeProps.reviews}
            onChange={onChangeEvents.onChangeReview}
            onEmitEvent={onEmitEvent}
          />
        </StyledInnerBox>
      </Box>
      <Box data-testid="stays-filter-bar-property-class-container" marginTop={8}>
        <Typography variant="headingSm" marginTop={2}>
          Property class
        </Typography>
        <StyledInnerBox>
          <PropertyClass
            selected={activeProps.rating}
            onChange={onChangeEvents.onChangeRating}
            mode={'all_filters'}
            onEmitEvent={onEmitEvent}
          />
        </StyledInnerBox>
      </Box>
      <Box data-testid="stays-filter-bar-area-container" marginTop={8}>
        <Typography variant="headingSm">Area</Typography>
        <AreaFilter
          selected={activeProps.area}
          onChange={onChangeEvents.onChangeArea}
          areas={metaData.area}
          onEmitEvent={onEmitEvent}
        />
      </Box>
      <Box data-testid="stays-filter-bar-payment-container" marginTop={8}>
        <Typography variant="headingSm">Cancellation Policy</Typography>
        <CancellationPolicy
          handleChange={onChangeEvents.onChangeRefundable}
          checked={activeProps.refundable}
          count={metaData.refundable?.count}
          onEmitEvent={onEmitEvent}
        />
      </Box>
      <Box data-testid="stays-filter-bar-availability-container" marginTop={8}>
        <Typography variant="headingSm">Availability</Typography>
        <Availability
          checked={activeProps.available}
          count={metaData.available?.count}
          handleChange={onChangeEvents.onChangeAvailable}
          onEmitEvent={onEmitEvent}
        />
      </Box>
    </StyledDesktopBox>
  );
};
