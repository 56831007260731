import React from 'react';
import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';
import { CalendarDates } from '@src/components/calendar-date-picker/types';
import { Selection } from '../../types';
import DateInput from '../date-input/main';

interface DateSelectionProps {
  dates?: CalendarDates;
  initialSelection: Selection;
  handleSelectionChange: (selection: Selection) => void;
}

const StyledDatesBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    width: '100%',
  },
  [theme.breakpoints.up(1116)]: {
    width: '99.9%',
  },
}));

const DateInputBoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: theme.spacing(4),
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const DateSelection = ({ dates, initialSelection, handleSelectionChange }: DateSelectionProps) => {
  const { checkInDate, checkOutDate } = dates;

  return (
    <StyledDatesBox>
      <DateInputBoxStyle>
        <DateInput
          date={checkInDate}
          type="checkIn"
          handleSelectionChange={handleSelectionChange}
          initialSelection={initialSelection}
        />
        <DateInput
          date={checkOutDate}
          type="checkOut"
          handleSelectionChange={handleSelectionChange}
          initialSelection={initialSelection}
        />
      </DateInputBoxStyle>
    </StyledDatesBox>
  );
};

export default DateSelection;
