import { Box, Typography } from '@mui/material';
import { styled } from '@fctg-ds/theme';

export const StyledNightsPax = styled(Box)(({ theme }) => ({
  color: theme.token.color.neutral.text.weak[' '].value,
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  margin: '0',
  fontSize: theme.spacing(3.5),
}));
