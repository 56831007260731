import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { TopHotelAmenitiesProps } from './types';
import { mapTopAmenities } from '@src/helpers/mapTopAmenities/mapTopAmenities';
import {
  StyledTopAmenitiesBox,
  StyledTopAmenitiesLink,
  StyledTopAmenitiesLinkText,
  StyledTopContainer,
} from './styles';
import AllHotelAmenitiesContainer from './components/all-hotel-amenities-container/main';
import TopHotelAmenitiesGrid from './components/top-hotel-amenities-grid/main';

const TopHotelAmenities = ({ amenities, isMobile }: TopHotelAmenitiesProps) => {
  const [open, setOpen] = useState(false);
  const handleOpenOrClose = () => {
    setOpen((e) => !e);
  };
  const mappedAmenities = mapTopAmenities(amenities);

  return (
    <>
      {mappedAmenities && mappedAmenities.length > 0 && (
        <StyledTopAmenitiesBox data-testid="topHotelAmenities">
          <StyledTopContainer>
            <Typography variant="headingMd" data-testid="topHotelAmenitiesHeading">
              Top amenities
            </Typography>
            <TopHotelAmenitiesGrid top={mappedAmenities} isMobile={isMobile} isModalDialogView={false} />
          </StyledTopContainer>
          <StyledTopAmenitiesLink onClick={() => setOpen(true)} data-testid="topHotelAmenitiesLink">
            <StyledTopAmenitiesLinkText variant="bodyMdStrong">See all property amenities</StyledTopAmenitiesLinkText>
          </StyledTopAmenitiesLink>
          <AllHotelAmenitiesContainer
            amenities={amenities}
            isMobile={isMobile}
            open={open}
            handleOpenOrClose={handleOpenOrClose}
          />
        </StyledTopAmenitiesBox>
      )}
    </>
  );
};

export default TopHotelAmenities;
