import { styled } from '@fctg-ds/theme';
import { Box, Grid, Typography, Button } from '@mui/material';
import { SecondaryButton } from '@fctg-ds/core';

export const StyledMainBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(8),
}));

export const StyledTopBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

export const StyledBottomBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
}));

export const StyledCategoryScoresBox = styled(Grid)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(6),
  justifyContent: 'space-between',
  alignContent: 'flex-start',
  alignItems: 'flex-end',
  [theme.breakpoints.up('lg')]: {
    width: '30%',
  },
}));

export const StyledUserReviewsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    width: '70%',
  },
}));

export const StyledSeeMoreReviewsDesktop = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  height: theme.spacing(10),
  padding: `0 ${theme.spacing(4)} 0 ${theme.spacing(4)}`,
}));

export const StyledSeeMoreReviewsMobile = styled(SecondaryButton)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const StyledSeeReviewsLinkText = styled(Typography)(({ theme }) => ({
  color: theme.token.color.neutral.text[' '].value,
  textDecoration: 'underline',
  fontSize: theme.spacing(3.5),
}));
