import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';

export const StyledOuterBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexFlow: 'column nowrap',
  gap: theme.spacing(3),
  padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
  width: '100%',
}));

export const StyledFareTypeBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexFlow: 'column nowrap',
  maxWidth: '512px',
}));

export const StyledLinkBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
}));

export const ChildAgesSelectorBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

export const StyledChildAgesSelectorTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));
