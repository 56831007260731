import PriceSlider from '@src/components/price-slider';
import React from 'react';
import { SliderProps } from '@mui/material';
import { EmitEventProps } from '@src/common/types/types';

interface PriceSliderMobileProps {
  min: number;
  max: number;
  chartData: Array<{ label: number; count: number }>;
  onChange: SliderProps['onChangeCommitted'];
  value: [number, number];
  onEmitEvent?: (event: EmitEventProps) => void;
}

export const PriceSliderMobile = ({ min, max, chartData, onChange, value, onEmitEvent }: PriceSliderMobileProps) => {
  return (
    <PriceSlider
      min={min}
      max={max}
      chartData={chartData}
      onChange={onChange}
      value={value}
      onEmitEvent={onEmitEvent}
    />
  );
};
