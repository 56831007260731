import { AutocompleteProps, OptionType } from '@src/components/autocomplete/types';
import { useRef, useState } from 'react';
import { PackagesResultsSearchFormProps } from '../types';

export interface UseAutocompleteProps {
  onChangeEvents: PackagesResultsSearchFormProps['onChangeEvents'];
  onFetchOptions: PackagesResultsSearchFormProps['onFetchOptions'];
}

export const useAutocomplete = ({ onChangeEvents, onFetchOptions }: UseAutocompleteProps) => {
  const originRef = useRef();
  const destinationRef = useRef();
  const [mode, setMode] = useState<AutocompleteProps['mode'] | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<OptionType[]>([]);

  const onChange = (value: OptionType) => {
    switch (mode) {
      case 'destination':
        onChangeEvents.onDestinationChange(value);
        break;
      case 'origin':
        onChangeEvents.onOriginChange(value);
        break;
      default:
        break;
    }
  };

  const onOpen = (value: AutocompleteProps['mode']) => {
    setMode(value);
    try {
      (document.activeElement as HTMLElement).blur();
    } catch (error) {
      console.error(error);
    }
    setOpen(true);
  };

  const onClose = () => {
    setMode(null);
    setOpen(false);
    onClear();
  };

  const onClear = () => {
    setOptions([]);
  };

  const getAutoCompleteRef = () => {
    switch (mode) {
      case 'destination':
        return destinationRef?.current;
      case 'origin':
        return originRef?.current;
      default:
        return undefined;
    }
  };

  const onFetch = async (searchValue: string) => {
    if (mode === 'destination') {
      const result = await onFetchOptions({ searchValue, type: 'all' });
      setOptions(result);
    } else if (mode === 'origin') {
      const result = await onFetchOptions({ searchValue, type: 'airports' });
      setOptions(result);
    }
  };

  return {
    onOpen,
    onClose,
    onClear,
    onFetch,
    onChange,
    getAutoCompleteRef,
    options,
    originRef,
    destinationRef,
    open,
    mode,
  };
};
