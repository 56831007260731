import { styled } from '@fctg-ds/theme';
import { Marker } from '@googlemaps/markerclusterer';
import { Typography, Box } from '@mui/material';
import React from 'react';

interface StyledMarkerProps {
  markers: Marker[];
}

// TODO: THIS IS A WORK AROUND TO GET STYLING AS THE REACTDOM RENDER CANT GET THE THEME PROVIDER.
// WE ARE STUCK WITH THIS UNTIL CLUSTER MARKERS RELEASE WITH A REACT COMPONENT VERSION

export const StyledDipOuter = styled(Box)(() => ({
  width: 10,
  height: 10,
  position: 'absolute',
  bottom: -10,
  borderLeft: '10px solid transparent',
  borderRight: '10px solid transparent',
  borderTop: `10px solid black`,
}));

export const StyledDipInner = styled(Box)(() => ({
  width: 10,
  height: 10,
  position: 'absolute',
  bottom: -10,
  borderLeft: '7px solid transparent',
  borderRight: '7px solid transparent',
  borderTop: `7px solid white`,
}));

export const StyledMainBox = styled(Box)(() => ({
  backgroundColor: 'white',
  borderRadius: '8px',
  color: '#000000',
  fontSize: '14px',
  padding: '5px 10px',
  borderColor: 'black',
  border: `2px solid black`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
}));

export const StyledTypography = styled(Typography)(() => ({
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.2px',
}));

export const StyledMarker = ({ markers }: StyledMarkerProps) => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '8px',
        color: '#000000',
        fontSize: '14px',
        padding: '5px 10px',
        borderColor: 'black',
        border: `2px solid black`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <StyledDipOuter />
      <StyledDipInner />
      <StyledTypography>{markers.length} stays</StyledTypography>
    </Box>
  );
};
