import { IconButton } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

export const StyledBox = styled(Box)(() => ({
  position: 'fixed',
  width: '100%',
  bottom: 20,
  zIndex: 5000,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'end',
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 20,
  top: 16,
  background: theme.token?.color?.neutral?.background[' '].value,
  [theme.breakpoints.down('lg')]: {
    '&:hover': { background: theme.token?.color?.neutral?.background[' '].value },
    right: 16,
    top: 16,
  },
}));

export const StyledProductTileWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '800px',
  boxShadow:
    '0px 16px 32px -8px rgba(0, 0, 0, 0.04), 0px 24px 40px -16px rgba(0, 0, 0, 0.02), 0px 4px 42px 0px rgba(0, 0, 0, 0.06), 0px 8px 24px -16px rgba(0, 0, 0, 0.06)',
  [theme.breakpoints.down('lg')]: {
    width: '552px',
  },
  [theme.breakpoints.down('md')]: {
    width: '360px',
  },
  [theme.breakpoints.between(320, 'sm')]: {
    width: '320px',
  },
  [theme.breakpoints.down(320)]: {
    width: '100%',
  },
}));
