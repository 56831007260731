import React from 'react';
import Badge from '@src/components/badge/main';

interface BadgesProps {
  cheapest?: boolean;
  discount: number;
}

const Badges = ({ cheapest, discount }: BadgesProps) => {
  return (
    <>
      {cheapest ? (
        <Badge type="cheapest">Cheapest</Badge>
      ) : (
        discount && <Badge type="discount">{discount + '% off'}</Badge>
      )}
    </>
  );
};

export default Badges;
