import { Box } from '@mui/material';
import { styled } from '@fctg-ds/theme';
import { PrimaryButton } from '@fctg-ds/core';
import { RoomOptionProps } from '../../types';

export const StyledTileButtonBox = styled(Box)<{ roomOptionVariant: RoomOptionProps['roomOptionVariant'] }>(
  ({ theme, roomOptionVariant }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    paddingTop: theme.spacing(4),
    ...(roomOptionVariant === 'roomTile'
      ? { background: theme.token.color.neutral.background.medium[' '].value }
      : { padding: `0 ${theme.spacing(4)} ${theme.spacing(4)}` }),
  }),
);

export const StyledTileButton = styled(PrimaryButton)(({ theme }) => ({
  height: theme.spacing(12),
}));
