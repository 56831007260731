import React from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import { PrimaryButton } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { FooterProps } from './types';

export const StyledFilterBarDialog = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: theme.token.color.background[' '].value,
  borderTop: `1px solid ${theme.token.color.border.disabled.value}`,
  [theme.breakpoints.up('xs')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(4.5)}`,
  },
  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
  },
}));

export const StyledLinkGrid = styled(Grid)(({ theme }) => ({
  margin: `${theme.spacing(2.5)} ${theme.spacing(6)} ${theme.spacing(2.5)} ${theme.spacing(1)}`,
}));

export const StyledClearAll = styled(Typography)(() => ({
  textDecoration: 'underline',
  cursor: 'pointer',
}));

export const Footer = ({ onChangeEvents, metaData, isSortBy = false, onClose }: FooterProps) => {
  const onClearAll = () => {
    onChangeEvents.onClearAll();
    onClose();
  };

  return (
    <StyledFilterBarDialog>
      <Grid container justifyContent="flex-end">
        {!isSortBy && (
          <StyledLinkGrid item>
            <Link color="inherit" onClick={onClearAll}>
              <StyledClearAll variant="bodySm">Clear all filters</StyledClearAll>
            </Link>
          </StyledLinkGrid>
        )}
        <Grid item>
          <PrimaryButton color="primary" onClick={onClose}>
            Show {metaData.total} stay{metaData.total === 1 ? '' : 's'}
          </PrimaryButton>
        </Grid>
      </Grid>
    </StyledFilterBarDialog>
  );
};
