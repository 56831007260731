import React from 'react';
import { Box, Typography } from '@mui/material';
import pluralize from 'pluralize';
import { StyledRoomOptionsButtonBox, StyledRoomOptionsButton } from './styles';
import { RoomTileProps } from '../../types';

interface Props {
  roomId: RoomTileProps['id'];
  handleRooms: RoomTileProps['handleRooms'];
  otherRoomsLength: number;
}

const RoomOptionsButton = ({ handleRooms, roomId, otherRoomsLength }: Props) => {
  const optionText = pluralize('option', otherRoomsLength);
  return (
    <Box data-testid="room-options-button-outer" width="100%">
      <StyledRoomOptionsButtonBox data-testid="room-options-button-inner">
        <StyledRoomOptionsButton
          onClick={() => handleRooms(roomId)}
          placeholder={'room-options-button'}
          data-testid="room-options-button"
          fullWidth
        >
          <Typography variant="bodyMdStrong">{`View ${otherRoomsLength} other ${optionText}`}</Typography>
        </StyledRoomOptionsButton>
      </StyledRoomOptionsButtonBox>
    </Box>
  );
};

export default RoomOptionsButton;
