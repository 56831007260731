import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';
import { ResultsHeaderProps } from './types';

export const ResultsHeaderBox = styled(Box)<{ variant?: ResultsHeaderProps['variant'] }>(
  ({ theme, variant = 'stays' }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      gap: variant === 'stays' ? theme.spacing(12) : theme.spacing(8),
    },
  }),
);

export const ResultsHeaderStyle = styled(Box)<{ variant?: ResultsHeaderProps['variant'] }>(
  ({ theme, variant = 'stays' }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    [theme.breakpoints.down('lg')]: {
      marginTop: variant === 'stays' ? 0 : theme.spacing(4),
    },
  }),
);

export const SortBoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  gap: theme.spacing(1),
}));

export const SortBoxDesktop = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  alignItems: 'flex-end',
}));

export const SortBoxMobile = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  marginTop: theme.spacing(1),
}));

export const SortByTextStyle = styled(Typography)(({ theme }) => ({
  minWidth: theme.spacing(12),
  marginLeft: theme.spacing(6),
}));
