import React, { useRef, useState } from 'react';
import { Chip } from '@fctg-ds/core';
import { KeyboardArrowDown } from '@mui/icons-material';
import { SortChipsProps } from './types';
import { Box } from '@mui/system';
import DesktopSortOptionsList from './components/desktop-menu/main';
import DrawerMobileSortOptionsList from './components/mobile-drawer/main';

const SortChip = ({
  isMobile,
  sortValue,
  sortOptions,
  footer,
  handleOnSelect,
  onEmitEvent,
  fetchLabelByOption,
  eventType,
}: SortChipsProps) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);

  const handleOpenOrClose = () => {
    setOpen((e) => !e);
  };

  return (
    <Box display="inline-block" ref={ref}>
      <Chip
        onClick={handleOpenOrClose}
        label={fetchLabelByOption(sortValue)}
        isActive={open || sortValue != null}
        endIcon={<KeyboardArrowDown />}
      />
      {isMobile ? (
        <DrawerMobileSortOptionsList
          open={open}
          footer={footer}
          sortValue={sortValue}
          sortOptions={sortOptions}
          handleOnSelect={handleOnSelect}
          handleOpenOrClose={handleOpenOrClose}
          handleOnClose={handleOpenOrClose}
          fetchLabelByOption={fetchLabelByOption}
          onEmitEvent={onEmitEvent}
          eventType={eventType}
        />
      ) : (
        <DesktopSortOptionsList
          open={open}
          sortValue={sortValue}
          sortOptions={sortOptions}
          anchorEl={ref?.current}
          handleOnSelect={handleOnSelect}
          handleOnClose={handleOpenOrClose}
          fetchLabelByOption={fetchLabelByOption}
          onEmitEvent={onEmitEvent}
          eventType={eventType}
        />
      )}
    </Box>
  );
};

export default SortChip;
