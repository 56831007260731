import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

export const StyledRoomOptionModalBox = styled(Box)<{ isLeadRoom?: boolean }>(({ theme, isLeadRoom }) => ({
  height: '100%',
  borderRadius: theme.spacing(4),
  ...(isLeadRoom
    ? { border: `2px ${theme.token.color.neutral.background.strong.pressed.value} solid` }
    : { border: `1px ${theme.token.color.neutral.border.disabled.value} solid` }),
}));
