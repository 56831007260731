import React from 'react';
import StickyPackageDetails from './components/sticky-package-details';
import StickyPackageDetailsWrapper from './components/sticky-package-details-wrapper';
import StaticPackageDetails from './components/static-package-details';
import { PackageDetailsProps } from './types';
import { parse, format } from 'date-fns';

const PackageDetails = ({
  departureDate,
  returnDate,
  travellersCount,
  nights,
  total,
  originalPrice,
  currency = 'AUD',
  amountSaved,
  isStuck = false,
}: PackageDetailsProps) => {
  const formattedDepartureDate = format(parse(departureDate, 'yyyy-MM-dd', new Date()), 'dd MMM');
  const formattedReturnDate = format(parse(returnDate, 'yyyy-MM-dd', new Date()), 'dd MMM');

  return (
    <>
      <StaticPackageDetails
        departureDate={formattedDepartureDate}
        returnDate={formattedReturnDate}
        travellersCount={travellersCount}
        nights={nights}
        total={total}
        originalPrice={originalPrice}
        currency={currency}
        amountSaved={amountSaved}
      />
      {isStuck && (
        <StickyPackageDetailsWrapper>
          <StickyPackageDetails
            departureDate={formattedDepartureDate}
            returnDate={formattedReturnDate}
            travellersCount={travellersCount}
            nights={nights}
            total={total}
            originalPrice={originalPrice}
            currency={currency}
            amountSaved={amountSaved}
          />
        </StickyPackageDetailsWrapper>
      )}
    </>
  );
};

export default PackageDetails;
