import React from 'react';
import { PackagesResultsSearchFormProps } from './types';
import PackagesResultsSearchFormMobile from './components/mobile-search-form/main';
import PackagesResultsSearchFormDesktop from './components/desktop-search-form/main';

const PackagesResultsSearchForm = ({
  origin,
  destination,
  departureDate,
  returnDate,
  cabinClass,
  travellers,
  onChangeEvents,
  onFetchOptions,
  brandRegion,
  isMobile,
}: PackagesResultsSearchFormProps) => {
  return isMobile ? (
    <PackagesResultsSearchFormMobile
      origin={origin}
      destination={destination}
      departureDate={departureDate}
      returnDate={returnDate}
      cabinClass={cabinClass}
      travellers={travellers}
      brandRegion={brandRegion}
      onChangeEvents={onChangeEvents}
      onFetchOptions={onFetchOptions}
    />
  ) : (
    <PackagesResultsSearchFormDesktop
      origin={origin}
      destination={destination}
      departureDate={departureDate}
      returnDate={returnDate}
      cabinClass={cabinClass}
      travellers={travellers}
      brandRegion={brandRegion}
      onChangeEvents={onChangeEvents}
      onFetchOptions={onFetchOptions}
    />
  );
};

export default PackagesResultsSearchForm;
