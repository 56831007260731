import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@fctg-ds/theme';
import UrgencyIndicator from '@src/components/urgency-indicator/main';
import NightsPax from '@src/components/nights-pax/main';
import Price from '@src/components/price/main';
import { RoomPriceProps } from '../../types';

const StyledPriceBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  minHeight: '98px',
}));

const StyledPriceInfo = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  whiteSpace: 'nowrap',
  marginRight: 0,
  [theme.breakpoints.between('xs', 'sm')]: {
    marginRight: `-${theme.spacing(4)}`,
  },
  [theme.breakpoints.between('md', 640)]: {
    marginRight: `-${theme.spacing(4)}`,
  },
  [theme.breakpoints.between('lg', 948)]: {
    marginRight: `-${theme.spacing(4)}`,
  },
}));

const StyledFeesDisclaimerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.token.color.neutral.text.weak[' '].value,
  justifyContent: 'flex-start',
  marginTop: theme.spacing(1),
  whiteSpace: 'break-spaces',
}));

const StaysRoomPrice = ({
  displayPrice,
  originalPrice,
  amountSaved,
  urgent,
  nights,
  travellers,
}: Omit<RoomPriceProps, 'roomOptionVariant'>) => {
  return (
    <StyledPriceBox data-testid="room-options-price-container">
      <StyledPriceInfo data-testid="room-options-price-content">
        {urgent && <UrgencyIndicator />}
        <NightsPax nights={nights} travellersCount={travellers} />
        <StyledFeesDisclaimerBox>
          <Typography data-testid="room-options-price-disclaimer-text" variant="captionMd">
            Incl. taxes & fees
          </Typography>
        </StyledFeesDisclaimerBox>
      </StyledPriceInfo>
      <Price displayPrice={displayPrice} originalPrice={originalPrice} amountSaved={amountSaved} />
    </StyledPriceBox>
  );
};

export default StaysRoomPrice;
