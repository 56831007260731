import React from 'react';
import { styled } from '@fctg-ds/theme';
import { Box, Stack, Typography } from '@mui/material';
import { TileProps } from '@components/destination-tile/types';

export interface DestinationTilesStackedLayoutProps {
  header?: string;
  itemsPerView?: number;
  children: React.ReactElement<TileProps>[] | React.ReactElement<TileProps>;
}

const StyledStack = styled(Stack, { shouldForwardProp: (prop) => prop !== 'itemsPerView' })<{ itemsPerView: number }>(
  ({ theme, itemsPerView }) => {
    return {
      flexFlow: 'row wrap',
      gap: theme.spacing(6),
      '> a': {
        width: `calc(${100 / itemsPerView}% - ${theme.spacing(3)})`,
      },
    };
  },
);

export const DestinationTilesStackedLayout = ({
  header = '',
  itemsPerView = 2,
  children,
}: DestinationTilesStackedLayoutProps) => {
  return (
    <Stack data-testid="tiles-stacked">
      {header && (
        <Box
          sx={(theme) => ({
            marginBottom: theme.token.spacing[4].value,
          })}
        >
          <Typography variant="headingMd">{header}</Typography>
        </Box>
      )}
      <StyledStack itemsPerView={itemsPerView}>{children}</StyledStack>
    </Stack>
  );
};

export default DestinationTilesStackedLayout;
