import { Typography } from '@mui/material';
import React from 'react';
import { RoomOptionsModalProps } from '../..';
import { formattedCurrency } from '@src/helpers/formattedCurrency';
import {
  StyledMainBox,
  StyledPackageDetailsBox,
  StyledPriceDetailsBox,
  StyledTotalText,
  StyledPackageDetailsInfoText,
} from './styles';
import pluralize from 'pluralize';

interface StickyPackageDetailsProps {
  leadRoomOption?: RoomOptionsModalProps['leadRoomOption'];
  nights: RoomOptionsModalProps['nights'];
  travellers: RoomOptionsModalProps['travellers'];
}

export const StickyPackageDetailsDesktop = ({ leadRoomOption, nights, travellers }: StickyPackageDetailsProps) => {
  return (
    leadRoomOption?.price?.total && (
      <StyledMainBox data-testid="room-option-modal-desktop-header">
        <StyledPackageDetailsBox>
          <Typography variant="bodyMdStrong">Package price</Typography>
          <StyledPackageDetailsInfoText variant="bodySm">
            2 return airfares + {pluralize('night', nights, true)}, {pluralize('traveller', travellers, true)}
          </StyledPackageDetailsInfoText>
        </StyledPackageDetailsBox>
        <StyledPriceDetailsBox>
          <Typography variant="priceXl">{formattedCurrency(leadRoomOption.price.total, 'AUD', true)}</Typography>
          <StyledTotalText variant="captionMd">/ total</StyledTotalText>
        </StyledPriceDetailsBox>
      </StyledMainBox>
    )
  );
};
