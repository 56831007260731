import React, { useRef } from 'react';
import { StyledPersonIcon, StyledTravellersBox } from './styles';
import { InputType, SearchFormProps } from '../../types';
import TravellersSelector from '@src/components/travellers-selector/main';
import { Travellers } from '@src/components/travellers-selector/types';
import { StyledTextfield, StyledTextfieldShineThrough } from '../../styles';
import { getTravellersText } from '@src/helpers/getTravellersText/getTravellersText';

interface TravellersSelectionProps {
  component: InputType;
  isMobileOrTablet: boolean;
  travellers: SearchFormProps['travellers'];
  onDone: SearchFormProps['onTravellersDone'];
  onOpen: (value: InputType) => void;
  onClose: () => void;
  onEmitEvent?: SearchFormProps['onEmitEvent'];
}

export const TravellersSelection = ({
  component,
  isMobileOrTablet,
  travellers,
  onDone,
  onOpen,
  onClose,
  onEmitEvent,
}: TravellersSelectionProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const onTravellersDone = (travellers: Travellers) => {
    onDone(travellers);
    onEmitEvent &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: 'Edit Travellers',
          interactionLabel: 'newAvailabilitySearch',
        },
      });
  };

  const onTravellersOpen = () => {
    onOpen('travellers');
    onEmitEvent &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: 'Edit Travellers',
          interactionLabel: 'openModal',
        },
      });
  };

  const onTravellersClose = () => {
    onClose();
    onEmitEvent &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: 'Edit Travellers',
          interactionLabel: 'closeModal',
        },
      });
  };

  const travellersText = getTravellersText(travellers);

  return (
    <StyledTravellersBox>
      <StyledTextfield
        ref={ref}
        startAdornment={<StyledPersonIcon />}
        value={travellersText}
        label="Travellers"
        onClick={onTravellersOpen}
        inputProps={{ inputMode: 'none' }}
        data-testid="travellers-selection"
        fullWidth
      />
      <TravellersSelector
        open={component === 'travellers'}
        isMobileOrTablet={isMobileOrTablet}
        travellers={travellers}
        onSubmit={onTravellersDone}
        onClose={onTravellersClose}
        anchorEl={ref?.current}
        inputComponent={
          <>
            {ref?.current && (
              <StyledTextfieldShineThrough
                width={ref.current.getBoundingClientRect().width}
                startAdornment={<StyledPersonIcon />}
                inputProps={{ inputMode: 'none' }}
                data-testid="travellers-selection"
                value={travellersText}
                label="Travellers"
                focused
              />
            )}
          </>
        }
      />
    </StyledTravellersBox>
  );
};
