import RoomOption from '@src/components/room-option/main';
import { StyledRoomOptionModalBox } from '../../styles';
import React from 'react';
import { Drawer, IconButton } from '@fctg-ds/core';
import CloseIcon from '@mui/icons-material/Close';
import { RoomOptionsModalProps } from '../..';
import { StyledDrawerContainer, StyledMobileSelectText } from './styles';
import { StickyPackageDetailsMobile } from '../sticky-package-details-mobile/main';

interface RoomOptionsDrawerMobileProps {
  open: RoomOptionsModalProps['open'];
  title: RoomOptionsModalProps['title'];
  roomOptions: RoomOptionsModalProps['roomOptions'];
  leadRoomOption: RoomOptionsModalProps['leadRoomOption'];
  roomId: RoomOptionsModalProps['roomId'];
  handleClose: RoomOptionsModalProps['handleClose'];
  handleSelect: RoomOptionsModalProps['handleSelect'];
  nights: RoomOptionsModalProps['nights'];
  travellers: RoomOptionsModalProps['travellers'];
  variant: RoomOptionsModalProps['variant'];
}

export const RoomOptionsDrawerMobile = ({
  open,
  roomOptions,
  leadRoomOption,
  roomId,
  title,
  handleClose,
  handleSelect,
  nights,
  travellers,
  variant,
}: RoomOptionsDrawerMobileProps) => {
  return (
    <Drawer
      open={open && roomOptions.length > 0}
      drawerHeaderTitle={title}
      onClose={handleClose}
      onOpen={() => true}
      leftActionBtn={
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }
      footer={
        variant === 'packages' && (
          <StickyPackageDetailsMobile leadRoomOption={leadRoomOption} nights={nights} travellers={travellers} />
        )
      }
    >
      <StyledDrawerContainer data-testid="room-option-drawer-mobile">
        <StyledMobileSelectText variant="bodyMd">
          Select one of the following room options for your stay
        </StyledMobileSelectText>
        {roomOptions.map((roomOption, index) => (
          <StyledRoomOptionModalBox isLeadRoom={index === 0}>
            <RoomOption
              handleSelect={handleSelect}
              roomOption={roomOption}
              nights={nights}
              travellers={travellers}
              roomId={roomId}
              roomOptionVariant="roomOption"
              variant={variant}
            />
          </StyledRoomOptionModalBox>
        ))}
      </StyledDrawerContainer>
    </Drawer>
  );
};
