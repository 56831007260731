import { Typography } from '@mui/material';
import React from 'react';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { StyledButton, StyledMapOuter } from './styles';
import { MapSectionProps } from './types';

export const MapSection = ({ onMapClick, onEmitEvent, enabled }: MapSectionProps) => {
  return (
    <StyledMapOuter enabled={enabled}>
      <StyledButton
        enabled={enabled}
        data-testid="stays-map-chip"
        onClick={() => {
          onEmitEvent &&
            onEmitEvent({
              event: 'trackInteraction',
              data: {
                interactionValue: 'mapFilter',
                interactionLabel: 'openModal',
              },
            });
          enabled && onMapClick();
        }}
        startIcon={<FmdGoodIcon />}
        placeholder={undefined}
      >
        <Typography variant="bodyMdStrong">Show on map</Typography>
      </StyledButton>
    </StyledMapOuter>
  );
};
