import React from 'react';
import PropertyClass from '@src/components/property-class';
import { Box } from '@mui/material';
import { Ratings } from '@src/components/property-class/types';
import { EmitEventProps } from '@src/common/types/types';

interface PropertyClassProps {
  onChange: (value: Ratings) => void;
  selected: Array<Ratings>;
  onEmitEvent?: (event: EmitEventProps) => void;
}

export const PropertyClassMobile = ({ selected, onChange, onEmitEvent }: PropertyClassProps) => {
  return (
    <Box display="flex" justifyContent="center">
      <PropertyClass selected={selected} onChange={onChange} mode="popup" onEmitEvent={onEmitEvent} />
    </Box>
  );
};
