import React from 'react';
import { Typography } from '@mui/material';
import { CategoryScoreProps } from '../../types';
import { getCategoryDisplay } from '../../mapper';
import {
  StyledCategoryScoreMainContainer,
  StyledCategoryScoreScoreContainer,
  StyledCategoryScoreBarContainer,
  StyledCategoryScoreLeftBar,
  StyledCategoryScoreRightBar,
} from './styles';

const CategoryScore = ({ category, score }: CategoryScoreProps) => {
  return (
    <StyledCategoryScoreMainContainer data-testid="category-score">
      <StyledCategoryScoreScoreContainer data-testid="category-score-score-container">
        <Typography variant="subheadingMd" data-testid="category-score-category-value">
          {getCategoryDisplay(category)}
        </Typography>
        <Typography variant="priceMd" data-testid="category-score-value">
          {score === 10.0 ? 10 : score.toFixed(1)}
        </Typography>
      </StyledCategoryScoreScoreContainer>
      <StyledCategoryScoreBarContainer data-testid="category-score-bar-container">
        <StyledCategoryScoreLeftBar score={score} data-testid="category-score-bar-left-container" />
        <StyledCategoryScoreRightBar score={score} data-testid="category-score-bar-right-container" />
      </StyledCategoryScoreBarContainer>
    </StyledCategoryScoreMainContainer>
  );
};

export default CategoryScore;
