import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { RenderComponentFn } from '@libs/serverless-micro-ui/csr/renderComponent.types';
import { FCThemeProvider } from '@fctg-ds/theme';

const renderComponent: RenderComponentFn =
  ({ components, store, clients }) =>
  (el, name, props) => {
    const Component = components[name];
    render(
      <Provider store={store}>
        <FCThemeProvider cssBaseline={false}>
          <Component config={store.getState().config} clients={clients} {...props} />
        </FCThemeProvider>
      </Provider>,
      el,
    );
  };

export default renderComponent;
