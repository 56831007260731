import { Modal } from '@fctg-ds/core';
import React from 'react';
import { HotelDetailProps } from '../hotel-detail/types';
import { Drawer, IconButton } from '@fctg-ds/core';
import CloseIcon from '@mui/icons-material/Close';
import { List } from './components';

export interface AttractionsModalProps {
  open: boolean;
  isMobile: boolean;
  attractions: HotelDetailProps['attractions'];
  onClose: () => void;
}

const AttractionsModal = ({ open, isMobile, attractions, onClose }: AttractionsModalProps) => {
  return (
    <>
      {isMobile ? (
        <Drawer
          open={open}
          onOpen={() => true}
          onClose={onClose}
          drawerHeaderTitle="What's nearby"
          hideDragHandle
          noContentPadding
          maxHeight="100%"
          leftActionBtn={
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          }
          data-testid="attractions-mobile-drawer"
        >
          <List attractions={attractions} />
        </Drawer>
      ) : (
        <Modal
          open={open}
          onClose={onClose}
          title="What's nearby"
          maxHeight={'80%'}
          maxWidth={'lg'}
          data-testid="attractions-desktop-modal"
          noContentPadding
          showClose
          slideUp
        >
          <List attractions={attractions} showDottedLines />
        </Modal>
      )}
    </>
  );
};

export default AttractionsModal;
