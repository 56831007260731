import React, { useState } from 'react';
import {
  StyledRefundType,
  StyledInfoIcon,
  StyledPopoverContentContainer,
  StyledPopoverContent,
  StyledRefundText,
} from './styles';
import { Popover } from '@fctg-ds/core';
import { parseISO, format } from 'date-fns';
import { RoomOptionProps } from '../../types';

interface RefundTypeProps {
  cancellationPolicies: string[];
  refundable: boolean;
  freeCancellationDate: string;
  roomOptionVariant: RoomOptionProps['roomOptionVariant'];
}

const RefundType = ({ cancellationPolicies, refundable, freeCancellationDate, roomOptionVariant }: RefundTypeProps) => {
  const hasCancellationPolicies = cancellationPolicies && cancellationPolicies.length > 0;

  const [anchorElement, setAnchorElement] = useState(null);
  const handleClick = (event) => hasCancellationPolicies && setAnchorElement(event.currentTarget);
  const handleClose = () => hasCancellationPolicies && setAnchorElement(null);
  const isOpen = Boolean(anchorElement);

  const highlightLastLine =
    hasCancellationPolicies && cancellationPolicies.at(-1) === "Times are based on the property's local time.";

  const formattedFreeCancellationDate = freeCancellationDate ? format(parseISO(freeCancellationDate), 'dd MMM') : '';

  return (
    <>
      <StyledRefundType
        refundable={refundable}
        roomOptionVariant={roomOptionVariant}
        onClick={handleClick}
        data-testid="room-options-refundable-info"
      >
        <StyledRefundText refundable={refundable} roomOptionVariant={roomOptionVariant} variant="bodySm">
          {refundable ? `Refundable room before ${formattedFreeCancellationDate}` : `Non-refundable room`}
        </StyledRefundText>
        {hasCancellationPolicies && (
          <StyledInfoIcon refundable={refundable} data-testid="cancellation-policies-info-icon" />
        )}
      </StyledRefundType>
      <Popover
        open={isOpen}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        data-testid="cancellation-policies-popover"
      >
        <StyledPopoverContentContainer>
          {cancellationPolicies.map((policy) => (
            <StyledPopoverContent variant="bodySm" highlightLastLine={highlightLastLine}>
              {policy}
            </StyledPopoverContent>
          ))}
        </StyledPopoverContentContainer>
      </Popover>
    </>
  );
};

export default RefundType;
