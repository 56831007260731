import { Box } from '@mui/material';
import { styled } from '@fctg-ds/theme';

export const StyledParentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
}));

export const StyledTopAmenitiesBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  padding: theme.spacing(8),
  backgroundColor: theme.token.color.neutral.background.medium[' '].value,
  width: '100%',
  borderRadius: theme.spacing(4),
}));
