import React from 'react';
import { Typography } from '@mui/material';
import { HeaderProps } from './types';
import {
  StyledHeaderBox,
  StyledSubContent,
  StyledCityName,
  StyledFiberManualRecordIcon,
  StyledLocationPin,
  StyledRating,
} from './styles';

const Header = ({ hotelName, city, starRating }: HeaderProps) => {
  return (
    <StyledHeaderBox data-testid="header">
      <Typography variant="headingLg" data-testid="headerHotelName">
        {hotelName}
      </Typography>
      <StyledSubContent gap={2}>
        <StyledRating
          value={starRating}
          iconSize="24px"
          max={5}
          precision={0.5}
          readOnly
          data-testid="headerStarRating"
        />
        <StyledFiberManualRecordIcon data-testid="headerSeparator" />
        <StyledLocationPin data-testid="headerLocationPinr" />
        <StyledCityName variant="bodyMd" data-testid="headerCity">
          {city}
        </StyledCityName>
      </StyledSubContent>
    </StyledHeaderBox>
  );
};

export default Header;
