import { Box } from '@mui/material';
import { styled } from '@fctg-ds/theme';

export const StyledCheckInOutContentInnerTimeSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  [theme.breakpoints.up('xs')]: {
    width: '100%',
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    width: '480px',
    flexDirection: 'row',
  },
}));

export const StyledCheckInOutContentInnerTimeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),
  backgroundColor: theme.token.color.neutral.background.medium[' '].value,
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  borderRadius: theme.spacing(2),
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: 'fit-content',
  },
}));

export const StyledCheckInOutContentInnerTimeBoxContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.token.color.neutral.background.weak[' '].value,
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  alignItems: 'center',
}));

export const StyledCheckInOutContentInnerTimeBoxTextBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center',
}));
