import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { SortOptionSelectableProps, SortOptionsListProps } from '../types';
import { SortOptionSelectableStyled } from '../styles';
import { GTM_SORT_EVENTS } from '@src/common/sort-chip/constants';

const SortOptionSelectable = ({
  sortValue,
  selected,
  isMobile,
  handleOnSelect,
  handleOnClose,
  onEmitEvent,
  fetchLabelByOption,
  eventType = 'results',
}: SortOptionSelectableProps) => {
  const onClick = () => {
    onEmitEvent &&
      !selected &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: GTM_SORT_EVENTS[eventType].interactionValue,
          interactionLabel: GTM_SORT_EVENTS[eventType].interactionLabel[sortValue] || '',
        },
      });
    handleOnSelect(sortValue);
    if (handleOnClose) handleOnClose();
  };

  return (
    <SortOptionSelectableStyled isMobile={isMobile} onClick={onClick} selected={selected}>
      <Typography variant={selected ? 'bodySmStrong' : 'bodySm'}>{fetchLabelByOption(sortValue)}</Typography>
    </SortOptionSelectableStyled>
  );
};

const SortOptionsList = ({
  isMobile,
  sortValue,
  sortOptions,
  handleOnSelect,
  handleOnClose,
  onEmitEvent,
  eventType,
  fetchLabelByOption,
}: SortOptionsListProps) => (
  <Box display="flex" flexDirection="column">
    {sortOptions.map((option) => (
      <SortOptionSelectable
        isMobile={isMobile}
        sortValue={option}
        selected={option === (sortValue || sortOptions[0])}
        handleOnClose={handleOnClose}
        handleOnSelect={handleOnSelect}
        onEmitEvent={onEmitEvent}
        eventType={eventType}
        fetchLabelByOption={fetchLabelByOption}
      />
    ))}
  </Box>
);

export default SortOptionsList;
