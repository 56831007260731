import { StyledInnerOption, StyledOptionBox, StyledTypographyBox, StyledTypographySubHeading } from './style';
import { HighlightedText, OptionIcon } from './components';
import { OptionProps } from './type';
import React from 'react';

const AutocompleteOption = ({ displayValue, value, iataCode, isChild, type, keyword, mode }: OptionProps) => {
  const headerRow = [displayValue, value].filter((text) => text);

  return (
    <StyledOptionBox data-testid="autocomplete-option" isChild={isChild}>
      <OptionIcon type={type} mode={mode} />
      <StyledInnerOption>
        <StyledTypographyBox>
          {headerRow.map((text, index) =>
            index === 0 ? (
              <HighlightedText key={`highlighted-${text}-${index}`} text={text} keyword={keyword} />
            ) : (
              <StyledTypographySubHeading key={`$subheading-${text}-${index}`} variant="bodySmStrong">
                {text}
              </StyledTypographySubHeading>
            ),
          )}
        </StyledTypographyBox>
        {mode === 'origin' && <HighlightedText text={iataCode} keyword={keyword} />}
      </StyledInnerOption>
    </StyledOptionBox>
  );
};

export default AutocompleteOption;
