import React, { useEffect, useState } from 'react';

import { SearchBarProps } from './types';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment } from '@mui/material';
import { StyledTextField } from './styles';
import ClearIcon from '@mui/icons-material/Clear';

const MIN_SEARCH_TEXT_LENGTH = 3;

const SearchBar = ({ hotelName, onChangeHotelName, onEmitEvent }: SearchBarProps) => {
  const [value, setValue] = useState(hotelName);

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const onClear = () => {
    setValue('');
    onChangeHotelName(null);
  };

  useEffect(() => {
    if (value?.length >= MIN_SEARCH_TEXT_LENGTH) {
      onChangeHotelName(value);
      if (onEmitEvent) {
        onEmitEvent({
          event: 'trackInteraction',
          data: {
            interactionValue: 'filterBy',
            interactionLabel: 'hotelName',
          },
        });
      }
    } else {
      onChangeHotelName('');
    }
  }, [value]);

  useEffect(() => {
    if (hotelName === null) {
      setValue('');
    }
  }, [hotelName]);

  return (
    <StyledTextField
      data-testid="stays-search-bar"
      value={value}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="clear toggle" data-testid="customise-trip-clear-flights" onClick={onClear}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      placeholder="Find a stay"
      onChange={onChange}
      variant="outlined"
    />
  );
};

export default SearchBar;
