import React from 'react';
import {
  StyledPaxCountChangerBox,
  StyledPaxCountChangerTextContainer,
  StyledPaxCountChangerText,
  StyledPaxCountChangerSubtext,
} from './styles';
import { Incrementor } from '../incrementor/main';

type PaxCountChangerProps = {
  title: string;
  subTitle?: string;
  value: number;
  min?: number;
  onChange: (value: number) => void;
};

export const PaxCountChanger = ({ value, title, subTitle, min, onChange, ...props }: PaxCountChangerProps) => (
  <StyledPaxCountChangerBox {...props}>
    <StyledPaxCountChangerTextContainer>
      <StyledPaxCountChangerText>{title}</StyledPaxCountChangerText>
      <StyledPaxCountChangerSubtext>{subTitle}</StyledPaxCountChangerSubtext>
    </StyledPaxCountChangerTextContainer>
    <Incrementor value={value} min={min} onChange={onChange} />
  </StyledPaxCountChangerBox>
);
