import { Box, styled } from '@mui/material';
import { FieldGroup, PrimaryButton, TextField } from '@fctg-ds/core';
import PersonIcon from '@mui/icons-material/Person';

export const StyledPackagesResultsSearchForm = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3.5),
  alignItems: 'center',
}));

export const StyledFieldGroup = styled(FieldGroup)(({ theme }) => ({
  width: '100%',
  borderColor: theme.token.color.neutral.background.weak[' '].value,
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '50%',
  backgroundColor: theme.token.color.neutral.background.medium[' '].value,
  borderRadius: theme.spacing(3),
  '.MuiInputBase-root': {
    backgroundColor: theme.token.color.neutral.background.medium[' '].value,
    borderColor: theme.token.color.neutral.background.weak[' '].value,
    cursor: 'pointer',
  },
  '.MuiInputBase-root:hover': {
    backgroundColor: theme.token.color.neutral.background.medium[' '].value,
    borderColor: theme.token.color.neutral.background.weak[' '].value,
  },
  '.MuiInputBase-input': {
    marginLeft: theme.spacing(1.5),
    cursor: 'pointer',
  },
  '.MuiFilledInput-root.Mui-focused': {
    backgroundColor: theme.token.color.neutral.background.medium[' '].value,
  },
  '.MuiFilledInput-root.Mui-focused::before': {
    borderWidth: '1px',
  },
}));

export const StyledAutocompleteTextField = styled(StyledTextField)(() => ({
  '& .MuiInputBase-input': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  '& .MuiFilledInput-root:hover': {
    zIndex: 2,
  },
}));

export const StyledDatesTextField = styled(StyledTextField)(() => ({
  minWidth: '250px',
}));

export const StyledTravellersTextField = styled(StyledTextField)(() => ({
  minWidth: 'fit-content',
}));

export const StyledTextfieldShineThrough = styled(StyledTravellersTextField)<{ width: number }>(({ theme, width }) => ({
  backgroundColor: theme.token.color.background.weak[' '].value,
  width,
}));

export const StyledPersonIcon = styled(PersonIcon)(({ theme }) => ({
  color: theme.token.color.black[' '].value,
}));

export const StyledPrimaryButton = styled(PrimaryButton)(({ theme }) => ({
  height: theme.spacing(14),
}));
