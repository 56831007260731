import { Box, Typography } from '@mui/material';
import { styled } from '@fctg-ds/theme';

export const StyledPoliciesSectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: theme.spacing(5),
  },
}));

export const StyledPoliciesSectionHeaderIconSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(5),
}));

export const StyledPoliciesSectionHeaderIconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: 'fit-content',
  gap: theme.spacing(5),
  height: 'fit-content',
  borderRadius: theme.spacing(6),
  border: `1px ${theme.token.color.neutral.border.disabled.value} solid`,
  padding: theme.spacing(2),
}));

export const StyledPoliciesSectionHeaderIconTitle = styled(Typography)(({ theme }) => ({
  padding: `${theme.spacing(1.5)} 0`,
  [theme.breakpoints.up('xs')]: {
    display: 'block',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const StyledPoliciesSectionHeaderContentSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  width: '100%',
}));

export const StyledPoliciesSectionHeaderContentTitle = styled(Typography)(({ theme }) => ({
  padding: `${theme.spacing(1.5)} 0`,
  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));
