import React from 'react';
import { Typography } from '@mui/material';
import { PoliciesProps } from '../../types';
import ClockOutlined from '@fctg-ds/icons/ClockOutlined';
import { formatCheckInOutTime } from '@src/helpers/formatCheckInOutTime/formatCheckInOutTime';
import {
  StyledCheckInOutContentInnerTimeSection,
  StyledCheckInOutContentInnerTimeBox,
  StyledCheckInOutContentInnerTimeBoxContent,
  StyledCheckInOutContentInnerTimeBoxTextBox,
} from './styles';

interface CheckInOutTimeProps {
  checkInTime: PoliciesProps['checkInTime'];
  checkOutTime: PoliciesProps['checkOutTime'];
}

const CheckInOutTime = ({ checkInTime, checkOutTime }: CheckInOutTimeProps) => {
  return (
    <>
      {checkInTime && checkOutTime && (
        <StyledCheckInOutContentInnerTimeSection data-testid="policiesCheckInOutInnerTimeSection">
          <StyledCheckInOutContentInnerTimeBox data-testid="policiesCheckInOutInnerCheckInTimeBox">
            <StyledCheckInOutContentInnerTimeBoxContent>
              <ClockOutlined />
              <Typography variant="bodySmStrong" data-testid="policiesCheckInOutInnerCheckInText">
                Check in
              </Typography>
            </StyledCheckInOutContentInnerTimeBoxContent>
            <StyledCheckInOutContentInnerTimeBoxTextBox>
              <Typography variant="bodySm" data-testid="policiesCheckInOutInnerCheckInTime">
                from {formatCheckInOutTime(checkInTime)}
              </Typography>
            </StyledCheckInOutContentInnerTimeBoxTextBox>
          </StyledCheckInOutContentInnerTimeBox>
          <StyledCheckInOutContentInnerTimeBox data-testid="policiesCheckInOutInnerCheckOutTimeBox">
            <StyledCheckInOutContentInnerTimeBoxContent>
              <ClockOutlined />
              <Typography variant="bodySmStrong" data-testid="policiesCheckInOutInnerCheckOutText">
                Check out
              </Typography>
            </StyledCheckInOutContentInnerTimeBoxContent>
            <StyledCheckInOutContentInnerTimeBoxTextBox>
              <Typography variant="bodySm" data-testid="policiesCheckInOutInnerCheckOutTime">
                by {formatCheckInOutTime(checkOutTime)}
              </Typography>
            </StyledCheckInOutContentInnerTimeBoxTextBox>
          </StyledCheckInOutContentInnerTimeBox>
        </StyledCheckInOutContentInnerTimeSection>
      )}
    </>
  );
};

export default CheckInOutTime;
