import React, { useEffect, useState } from 'react';
import { Dialog } from '@fctg-ds/core';
import { ErrorDialogProps } from './types';

const ErrorDialog = ({ open, errorMessage, handleClose }: ErrorDialogProps) => {
  if (!errorMessage || errorMessage === '') return null;

  const [isOpen, setIsOpen] = useState<boolean>(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const onClose = () => {
    setIsOpen(false);
    handleClose();
  };

  return (
    <Dialog
      data-testid="error-dialog"
      open={isOpen}
      onClose={onClose}
      title={errorMessage}
      options={[
        {
          text: 'Ok',
          action: onClose,
          primaryAction: true,
        },
      ]}
    />
  );
};

export default ErrorDialog;
