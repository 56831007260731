import { styled } from '@fctg-ds/theme';
import { Box, Typography } from '@mui/material';

export const StyledTypography = styled(Typography)(() => ({
  textWrap: 'nowrap',
  alignItems: 'end',
}));

export const StyledDottedLine = styled(Box)<{ showDottedLines?: boolean }>(({ theme, showDottedLines }) => ({
  ...(showDottedLines
    ? {
        borderStyle: 'dashed',
        borderColor: theme.token.color.border.disabled.value,
        borderWidth: 1,
        borderRadius: 1,
        height: 1,
      }
    : {}),
  width: '100%',
}));

export const StyledEndBox = styled(Box)(({ theme }) => ({
  alignSelf: 'flex-end',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
  flexGrow: 1,
  justifyContent: 'space-between',
}));

export const StyledLocationBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
}));
