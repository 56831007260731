import React from 'react';
import { Typography } from '@mui/material';
import {
  StyledBottomBox,
  StyledMainBox,
  StyledSeeMoreReviewsDesktop,
  StyledSeeMoreReviewsMobile,
  StyledTopBox,
  StyledCategoryScoresBox,
  StyledUserReviewsBox,
  StyledSeeReviewsLinkText,
} from './styles';
import UserRating from '../user-rating/main';
import CategoryScore from './components/category-score/main';
import GuestReviewTile from '../guest-review-tile/main';
import ScoreBoxGridItem from './components/score-box-grid-item/main';
import { GuestReviewsProps } from './types';
import { isValidCategoryScore } from '@src/helpers/isValidCategoryScore/isValidCategoryScore';

export const GuestReviews = ({
  guestReviewsSummary,
  guestReviews,
  isMobile,
  showSeeMoreReviewsLink,
  handleShowGuestReviews,
  onEmitEvent,
}: GuestReviewsProps) => {
  const { count, overall, superlative, cleanliness, service, condition, amenities } = guestReviewsSummary;

  const onShowGuestReviews = () => {
    handleShowGuestReviews();
    onEmitEvent &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: 'Guest Reviews',
          interactionLabel: 'openModal',
        },
      });
  };

  return (
    <StyledMainBox data-testid="guest-reviews">
      <Typography variant="headingMd" data-testid="guest-reviews-heading">
        Guest reviews
      </Typography>
      <StyledTopBox>
        <UserRating count={count} overall={overall} superlative={superlative} size={'sm'} />
        {handleShowGuestReviews && showSeeMoreReviewsLink && (
          <StyledSeeMoreReviewsDesktop onClick={onShowGuestReviews} data-testid="more-reviews-link-desktop">
            <StyledSeeReviewsLinkText variant="bodyMdStrong">See more reviews</StyledSeeReviewsLinkText>
          </StyledSeeMoreReviewsDesktop>
        )}
      </StyledTopBox>
      <StyledBottomBox>
        <StyledCategoryScoresBox container data-testid="guest-reviews-category-score">
          {isValidCategoryScore(service) && (
            <ScoreBoxGridItem>
              <CategoryScore category={'service'} score={service} />
            </ScoreBoxGridItem>
          )}
          {isValidCategoryScore(cleanliness) && (
            <ScoreBoxGridItem>
              <CategoryScore category={'cleanliness'} score={cleanliness} />
            </ScoreBoxGridItem>
          )}
          {isValidCategoryScore(amenities) && (
            <ScoreBoxGridItem>
              <CategoryScore category={'amenities'} score={amenities} />
            </ScoreBoxGridItem>
          )}
          {isValidCategoryScore(condition) && (
            <ScoreBoxGridItem>
              <CategoryScore category={'condition'} score={condition} />
            </ScoreBoxGridItem>
          )}
        </StyledCategoryScoresBox>
        <StyledUserReviewsBox>
          {guestReviews.map((guestReview, index) => (
            <GuestReviewTile key={`guest-review-${index}`} {...guestReview} isMobile={isMobile} />
          ))}
          {handleShowGuestReviews && showSeeMoreReviewsLink && (
            <StyledSeeMoreReviewsMobile onClick={onShowGuestReviews} data-testid="more-reviews-link-mobile">
              See more reviews
            </StyledSeeMoreReviewsMobile>
          )}
        </StyledUserReviewsBox>
      </StyledBottomBox>
    </StyledMainBox>
  );
};
