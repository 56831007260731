import { Box, styled } from '@mui/material';
import { Chip } from '@fctg-ds/core';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const StyledChip = styled(Chip)(({ theme }) => ({
  height: theme.spacing(8),
  width: 'max-content',
}));

export const StyledDotBox = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  marginTop: theme.spacing(0.5),
}));

export const StyledDot = styled(FiberManualRecordIcon)(({ theme }) => ({
  fontSize: theme.spacing(0.5),
}));
