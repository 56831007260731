import { styled } from '@fctg-ds/theme';
import TextField from '@mui/material/TextField';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '.MuiInputBase-root': {
    backgroundColor: theme.token.color.black.a04.value,
  },
}));
