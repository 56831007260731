import { useEffect, useState } from 'react';
import { PaxCountUpdater, PaxType, Travellers } from '../types';
import { ErrorState } from '../components/pax-count/types';

const MAX_TRAVELLERS = 9;

interface UseTravellersErrorProps {
  travellers: Travellers;
  paxCountUpdater: PaxCountUpdater;
  paxType: PaxType;
}

export const useTravellersError = ({ travellers, paxCountUpdater, paxType }: UseTravellersErrorProps) => {
  const [error, setError] = useState<ErrorState | null>(null);

  const { adult, childAges, infant } = travellers;

  const handleMaximumPaxExceeded = () => {
    paxCountUpdater[paxType].decrease();
    setError(null);
  };

  const handleIncorrectAdultInfantRatio = () => {
    if (paxType === 'adult') {
      paxCountUpdater['adult'].increase();
    } else if (paxType === 'infant') {
      paxCountUpdater['infant'].decrease();
    }
    setError(null);
  };

  useEffect(() => {
    if (infant + childAges.length > 5) {
      setError({
        title: 'Maximum of 5 infants and children supported online',
        defaultAction: handleMaximumPaxExceeded,
        options: [
          {
            text: 'OK',
            action: handleMaximumPaxExceeded,
            primaryAction: true,
          },
        ],
      });
    } else if (infant > adult) {
      setError({
        title: 'Each infant must be accompanied by an adult',
        defaultAction: handleIncorrectAdultInfantRatio,
        options: [
          {
            text: 'OK',
            action: handleIncorrectAdultInfantRatio,
            primaryAction: true,
          },
        ],
      });
    } else if (childAges.length + infant + adult > MAX_TRAVELLERS) {
      setError({
        title: 'Maximum of 9 travellers supported online',
        defaultAction: handleMaximumPaxExceeded,
        options: [
          {
            text: 'OK',
            action: handleMaximumPaxExceeded,
            primaryAction: true,
          },
        ],
      });
    }
  }, [adult, childAges, infant]);

  return { error };
};
