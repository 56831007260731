// @IMPORTANT! Keep this at the top always
// this will ensure that any chunked/split files will be imported from the correct URL
__webpack_public_path__ = new URL(((document as Document)?.currentScript as HTMLScriptElement)?.src || '').origin;

import store from '@src/store';
import getEndpointsClient from '@endpoints/client/getEndpointsClient';
import createMicroFrontend from '@libs/serverless-micro-ui/createMicroFrontend';
import { SDKClients } from '@src/sdk.types';

// MICRO FRONTEND COMPONENTS
import DestinationTilesMofo from '@public/destination-tiles';
import FilterBarMofo from '@public/filter-bar';
import ResultsHeaderMofo from '@public/results-header';
import MapModalMofo from './public/map-modal';
import ImageGalleryMofo from './public/image-gallery';
import RoomTileMofo from './public/room-tile';
import HotelDetailMofo from './public/hotel-detail';
import RoomOptionsModalMofo from './public/room-options-modal';
import RoomDetailsModalMofo from './public/room-details-modal';
import PoliciesMofo from './public/policies';
import GuestReviewsMofo from './public/guest-reviews';
import GuestReviewsModalMofo from './public/guest-reviews-modal/main';
import EditSearchFormMofo from './public/edit-search-form/main';
import PackagesResultsSearchFormMofo from './public/packages-results-search-form';
import StepperMofo from './public/stepper';
import PackageDetailsMofo from './public/package-details/main';

// PUBLIC COMPONENT LIST
// The list of components this micro frontend exposes
export const components = {
  DestinationTiles: DestinationTilesMofo,
  FilterBar: FilterBarMofo,
  ResultsHeader: ResultsHeaderMofo,
  MapModal: MapModalMofo,
  ImageGallery: ImageGalleryMofo,
  RoomTile: RoomTileMofo,
  RoomOptionsModal: RoomOptionsModalMofo,
  RoomDetailsModal: RoomDetailsModalMofo,
  HotelDetail: HotelDetailMofo,
  Policies: PoliciesMofo,
  GuestReviews: GuestReviewsMofo,
  GuestReviewsModal: GuestReviewsModalMofo,
  EditSearchForm: EditSearchFormMofo,
  PackagesResultsSearchForm: PackagesResultsSearchFormMofo,
  Stepper: StepperMofo,
  PackageDetails: PackageDetailsMofo,
};

// UI TO API CLIENT SETUP
// Normally this should just be the backend for frontend client
export const clients: SDKClients = {
  bff: getEndpointsClient({ url: __webpack_public_path__ }),
};

createMicroFrontend(SERVICE_SDK_NAME, {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init: () => {},
  components,
  store,
  clients,
  methods: {},
  options: { noConfig: true },
});
