import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { SliderProps } from '@mui/material/Slider';

import Slider from './components/slider';
import PriceSliderGraph from './components/graph';
import { styled } from '@fctg-ds/theme';
import PriceLabel from './components/price-label';

import isNil from 'lodash/isNil';
import { EmitEventProps } from '@src/common/types/types';

type chartDatum = {
  label: number;
  count: number;
};

export interface PriceSliderProps {
  chartData: chartDatum[];
  onChange?: SliderProps['onChangeCommitted'];
  value: [number, number];
  min: number;
  max: number;
  sliderProps?: SliderProps;
  currencyPrefix?: string;
  graphTitlePrefix?: string;
  onEmitEvent?: (event: EmitEventProps) => void;
}

const PriceLabelWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));

const GraphWrapper = styled(Box)(({ theme }) => {
  return {
    marginTop: theme.token.spacing[3].value,
    marginBottom: `-${theme.spacing(1.5)}`,
  };
});

const PriceSlider = ({
  chartData,
  onChange,
  value,
  min,
  max,
  sliderProps,
  currencyPrefix,
  graphTitlePrefix,
  onEmitEvent,
}: PriceSliderProps) => {
  const onChangePrice = (event: Event, value: [number, number]) => {
    onChange(event, value);
    onEmitEvent &&
      onEmitEvent({
        event: 'trackInteraction',
        data: {
          interactionValue: 'filterBy',
          interactionLabel: 'price',
        },
      });
  };

  const valueLabelFormat = (value: number) => {
    if (!value) return '';

    return `${currencyPrefix}${value?.toFixed()}`;
  };

  // internal state for slider, main state updates after slider is released
  const [sliderValue, setSliderValue] = useState([min, max] as [number, number]);

  useEffect(() => {
    // When value changes, we need to check whether the filter has been cleared or else
    // it will always default to being set to min and max
    if (isNil(value?.[0]) || isNil(value?.[1])) {
      setSliderValue([min, max]);
    } else {
      setSliderValue(value);
    }
  }, [min, max, value]);

  const onChangeSlider: SliderProps['onChange'] = (_event, value) => {
    setSliderValue(value as [number, number]);
  };

  return (
    <Box>
      <GraphWrapper>
        <PriceSliderGraph value={sliderValue} graphTitlePrefix={graphTitlePrefix} chartData={chartData || []} />
      </GraphWrapper>
      <Slider
        value={sliderValue}
        onChange={onChangeSlider}
        onChangeCommitted={onChangePrice}
        min={min}
        max={max}
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
        {...sliderProps}
      />
      {!isNil(sliderValue[0]) && !isNil(sliderValue[1]) && (
        <PriceLabelWrapper data-testid="price-label-wrapper">
          <PriceLabel mode={'min'} price={`${currencyPrefix}${sliderValue[0].toLocaleString()}`} />
          <PriceLabel mode={'max'} price={`${currencyPrefix}${sliderValue[1].toLocaleString()}`} />
        </PriceLabelWrapper>
      )}
    </Box>
  );
};

export default PriceSlider;

PriceSlider.defaultProps = {
  currencyPrefix: 'AU$',
  graphTitlePrefix: 'Total: ',
};
