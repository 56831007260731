import { Box, Typography } from '@mui/material';
import { styled } from '@fctg-ds/theme';

export const StyledPriceContainer = styled(Box)<{ showPriceDifference: boolean }>(({ showPriceDifference }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: showPriceDifference ? 'flex-end' : 'center',
  minWidth: 'fit-content',
}));

export const StyledPriceBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const StyledOriginalPriceBox = styled(Box)(({ theme }) => ({
  height: theme.spacing(10),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  alignContent: 'end',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
}));

export const StyledOriginalPriceTypography = styled(Typography)(({ theme }) => ({
  color: theme.token.color.black[' '].value,
  textAlign: 'end',
  textDecoration: 'line-through',
  opacity: '0.54',
}));

export const StyledPriceComment = styled(Box)(({ theme }) => ({
  height: theme.spacing(5),
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.token.color.upsell['background'].medium[' '].value,
  color: theme.token.color.upsell['text'].medium[' '].value,
  fontSize: theme.spacing(3),
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)} ${theme.spacing(0.5)} ${theme.spacing(2)}`,
  borderRadius: theme.token.radius.sm.value,
}));

export const StyledDifferencePriceBox = styled(Box)<{ showTaxDisclaimer: boolean }>(({ showTaxDisclaimer, theme }) => ({
  height: showTaxDisclaimer ? theme.spacing(10) : theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  alignContent: 'end',
  gap: theme.spacing(0.5),
  flexWrap: 'wrap',
  marginTop: `-${theme.spacing(1.5)}`,
  marginBottom: theme.spacing(0.5),
}));

export const StyledDifferencePriceTypography = styled(Typography)(({ theme }) => ({
  color: theme.token.color.neutral.text.weak[' '].value,
  textAlign: 'end',
}));
