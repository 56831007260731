import { Travellers } from '@src/components/travellers-selector/types';
import { isEqual } from 'lodash';

export const isSameTravellersSelected = (previousTravellers: Travellers, newTravellers: Travellers): boolean => {
  const sameAdults = previousTravellers.adult === newTravellers.adult;
  const sameChildAges = isEqual(previousTravellers, newTravellers);
  const sameInfants = previousTravellers.infant === newTravellers.infant;

  return sameAdults && sameChildAges && sameInfants;
};
