import React from 'react';
import { PropertyClassProps } from './types';
import { StyledPropertyClassGridContainer, StyledPropertyClassGridItem } from './styles';
import { PropertyClassSelection } from './components';
import { BoxChip } from '../box-chip';
import { selections } from './constants';

const PropertyClass = ({ selected, onChange, mode = 'all_filters', onEmitEvent }: PropertyClassProps) => {
  return (
    <StyledPropertyClassGridContainer data-testid="property-class" container mode={mode}>
      {selections.map(({ ratingDescription, rating }) => (
        <StyledPropertyClassGridItem xs={4} item>
          <BoxChip
            isActive={selected.includes(rating)}
            onChange={() => {
              onEmitEvent &&
                !selected.includes(rating) &&
                onEmitEvent({
                  event: 'trackInteraction',
                  data: {
                    interactionValue: 'filterBy',
                    interactionLabel: 'starRating',
                  },
                });
              onChange(rating);
            }}
          >
            <PropertyClassSelection ratingDescription={ratingDescription} rating={rating} />
          </BoxChip>
        </StyledPropertyClassGridItem>
      ))}
    </StyledPropertyClassGridContainer>
  );
};

export default PropertyClass;
