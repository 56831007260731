import React from 'react';
import { StyledCount, StyledDecrementButton, StyledIncrementButton, StyledIncrementor } from './styles';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

interface IncrementorProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
}

export const Incrementor = ({ value, onChange, min = 0 }: IncrementorProps) => {
  const incrementCount = () => {
    onChange(value + 1);
  };

  const decrementCount = () => {
    if (value > min) onChange(value - 1);
  };

  const isDisabled = value === min;

  return (
    <StyledIncrementor data-testid="incrementor">
      <StyledDecrementButton
        data-testid="decrement-button"
        size="medium"
        variant="outlined"
        disabled={isDisabled}
        onClick={decrementCount}
      >
        <RemoveIcon />
      </StyledDecrementButton>
      <StyledCount data-testid="incrementor-value">{value}</StyledCount>
      <StyledIncrementButton data-testid="incrementor-button" size="medium" variant="outlined" onClick={incrementCount}>
        <AddIcon />
      </StyledIncrementButton>
    </StyledIncrementor>
  );
};
