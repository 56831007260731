import React from 'react';
import { styled } from '@fctg-ds/theme';
import { PrimaryButton } from '@fctg-ds/core';
import { CalendarDates } from '@src/components/calendar-date-picker/types';
import { isSameDatesSelected } from '@src/helpers/isSameDatesSelected/isSameDatesSelected';

export interface DoneButtonProps {
  dates?: CalendarDates;
  children: React.ReactNode;
  handleDone: () => void;
}

const StyledDoneButton = styled(PrimaryButton)<{ active: boolean }>(({ active, theme }) => ({
  width: 'fit',
  backgroundColor: active
    ? theme.token.color.primary[' '].value
    : theme.token.color.primary.background.medium.disabled.value,
  color: active ? theme.token.color.primary.background[' '].value : theme.token.color.icon.weak.disabled.value,
  '&:hover, &:active': {
    cursor: active ? 'pointer' : 'revert',
    backgroundColor: active
      ? theme.token.color.primary.background.strong.hovered.value
      : theme.token.color.primary.background.medium.disabled.value,
  },
}));

const DoneButton = ({ dates, children, handleDone }: DoneButtonProps) => {
  const { checkInDate, checkOutDate } = dates;

  return (
    <StyledDoneButton
      active={checkInDate && checkOutDate && !isSameDatesSelected(checkInDate, checkOutDate)}
      onClick={handleDone}
    >
      {children}
    </StyledDoneButton>
  );
};

export default DoneButton;
